import React, { useEffect, useState } from "react";
import {
    FiMapPin,
    FiCalendar,
    FiUser,
    FiMinus,
    FiPlus,
    FiSearch,
  } from "react-icons/fi";


const InputBoxSubHeader = ({ inputbox, setInputBox, cities }) => {

    cities.sort((a, b) => b.a2z_score - a.a2z_score);
    const LandMarkSuggestions = cities.slice(0,5)  
  let countries = cities;
  // const [input, setInputBox] = useState("");
  const [suggestion, setSuggestions] = useState([]);
  const [select, setSelect] = useState("");
  const [clicked, setClicked] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    let matches = [];

    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = countries.filter((item) => regex.test(item.name));
    }
    setSuggestions(matches);
    setSelect(value);
  };

  const selectValue = (item) => {
    setSelect(item.name);
    setSuggestions("");
    setInputBox(item.id);
  };

  const clearSearch = () => {
    setInputBox("");
    setSuggestions("");
    setSelect("");
  };

  useEffect(()=>{
    if(select===""){
        setInputBox("")
    }
  },[select])
  return (
    <div className="hidden lg:flex font-jt flex-row w-full lg:w-[30%] border-[2px] border-[#E8E8E8]  rounded-full  p-1">
      <input
        className="w-[92%] px-3 py-1 ml-1 text-xl bg-transparent  border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
        type="text"
        placeholder="Search Villas"
        value={select}
        onChange={handleChange}
        style={{ textTransform: "capitalize" }}
        onClick={() => {
          setClicked(!clicked);
        }}
      />
        <div
          className="ml-4  cursor-pointer text-white flex justify-center items-center w-10 rounded-lg transition-all hover:scale-[1.05]"
          onClick={() => {
            selectValue(select)
            setClicked(!clicked)
          }}
        >
           <svg
          width="25"
          height="25"
          viewBox="0 0 41 40"
          fill="none"
          className="my-auto pr-1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.7067 35.8283L30.702 26.8236C32.8699 23.9376 34.0402 20.4246 34.0363 16.815C34.0363 7.59496 26.535 0.09375 17.315 0.09375C8.09496 0.09375 0.59375 7.59496 0.59375 16.815C0.59375 26.035 8.09496 33.5363 17.315 33.5363C20.9246 33.5402 24.4376 32.3699 27.3236 30.202L36.3283 39.2067C36.7842 39.6141 37.3787 39.8316 37.9898 39.8145C38.601 39.7974 39.1824 39.547 39.6147 39.1147C40.047 38.6824 40.2974 38.101 40.3145 37.4898C40.3316 36.8787 40.1141 36.2842 39.7067 35.8283ZM5.37125 16.815C5.37125 14.4527 6.07174 12.1435 7.38413 10.1794C8.69653 8.21527 10.5619 6.68441 12.7443 5.78041C14.9268 4.87642 17.3282 4.63989 19.6451 5.10075C21.962 5.5616 24.0901 6.69913 25.7605 8.36949C27.4309 10.0399 28.5684 12.168 29.0293 14.4849C29.4901 16.8018 29.2536 19.2032 28.3496 21.3857C27.4456 23.5681 25.9147 25.4335 23.9506 26.7459C21.9865 28.0583 19.6772 28.7587 17.315 28.7587C14.1485 28.7549 11.1128 27.4954 8.87369 25.2563C6.63462 23.0172 5.37505 19.9815 5.37125 16.815Z"
            fill="#020202"
          />
        </svg>
        </div>
      {suggestion?.length > 0 ? (
        <div className="flex flex-row mt-14 shadow-2xl  absolute  rounded-2xl w-[27%]">
          <div className="bg-white cursor-pointer border-2 border-[#F4F4F4] rounded-lg w-full shadow-md ">
            {suggestion.slice(0, 8).map((item) => {
              return (
                <div
                  className=" suggestions text-center px-2 mt-2 py-2 text-lg border-[#F4F4F4] border-b-[1px] "
                  key={item.id}
                  onClick={() => {
                    selectValue(item);
                    setClicked(false);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        clicked && (
          <div className="flex flex-row mt-14  absolute  rounded-2xl w-[27%] shadow-2xl">
            <div className="bg-white cursor-pointer border-2 border-[#F4F4F4] rounded-lg w-full shadow-md">
              {LandMarkSuggestions.map((item) => {
                return (
                  <div
                    className=" suggestions text-center px-2 mt-2 py-2 text-lg border-[#F4F4F4] border-b-[1px]  "
                    key={item.id}
                    onClick={() => {
                      selectValue(item);
                      setClicked(false);
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        )
      )}
      {/* {suggestion?.length > 0 ? (
        <div className="bg-white cursor-pointer rounded-lg">
          {suggestion.map((item) => {
            return (
              <div
                className="text-center p-1 text-[14px] border-b-[1px] font-semibold"
                key={item.id}
                onClick={() => selectValue(item.name)}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      ) : null} */}
    </div>
  );
};

export default InputBoxSubHeader;
