import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "./carousel.min.css";
import { useNavigate } from "react-router-dom";
const slidesdummy = [
  {
    source:
      "https://images.unsplash.com/photo-1610513320995-1ad4bbf25e55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
];

const SimilarVillas = ({ data, landmark, i,adults,children,startDate,endDate,location }) => {
  const [slides, setSlide] = useState(slidesdummy);
  const handleimages = () => {
    if (data?.media_order?.length > 0) {
      data?.media.sort((a, b) => {
        const indexA = data?.media_order.indexOf(a.media.id);
        const indexB = data?.media_order.indexOf(b.media.id);
        return indexA - indexB;
      });
    }

    if (data?.media.length > 0) {
      const newArray = data?.media.map((item) => {
        return {
          source: item.media.file,
        };
      });

      setSlide(newArray);
    } else {
      setSlide(slidesdummy);
    }
  };

  useEffect(() => {
    handleimages();
  }, [data]);
  //console.log(data, i, "carddata");


  const navigate = useNavigate();
  const handleCardClick = (villaId, villaName) => {
    const formData = {
      villaId: villaId,
      villaName: villaName,
      adults: adults,
      children: children,
      startDate: startDate,
      endDate: endDate,
      location: location,
    };

    const hyphenatedVillaName = villaName.replace(/\s+/g, '-');
    navigate(`/villa/${villaId}/${hyphenatedVillaName}`, { state: formData })
    window.location.reload();
  };
  return (
    <>
      {data ? (
        <div className="bg-[#EAEAEA] font-jt rounded-2xl   h-[430px] w-[90vw] lg:w-[400px] overflow-hidden  p-3">
          <div className="relative w-full h-3/5 overflow-hidden rounded-2xl">
    <img
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={slides[0].source}
    />
</div>

          <div className=" flex flex-col h-2/5 p-1 justify-between">
            <div>
              <div className="flex mt-4 items-center  flex-row w-full justify-between">
                <div
                  className="hidden lg:block hover:underline cursor-pointer text-2xl lg:text-[25px] font-semibold"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    handleCardClick(
                      data.id,
                      data.property_name
                    );
                  }}
                >
                  {data?.property_name
                    ? data?.property_name.toLowerCase()
                    : data?.listing_name.toLowerCase()}
                </div>
                <div
                  className="lg:hidden hover:underline block cursor-pointer text-[20px] font-semibold"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    handleCardClick(
                      data.id,
                      data.property_name
                    );
                  }}
                >
                  {data?.property_name
                    ? data?.property_name.length > 18
                      ? data?.property_name.slice(0, 18).toLowerCase() + ".."
                      : data?.property_name.toLowerCase()
                    : data?.listing_name.length > 18
                      ? data?.listing_name.slice(0, 18).toLowerCase() + ".."
                      : data?.listing_name.toLowerCase()}
                </div>
                <div>
                  <div className="hidden lg:flex flex-row">
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                        fill="#E8BD25"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                        fill="#E8BD25"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                        fill="#E8BD25"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                        fill="#E8BD25"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                        fill="#BCBCBC"
                      />
                    </svg>
                    (0)
                  </div>
                  <div className="lg:hidden flex flex-row items center">
                    <div className="flex flex-row items-center">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                          fill="#E8BD25"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                          fill="#E8BD25"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                          fill="#E8BD25"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                          fill="#E8BD25"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                          fill="#BCBCBC"
                        />
                      </svg>
                    </div>
                    <div className="items center">(0)</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-between">
                <div className=" w-[15%] text-[12px] lg:text-sm text-[#646464]">
                  5 BHK
                </div>
                <div className="flex flex-row w-[85%]  justify-end space-x-1">
                  <div className="w-[100%] flex justify-end text-[12px] lg:text-sm text-end text-[#646464]">
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.24968 0C2.81738 0 0.0361328 2.79687 0.0361328 6.25C0.0361328 12.2188 6.24968 20 6.24968 20C6.24968 20 12.4632 12.2188 12.4632 6.25C12.4632 2.79687 9.68197 0 6.24968 0ZM6.24968 9.28125C4.62468 9.28125 3.31218 7.95833 3.31218 6.32812C3.31218 4.69792 4.62988 3.36979 6.24968 3.36979C7.87468 3.36979 9.18718 4.69271 9.18718 6.32812C9.18718 7.95833 7.87468 9.28125 6.24968 9.28125Z"
                        fill="#646464"
                      />
                    </svg>
                    {data?.addressData?.area
                      ? data?.addressData?.area
                      : data?.addressData?.building}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full items-center justify-between">
              <div className="text-[23px] font-semibold">
                {data?.size_of_property} sqft
              </div>
              <div className="text-[23px] font-semibold">
                {data?.price_guest_pay}/per night
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SimilarVillas;
