import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
// import "./carousel.min.css";
import { Link } from "react-router-dom";
import "./hotel.css";
import { RWebShare } from "react-web-share";
import Slider from "react-slick";
import "./slick.css"; 
import "./slick-theme.css";
const slidesdummy = [
  {
    source:
      "https://images.unsplash.com/photo-1610513320995-1ad4bbf25e55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    source:
      "https://images.unsplash.com/photo-1610513320995-1ad4bbf25e55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    source:
      "https://images.unsplash.com/photo-1610513320995-1ad4bbf25e55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    source:
      "https://images.unsplash.com/photo-1610513320995-1ad4bbf25e55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  }
];

const HotelCard = ({
  hotel,
  villadata,
  amenitiesAll,
  startDate,
  endDate,
  adults,
  children,
  city,
  citylocation
}) => {
  const [slides, setSlide] = useState(slidesdummy);
  const [totalPrice, setTotalPrice] = useState(villadata.price_guest_pay);
  const [availability, setAvailability] = useState(true);
  const [extraPrice, setExtraPrice] = useState(0);
  const [DateNull, SetDateNull] = useState(false)
  const maxPax = villadata.number_of_adults + Math.ceil(villadata.number_of_children / 2);
  const includedPax =
    villadata.standard_occupany ? villadata.standard_occupany : maxPax;
  const [pax, setPax] = useState(adults + Math.ceil(children / 2));
  // //console.log(villadata, "asd");
  const filteredArray = amenitiesAll
    .filter((item) =>
      villadata.amenities.some((obj) => obj.amenities_id === item.id)
    )
    .map(({ id, name, icon }) => ({ id, name, icon: icon.file }));

  const onChange = () => {
    // Your onChange logic here
  };

  const onClickItem = () => {
    // Your onClickItem logic here
  };

  const onClickThumb = () => {
    // Your onClickThumb logic here
  };
  console.log(villadata,"2024data")
  const handleimages = () => {
    if (villadata.media_order.length > 0) {
      villadata.media.sort((a, b) => {
        const indexA = villadata.media_order.indexOf(a.media.id);
        const indexB = villadata.media_order.indexOf(b.media.id);
        return indexA - indexB;
      });
    }

    if (villadata.media.length > 0) {
      const newArray = villadata.media.map((item) => {
        return {
          source: item.media.file,
        };
      });
      if (newArray.length === 1) {
        // Add the first element again to make the length 2
        newArray.push(newArray[0]);
    }
      setSlide(newArray);
    } else {
      setSlide(slidesdummy);
    }
  };
  let url=''
  const handleCardClick = () => {
    const formData2 = {
      villaId: villadata.property_ptr_id,
      villaName: villadata.listing_name,
      location: citylocation,
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
    };
    const hyphenatedVillaName = villadata?.listing_name?.replace(/\s+/g, "-");
    url = `http://www.a2zvillaz.com/villa/${villadata.property_ptr_id}/${hyphenatedVillaName}?formData=${encodeURIComponent(JSON.stringify(formData2))}`;

    // Open the link in a new tab
    // const newTab = window.open(url, '_blank');
    // if (!newTab) {
    //   // Handle cases where the popup blocker prevents opening the new tab
    //   // You might want to show a message to the user in this case.
    //   navigate(url); // Fallback to navigating in the same tab
    // }
  };
  handleCardClick();

  useEffect(() => {
    if (villadata && startDate && endDate) {
      let priceSum = 0;
      let flag = true;

      if (Object.prototype.toString.call(startDate) === "[object Date]" &&
        Object.prototype.toString.call(endDate) === "[object Date]") {

        for (
          let d = new Date(startDate);
          d < endDate;
          d.setDate(d.getDate() + 1)
        ) {
          let tempDate = d.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' }).slice(0, 10);
          let dateStr = tempDate;
          let [month, day, year] = dateStr.split("/");
          let formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
          //console.log(formattedDate, "tempdate")
          if (villadata.price_per_date[formattedDate] === 0) flag = false;
          priceSum += parseInt(villadata.price_per_date[formattedDate]);
        }
      }

      if (!priceSum || priceSum === 0) flag = false;
      setAvailability(flag);
      if (flag) setTotalPrice(priceSum);
      else setTotalPrice(villadata.price_guest_pay);
      //console.log(priceSum, "Total wala");
    }
  }, [villadata, startDate, endDate]);

  useEffect(() => {
    let days = 0; // default value in case of invalid dates

    if (Object.prototype.toString.call(startDate) === "[object Date]" &&
      Object.prototype.toString.call(endDate) === "[object Date]") { // check if dates are valid
      let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      days = Math.ceil(timeDiff / (1000 * 3600 * 24));
      SetDateNull(false)
    }
    else {
      SetDateNull(true)
    }
    // //console.log(Object.prototype.toString.call(startDate),"startdate")
    // const days = (endDate.getTime() - startDate.getTime()) / 86400000;
    setPax(adults + Math.ceil(children / 2));
    if (includedPax >= adults + Math.ceil(children / 2)) setExtraPrice(0);
    else {
      setExtraPrice(
        (adults + Math.ceil(children / 2) - includedPax) *
        villadata.price_per_extra_person *
        days
      );
    }

    // //console.log(extraPrice, "EXTRAA", days);
  }, [villadata,adults, children, startDate, endDate]);

  const linkRef = useRef(null);
  useEffect(() => {
    
    handleimages();
  }, [villadata]);
  // //console.log(slides, "asda")

//////////////////////

function generateReviewCount(property_name, price_guest_pay, number_of_adults, a, b) {
  // Calculate a deterministic value based on input parameters
  let deterministic_value = property_name.length + price_guest_pay + number_of_adults + new Date().getDate() + (new Date().getMonth() + 1);

  // Use the deterministic value to generate a pseudo-random integer between a and b
  let randomInteger = (deterministic_value % (b - a + 1)) + a;

  // Use the deterministic value to determine the number and time unit
  let time_unit, number;
  if (deterministic_value % 2 === 0) {
      time_unit = "hr";
      number = (deterministic_value % 20) + 1;  // 1 to 20 hours
  } else {
      time_unit = "day";
      number = (deterministic_value % 19) + 2;  // 2 to 20 days
  }

  // Construct output string
  

  return randomInteger;
}

function generateDiscount(number_of_bathrooms,property_name, price_guest_pay, number_of_adults, a, b) {
  // Calculate a deterministic value based on input parameters
  let deterministic_value = number_of_bathrooms +property_name.length + price_guest_pay + number_of_adults + new Date().getDate() + (new Date().getMonth() + 1);

  // Use the deterministic value to generate a pseudo-random integer between a and b
  let randomInteger = (deterministic_value % (b - a + 1)) + a;

  // Use the deterministic value to determine the number and time unit
  let time_unit, number;
  if (deterministic_value % 2 === 0) {
      time_unit = "hr";
      number = (deterministic_value % 20) + 1;  // 1 to 20 hours
  } else {
      time_unit = "day";
      number = (deterministic_value % 19) + 2;  // 2 to 20 days
  }

  // Construct output string
  

  return randomInteger;
}

function generateLastBooked(property_name, price_guest_pay, number_of_adults, area) {
  // Calculate a deterministic value based on input parameters
  let deterministic_value = property_name.length + area.length + price_guest_pay + number_of_adults + new Date().getDate() + (new Date().getMonth() + 1);

  // Use the deterministic value to determine the number and time unit
  let time_unit, number;
  if (deterministic_value % 2 === 0) {
      time_unit = "hr";
      number = (deterministic_value % 20) + 1;  // 1 to 20 hours
  } else {
      time_unit = "day";
      number = (deterministic_value % 19) + 2;  // 2 to 20 days
  }

  // Construct output string
  let output = `Last Booked ${number} ${time_unit} Ago`;

  return output;
}

/////////////////////

function generateStars(property_name, price_guest_pay, number_of_adults, area) {
  let array = [];

  // Generate three possible combinations
  let combinations = [
      ["#E8BD25", "#E8BD25", "#E8BD25", "#E8BD25", "#E8BD25"],
      ["#E8BD25", "#E8BD25", "#E8BD25", "#E8BD25", "#BCBCBC"],
      ["#E8BD25", "#E8BD25", "#E8BD25", "#BCBCBC", "#BCBCBC"]
  ];

  // Randomly choose one of the combinations
  let randomIndex = property_name.length + area.length + price_guest_pay + number_of_adults + new Date().getDate() + (new Date().getMonth() + 1);
  let chosenCombination = combinations[randomIndex%3];

  

  // Add the shuffled combination to the array
  array.push(...chosenCombination);
  
  return array;
}







/////////////////////////

  /////////
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: " flex flex-row mt-2 w-full p-[2px] lg:p-2",
    // nextArrow: <SampleNextArrow to="next"/>,
    //     prevArrow: <SamplePrevArrow to="prev" />,
  };
  ////////////
  return (
   
    <div className="bg-[#EAEAEA] w-[96%] md:w-full relative mx-auto font-jt border-[3px] border-[#2A2A2B] lg:border-none lg:shadow-md rounded-[30px] lg:rounded-2xl shadow-xl mt-5 justify-between   flex flex-col md:flex-row">
        <div className={`absolute right-4 md:right-[16rem] -top-2 md:-top-1 ${villadata.status !== "OPEN/BOOKABLE" ? 'block' : 'hidden'}`}>
        <div className="relative z-40">
          <svg width="157" height="63" viewBox="0 0 157 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_128_96)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4 5H11V47L78.5 31.3333L146 47V5H153L146 0H11L4 5Z" fill="black"/>
            </g>
          </svg>
          <div className="absolute inset-0 text-yellow-300 -top-6 flex items-center justify-center z-50 ">
            Not Available
          </div>
        </div>
        </div>
     


      <div className="w-full md:w-[40%] px-2 block my-auto">
      <Slider {...settings} >
        {slides.map((i) => (
            
                <div className="rounded-3xl w-full lg:rounded-2xl">
                  <img
                    className=" rounded-3xl mx-auto w-full object-cover  lg:rounded-2xl lg:h-[300px]  h-[210px]"
                    src={i.source}
                  />
                </div>
              
            ))}
            
      </Slider>
        {/* <Carousel
          autoFocus={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          useKeyboardArrows
          showIndicators={false}
          className="presentation-mode p-[2px] lg:p-4"
        >
          {slides.map((i) => (
            <>
              <div className="rounded-3xl lg:rounded-2xl">
                <img
                  className=" rounded-3xl lg:rounded-2xl lg:h-[300px] object-contain h-[210px]"
                  src={i.source}
                />
              </div>
            </>
          ))}
        </Carousel> */}
      </div>
      
      <div className="w-full md:w-[43%] p-2 md:p-6">
        <div className="hidden lg:flex flex-row justify-between items-center  ">
        <a href={url}>
          <div className="flex flex-row justify-between ">
            <div className=" flex flex-row">
              {generateStars(villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, villadata.area).map(elem=>(
                <svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                  fill={elem}
                />
              </svg>
              ))}
              
              ({villadata.review_count>0?villadata.review_count:generateReviewCount(villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, 30, 80)})
            </div>
            <div className="flex flex-row">
              {!villadata.is_recommended ? (
                <>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      d="M23.0325 11.4675L12.5325 0.9675C12.4627 0.897204 12.3798 0.841408 12.2884 0.803331C12.197 0.765255 12.099 0.745651 12 0.745651C11.9009 0.745651 11.8029 0.765255 11.7115 0.803331C11.6201 0.841408 11.5372 0.897204 11.4675 0.9675L0.967454 11.4675C0.897158 11.5372 0.841362 11.6202 0.803286 11.7116C0.765209 11.803 0.745605 11.901 0.745605 12C0.745605 12.099 0.765209 12.197 0.803286 12.2884C0.841362 12.3798 0.897158 12.4628 0.967454 12.5325L11.4675 23.0325C11.5372 23.1028 11.6201 23.1586 11.7115 23.1967C11.8029 23.2347 11.9009 23.2543 12 23.2543C12.099 23.2543 12.197 23.2347 12.2884 23.1967C12.3798 23.1586 12.4627 23.1028 12.5325 23.0325L23.0325 12.5325C23.1028 12.4628 23.1585 12.3798 23.1966 12.2884C23.2347 12.197 23.2543 12.099 23.2543 12C23.2543 11.901 23.2347 11.803 23.1966 11.7116C23.1585 11.6202 23.1028 11.5372 23.0325 11.4675ZM14.9175 12.3375L12.6675 16.8375C12.6051 16.9612 12.5098 17.0652 12.392 17.138C12.2741 17.2108 12.1385 17.2496 12 17.25C11.8823 17.2514 11.7662 17.223 11.6625 17.1675C11.4858 17.0776 11.3519 16.9214 11.2901 16.7331C11.2283 16.5448 11.2435 16.3396 11.3325 16.1625L13.035 12.75H9.74995C9.62211 12.7496 9.49648 12.7166 9.385 12.654C9.27353 12.5914 9.17991 12.5013 9.11303 12.3923C9.04615 12.2834 9.00823 12.1591 9.00288 12.0314C8.99753 11.9037 9.02492 11.7767 9.08245 11.6625L11.3325 7.1625C11.3721 7.06729 11.431 6.98128 11.5054 6.90985C11.5797 6.83841 11.6681 6.78309 11.7648 6.74733C11.8615 6.71158 11.9646 6.69616 12.0676 6.70204C12.1706 6.70792 12.2712 6.73497 12.3632 6.78151C12.4553 6.82804 12.5367 6.89306 12.6025 6.9725C12.6683 7.05194 12.717 7.14409 12.7455 7.2432C12.7741 7.3423 12.7819 7.44623 12.7684 7.54848C12.755 7.65074 12.7206 7.74913 12.6675 7.8375L10.965 11.25H14.25C14.3778 11.2504 14.5034 11.2834 14.6149 11.346C14.7264 11.4086 14.82 11.4987 14.8869 11.6077C14.9538 11.7166 14.9917 11.8409 14.997 11.9686C15.0024 12.0963 14.975 12.2233 14.9175 12.3375Z"
                      fill="##E8BD25"
                    />
                  </svg>
                  <div className={`text-sm font-semibold ml-1 text-center ${villadata.status !== "OPEN/BOOKABLE" ? 'hidden' : 'block'}`}>
                    {generateLastBooked(villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, villadata.area)}
                  </div>
                </>
              ) : (
                <>
                  <img
                    src="./recommended.png"
                    className="ml-2"
                    alt=""
                    width={"25px"}
                    height={"20px"}
                  />
                  <div className="  font-semibold ml-1 text-center my-auto">
                    Recommended
                  </div>
                </>
              )}
              {/* <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
              >
                <path
                  d="M23.0325 11.4675L12.5325 0.9675C12.4627 0.897204 12.3798 0.841408 12.2884 0.803331C12.197 0.765255 12.099 0.745651 12 0.745651C11.9009 0.745651 11.8029 0.765255 11.7115 0.803331C11.6201 0.841408 11.5372 0.897204 11.4675 0.9675L0.967454 11.4675C0.897158 11.5372 0.841362 11.6202 0.803286 11.7116C0.765209 11.803 0.745605 11.901 0.745605 12C0.745605 12.099 0.765209 12.197 0.803286 12.2884C0.841362 12.3798 0.897158 12.4628 0.967454 12.5325L11.4675 23.0325C11.5372 23.1028 11.6201 23.1586 11.7115 23.1967C11.8029 23.2347 11.9009 23.2543 12 23.2543C12.099 23.2543 12.197 23.2347 12.2884 23.1967C12.3798 23.1586 12.4627 23.1028 12.5325 23.0325L23.0325 12.5325C23.1028 12.4628 23.1585 12.3798 23.1966 12.2884C23.2347 12.197 23.2543 12.099 23.2543 12C23.2543 11.901 23.2347 11.803 23.1966 11.7116C23.1585 11.6202 23.1028 11.5372 23.0325 11.4675ZM14.9175 12.3375L12.6675 16.8375C12.6051 16.9612 12.5098 17.0652 12.392 17.138C12.2741 17.2108 12.1385 17.2496 12 17.25C11.8823 17.2514 11.7662 17.223 11.6625 17.1675C11.4858 17.0776 11.3519 16.9214 11.2901 16.7331C11.2283 16.5448 11.2435 16.3396 11.3325 16.1625L13.035 12.75H9.74995C9.62211 12.7496 9.49648 12.7166 9.385 12.654C9.27353 12.5914 9.17991 12.5013 9.11303 12.3923C9.04615 12.2834 9.00823 12.1591 9.00288 12.0314C8.99753 11.9037 9.02492 11.7767 9.08245 11.6625L11.3325 7.1625C11.3721 7.06729 11.431 6.98128 11.5054 6.90985C11.5797 6.83841 11.6681 6.78309 11.7648 6.74733C11.8615 6.71158 11.9646 6.69616 12.0676 6.70204C12.1706 6.70792 12.2712 6.73497 12.3632 6.78151C12.4553 6.82804 12.5367 6.89306 12.6025 6.9725C12.6683 7.05194 12.717 7.14409 12.7455 7.2432C12.7741 7.3423 12.7819 7.44623 12.7684 7.54848C12.755 7.65074 12.7206 7.74913 12.6675 7.8375L10.965 11.25H14.25C14.3778 11.2504 14.5034 11.2834 14.6149 11.346C14.7264 11.4086 14.82 11.4987 14.8869 11.6077C14.9538 11.7166 14.9917 11.8409 14.997 11.9686C15.0024 12.0963 14.975 12.2233 14.9175 12.3375Z"
                  fill="#E8BD25"
                />
              </svg>
              <div className="text-sm font-semibold ml-1 text-center">
                Last Booked 1 hr Ago
              </div> */}
            </div>
          </div>
          </a> 
          
          <div>
            <RWebShare
              data={{
                url: `http://www.a2zvillaz.com/villa/${villadata.property_ptr_id}/${villadata?.listing_name?.replace(/\s+/g, "-")}`,
                title: "A2Z Villaz",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className=" cursor-pointer">

                <svg
                  width="30"
                  height="32"
                  viewBox="0 0 30 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.0548 21.0469C22.7 21.0464 21.399 21.5769 20.4307 22.5246L10.9923 17.2179C11.1863 16.4176 11.1863 15.5824 10.9923 14.7821L20.4277 9.47543C21.3092 10.3329 22.4671 10.8485 23.6941 10.9302C24.9211 11.0118 26.1371 10.6541 27.1244 9.92096C28.1117 9.18786 28.8058 8.12729 29.0825 6.92912C29.3592 5.73095 29.2004 4.47341 28.6346 3.38163C28.0687 2.28985 27.1327 1.43511 25.9942 0.970451C24.8557 0.505787 23.5889 0.461531 22.4207 0.845605C21.2525 1.22968 20.2592 2.01701 19.6185 3.06663C18.9778 4.11626 18.7317 5.35966 18.9241 6.57422L9.26599 12.007C8.50737 11.375 7.58435 10.9722 6.60505 10.8459C5.62576 10.7196 4.63074 10.8749 3.73657 11.2938C2.84241 11.7127 2.08612 12.3777 1.55631 13.2109C1.0265 14.0441 0.745117 15.0111 0.745117 15.9985C0.745117 16.9859 1.0265 17.9529 1.55631 18.7861C2.08612 19.6194 2.84241 20.2844 3.73657 20.7032C4.63074 21.1221 5.62576 21.2775 6.60505 21.1511C7.58435 21.0248 8.50737 20.622 9.26599 19.99L18.9241 25.4258C18.7571 26.4751 18.9159 27.5503 19.3789 28.5065C19.842 29.4628 20.5871 30.2541 21.5139 30.7737C22.4406 31.2934 23.5044 31.5164 24.5618 31.4127C25.6192 31.309 26.6194 30.8837 27.4275 30.1939C28.2357 29.5042 28.8129 28.5833 29.0815 27.5553C29.35 26.5273 29.2969 25.4417 28.9294 24.4449C28.5618 23.448 27.8974 22.5878 27.0258 21.9802C26.1542 21.3726 25.1173 21.0469 24.0548 21.0469Z"
                    fill="#020202"
                  />
                </svg>
              </button>
            </RWebShare>
          </div>
        </div>
        <div className="flex flex-row w-full -mt-2 lg:mt-0 items-center">
          <div
            className=" hidden lg:block text-[22px] lg:text-[24px] font-[600] hover:underline hover:cursor-pointer w-full "
            // onClick={() => {
            //   handleCardClick(
            //     villadata.property_ptr_id,
            //     villadata.property_name
            //   );
            // }}
          >
            <a href={url}>
            {villadata.listing_name?villadata.listing_name:villadata.property_name}
            </a>
          </div>
          <div
            className="lg:hidden block  text-[22px] lg:text-[24px] font-[600] hover:underline hover:cursor-pointer w-full capitalize"
            // onClick={() => {
            //   handleCardClick(
            //     villadata.property_ptr_id,
            //     villadata.property_name
            //   );
            // }}
          ><a href={url}>
              {villadata.listing_name?villadata.listing_name:(villadata.property_name.length > 18
              ? villadata.property_name.slice(0, 18).toLowerCase() + ".."
              : villadata.property_name.toLowerCase())}
          </a>
            
          </div>
          <div className="lg:hidden items-center ml-2 flex flex-row w-fit justify-between  ">
          <a href={url}>
            <div className="items-center mr-1 flex flex-row">
              {generateStars(villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, villadata.area).map(elem=>(
                <svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.12914 6.4475C8.12914 6.4475 4.09136 6.885 1.3978 7.1775C1.15502 7.20625 0.940358 7.365 0.859858 7.60625C0.779358 7.8475 0.859858 8.09875 1.03875 8.2575C3.03975 10.0437 6.04636 12.7187 6.04636 12.7187C6.0438 12.7187 5.22091 16.61 4.67403 19.2062C4.62803 19.4412 4.71491 19.69 4.92447 19.8387C5.13275 19.9875 5.40108 19.99 5.61191 19.8737C7.96814 18.5637 11.4974 16.5937 11.4974 16.5937C11.4974 16.5937 15.0279 18.5637 17.3802 19.875C17.5949 19.99 17.8632 19.9875 18.0715 19.8387C18.2811 19.69 18.368 19.4412 18.3207 19.2075C17.7738 16.61 16.9522 12.7187 16.9522 12.7187C16.9522 12.7187 19.9588 10.0437 21.9598 8.26125C22.1387 8.0975 22.2179 7.84625 22.1387 7.60625C22.0595 7.36625 21.8448 7.2075 21.602 7.18C18.9085 6.885 14.8694 6.4475 14.8694 6.4475C14.8694 6.4475 13.1955 2.825 12.08 0.409996C11.9752 0.193746 11.7555 0.0424957 11.4974 0.0424957C11.2392 0.0424957 11.0182 0.194996 10.9185 0.409996C9.80175 2.825 8.12914 6.4475 8.12914 6.4475Z"
                  fill={elem}
                />
              </svg>
              ))}
              ({villadata.review_count>0?villadata.review_count:generateReviewCount(villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, 30, 80)})
            </div>
            </a>
            <div className="lg:hidden">
              <RWebShare
                data={{
                  url: `http://www.a2zvillaz.com/villa/${villadata.property_ptr_id}/${villadata?.listing_name?.replace(/\s+/g, "-")}`,
                  title: "A2Z Villaz",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <button className=" cursor-pointer">

                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 30 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.0548 21.0469C22.7 21.0464 21.399 21.5769 20.4307 22.5246L10.9923 17.2179C11.1863 16.4176 11.1863 15.5824 10.9923 14.7821L20.4277 9.47543C21.3092 10.3329 22.4671 10.8485 23.6941 10.9302C24.9211 11.0118 26.1371 10.6541 27.1244 9.92096C28.1117 9.18786 28.8058 8.12729 29.0825 6.92912C29.3592 5.73095 29.2004 4.47341 28.6346 3.38163C28.0687 2.28985 27.1327 1.43511 25.9942 0.970451C24.8557 0.505787 23.5889 0.461531 22.4207 0.845605C21.2525 1.22968 20.2592 2.01701 19.6185 3.06663C18.9778 4.11626 18.7317 5.35966 18.9241 6.57422L9.26599 12.007C8.50737 11.375 7.58435 10.9722 6.60505 10.8459C5.62576 10.7196 4.63074 10.8749 3.73657 11.2938C2.84241 11.7127 2.08612 12.3777 1.55631 13.2109C1.0265 14.0441 0.745117 15.0111 0.745117 15.9985C0.745117 16.9859 1.0265 17.9529 1.55631 18.7861C2.08612 19.6194 2.84241 20.2844 3.73657 20.7032C4.63074 21.1221 5.62576 21.2775 6.60505 21.1511C7.58435 21.0248 8.50737 20.622 9.26599 19.99L18.9241 25.4258C18.7571 26.4751 18.9159 27.5503 19.3789 28.5065C19.842 29.4628 20.5871 30.2541 21.5139 30.7737C22.4406 31.2934 23.5044 31.5164 24.5618 31.4127C25.6192 31.309 26.6194 30.8837 27.4275 30.1939C28.2357 29.5042 28.8129 28.5833 29.0815 27.5553C29.35 26.5273 29.2969 25.4417 28.9294 24.4449C28.5618 23.448 27.8974 22.5878 27.0258 21.9802C26.1542 21.3726 25.1173 21.0469 24.0548 21.0469Z"
                      fill="#020202"
                    />
                  </svg>
                </button>
              </RWebShare>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center ">
          {/* -mt-1 lg:mt-2 */}
          <svg
            width="15"
            height="22.5"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.15147 0.837646C1.87723 0.837646 0.844696 1.87598 0.844696 3.15795C0.844696 5.37385 3.15147 8.26263 3.15147 8.26263C3.15147 8.26263 5.45825 5.37385 5.45825 3.15795C5.45825 1.87598 4.42571 0.837646 3.15147 0.837646ZM3.15147 4.2833C2.54819 4.2833 2.06093 3.79217 2.06093 3.18696C2.06093 2.58174 2.55012 2.08868 3.15147 2.08868C3.75475 2.08868 4.24202 2.57981 4.24202 3.18696C4.24202 3.79217 3.75475 4.2833 3.15147 4.2833Z"
              fill="black"
            />
          </svg>
          <Link
            to={
              "https://maps.google.com/?q=" +
              villadata.geo_lat +
              "," +
              villadata.geo_long
            }
            className=""
          >
            <p className="text-gray-600 ml-1 text-sm lg:text-base underline ">
              {villadata.building ? villadata.building : city}
            </p>
          </Link>
        </div>
        <a href={url}>
        <div className="grid grid-cols-4 gap-2 mt-4 mb-5 lg:mb-5 lg:mt-5 space-x-[3px] lg:space-x-2">
          <div className="bg-white border-[#CACACA]  p-[3px] h-fit lg:h-auto lg:p-2 rounded-sm lg:rounded-md shadow-md text-center">
            <p className=" font-bold text-[12px] lg:text-lg">
              {villadata.size_of_property ? villadata.size_of_property : "N/A"}{" "}
              Sq.ft{" "}
            </p>
            <p className="text-sm lg:text-base">Area</p>
          </div>
          <div className="bg-white border-[#CACACA] p-[3px] h-fit lg:h-auto lg:p-2 rounded-sm lg:rounded-md shadow-md text-center">
            <p className=" font-bold text-[12px] lg:text-lg">
              {villadata.number_of_bedrooms
                ? villadata.number_of_bedrooms
                : "N/A"}
            </p>
            <p className="text-sm lg:text-base">BHK</p>
          </div>
          <div className="bg-white border-[#CACACA] p-[3px] h-fit lg:h-auto lg:p-2 rounded-sm lg:rounded-md shadow-md text-center">
            <p className=" font-bold text-[11px] lg:text-lg">
              {villadata.number_of_bedrooms
                ? villadata.number_of_bedrooms
                : "N/A"}{" "}
            </p>
            <p className="text-sm lg:text-base">Bedrooms</p>
          </div>
          <div className="bg-white border-[#CACACA]  p-[3px] h-fit lg:h-auto lg:p-2 rounded-sm lg:rounded-md shadow-md text-center">
            <p className=" font-bold text-[11px] lg:text-lg">
              {villadata.number_of_bathrooms
                ? villadata.number_of_bathrooms
                : "N/A"}{" "}
            </p>
            <p className="text-sm lg:text-base">Bathrooms</p>
          </div>
        </div>
        </a>
        
        <div className="flex flex-wrap lg:flex-row lg:flex-wrap text-[16px] text-black -mt-1 lg:mt-4">
          <div className="custom-scrollbar2 overflow-x-auto whitespace-nowrap">
            {filteredArray.map((a) => (
              <a href={url}>
              <div className=" mr-2 lg:mb-4 mb-1  inline-block">
                <div className="relative">
                  <img
                    src={a.icon}
                    alt={a.name}
                    className="w-[28px] lg:w-[35px] mr-2 transition-all"
                    // onMouseOver={(e) => {
                    //   e.currentTarget.nextSibling.classList.remove("hidden");
                    //   e.currentTarget.nextSibling.classList.add("flex");
                    // }}
                    // onMouseOut={(e) => {
                    //   e.currentTarget.nextSibling.classList.remove("flex");
                    //   e.currentTarget.nextSibling.classList.add("hidden");
                    // }}
                  />
                  {/* <div className="hidden bg-[#2A2A2B] text-white text-sm rounded absolute bottom-full w-fit left-1/2 transform -translate-x-1/2 -translate-y-2 py-1 px-2">
                    {a.name}
                  </div> */}
                </div>
              </div>
              </a>
            ))}
          </div>
        </div>
        
      </div>
    
      <a href={url} className="w-full md:w-[17%] rounded-b-3xl lg:rounded-b-2xl lg:rounded-2xl bg-[#2A2A2B] text-white flex flex-col justify-between p-2">
        
        <div className="h-full justify-center flex flex-col ">
        
          <div className="flex justify-between space-x-1 px-1 lg:px-2">
            <div className="hidden lg:flex flex-row lg:items-start items-center lg:ml-0 lg:mt-0 ml-2 mt-1 lg:scale-[1.0] scale-[1.5]">
              <svg
                width="47.269"
                height="65"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=""
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.70096 0.700317C0.87253 0.700317 0.200958 1.37189 0.200958 2.20032V16.051C0.200958 16.5779 0.477406 17.0662 0.929214 17.3372L6.92921 20.9372C7.40423 21.2222 7.99767 21.2222 8.47269 20.9372L14.4727 17.3372C14.9245 17.0662 15.2009 16.5779 15.2009 16.051V2.20032C15.2009 1.37189 14.5294 0.700317 13.7009 0.700317H1.70096ZM1.70059 2.80039C2.03196 2.80039 2.30058 2.53176 2.30058 2.20039C2.30058 1.86902 2.03196 1.60039 1.70059 1.60039C1.36921 1.60039 1.10059 1.86902 1.10059 2.20039C1.10059 2.53176 1.36921 2.80039 1.70059 2.80039Z"
                  fill="white"
                />
              </svg>
              <div className="absolute   mt-4 text-[#5C18CC]">
                <p className="ml-2 text-lg -mt-1 font-bold">{generateDiscount(villadata.number_of_bathrooms,villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, 10, 25)}%</p>
                <p className="text-center ml-1 -mt-1 font-bold">off</p>
              </div>
            </div>
            <div className="lg:hidden flex flex-row lg:items-start items-center lg:ml-0 lg:mt-0 ml-2 mt-1 lg:scale-[1.0] scale-[1.5]">
              <svg
                width="35.36"
                height="50"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=""
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.70096 0.700317C0.87253 0.700317 0.200958 1.37189 0.200958 2.20032V16.051C0.200958 16.5779 0.477406 17.0662 0.929214 17.3372L6.92921 20.9372C7.40423 21.2222 7.99767 21.2222 8.47269 20.9372L14.4727 17.3372C14.9245 17.0662 15.2009 16.5779 15.2009 16.051V2.20032C15.2009 1.37189 14.5294 0.700317 13.7009 0.700317H1.70096ZM1.70059 2.80039C2.03196 2.80039 2.30058 2.53176 2.30058 2.20039C2.30058 1.86902 2.03196 1.60039 1.70059 1.60039C1.36921 1.60039 1.10059 1.86902 1.10059 2.20039C1.10059 2.53176 1.36921 2.80039 1.70059 2.80039Z"
                  fill="white"
                />
              </svg>
              <div className="absolute   mt-4 text-[#5C18CC]">
                <p className="lg:ml-2 ml-1 text-sm lg:text-lg -mt-3 lg:-mt-1 font-bold">{generateDiscount(villadata.number_of_bathrooms,villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, 10, 25)}%</p>
                <p className="text-center text-[10px] lg:text-base ml-1 -mt-1 font-bold">off</p>
              </div>
            </div>

            <div className="text-end  ">
              <div className="text-sm lg:text-base">
                <s className="text-red-600">
                  <span className="text-white">
                  ₹{Math.floor((totalPrice + extraPrice)/(1-(generateDiscount(villadata.number_of_bathrooms,villadata.property_name, villadata.price_guest_pay, villadata.number_of_adults, 10, 25)/100.00) ) )}
                  </span>
                </s>
              </div>
              <div className="text-[27px] -mt-2 -mb-2 lg:mb-0 lg:mt-0 lg:text-[25px]">
              ₹{totalPrice + extraPrice}
              </div>

              <div className="flex text-[10px] lg:text-sm text-end  space-y-1 flex-col">
                <div className="mb-0 lg:mb-1">Total</div>
                <div className="">
                  {villadata.is_tax_included ? "+₹0" : "+₹250"} TAX AND FEE
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div className=" flex flex-col h-5/12">
          <div className="hidden lg:block text-[13px] underline text-end lg:text-center">
            Explore Prepayment Policy
          </div>
        </div>
      </a>
    </div>
  );
};

export default HotelCard;

// -mt-2 -mb-2 lg:-mb-0 lg:-mt-0
