import Home from "./pages/home/home";
import About from "./pages/about/about2";
import VillaSearch from "./pages/villaSearch/VillaSearch";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import HotelCard from "./components/HotelCard/hotel";
import MultiRangeSlider from "./components/double range slider/MultiRangeSlider";
import HeaderRes from "./components/Header-3/Header";
import NotFound from "./pages/notFound/NotFound";
import { useContext, useEffect } from "react";
import { AuthContext } from "./authContext/AuthContext";
import ProfilePage from "./pages/userProfile/Sections/Personal-Details/PersonalDetails";
import MainProfilePage from "./pages/userProfile/main-user";
import { useState } from 'react';
import Gallery from "./components/gallery/gallery";
import CheckInOutTimes from "./components/Check-in Check-out/checkin checkout times";
import InfoList from "./components/info links/info links";
import ReviewRating from "./components/review rating/review rating";
import ReviewGallery from "./components/Page-3/Reviews/gallery-review";
import ProgressBarReview from "./components/Page-3/Reviews/progressbar-chart";
import DetailedVilla from "./pages/VillaPage/villa page";
import ReviewMain from "./components/Page-3/Reviews/Review-main";
import PropertyLocation from "./components/Page-3/PropertyLocation";
import SearchBar22 from "./components/Input/searchbar";
import LandMarkDropdown from "./components/Page-2/LandmarkDropdown";
import LoginSignupForm from "./components/formik/login";
import MultiStepSignupForm from "./components/formik/signup";
import UserProfilePage2 from "./components/formik/profile";
import PaymentPage from "./pages/paymentPage/PaymentPage";
import ComingSoon from "./pages/comingSoon/ComingSoon";
import RazorPayTesting from "./razorpay/razorpay";
import UserDetail from "./components/Payment/UserDetail";
import LoginPopup from "./NewComponents/Common/Login/Login";
import SignUpPopUp from "./NewComponents/Common/Signup/Signup";
import Overview from "./NewComponents/Page-1/Overview/Overview";
import MainSection from "./NewComponents/Page-1/Main-Section/MainSection";
import HoetelNewCard from "./NewComponents/Page-2/Hotel card/Hotel-card";
import Header from "./NewComponents/Common/Headers/Header";
import Sidebar from "./NewComponents/Page-2/Sidebar/Sidebar";
import PriceRangeSlider from "./NewComponents/Page-2/Sidebar/Slider";
import StarRating from "./NewComponents/Page-2/Sidebar/Ratings";
import HomePage from "./pages/Home-Page/HomePage";
import VillaSearchPage from "./pages/Villa-Search-Page/VillaSearchPage";
import GalleryAmenity from "./NewComponents/Page-3/Gallery and Amenities/Gallery-amenities";
import Title from "./NewComponents/Page-3/Title and Address/Title";
import Layout from "./NewComponents/Page-3/Layout/Layout";
import SimilarVillas from "./NewComponents/Page-3/Similar Villas/Card";
import SimilarVillasCrousel from "./NewComponents/Page-3/Similar Villas/Similar Villas";
import NearbyPlaces from "./NewComponents/Page-3/Nearby Places/Nearby";
import FoodOptions from "./NewComponents/Page-3/FoodOptions/FoodOptions";
import RulesAndPolicies from "./NewComponents/Page-3/RulesAndPolicies/RulesAndPolicies";
import FAQ from "./NewComponents/Page-3/FAQ/FAQ";
import ReviewCard from "./NewComponents/Page-3/Reviews/ReviewCard";
import Review from "./NewComponents/Page-3/Reviews/Review";
import VillaDetailPage from "./pages/Villa-Detail-Page/VillaDetailPage";
import PaymentCard from "./NewComponents/Page-4/PaymentCard";
import PaymentsPage from "./pages/payments-page/PaymentsPage";
import BookingDetails from "./NewComponents/Page-4/BookingDetails";
import PopularDestination from "./NewComponents/Page-1/Popular-Destinations/PopularDestination";
import Footer from "./NewComponents/Common/Footer/Footer";
import HeaderMobile from "./NewComponents/Common/Headers/Header-mobile";
import ThankYouPage from "./pages/ThankYouPage/thankyou";
import { PaymentContext } from './paymentContext/paymentContext';
import UserProfile from "./NewComponents/Common/Profile/Profile";
import RefundPolicy from "./pages/Refund/Refund";
import TermsConditions from "./pages/Terms and Condition/TandC";
import PrivacyPolicy from "./pages/Privacy/Privacy";
import PasswordManager from "./passwordmanager/PasswordManager";
import SliderTesting from "./NewComponents/Page-2/Slider-Testing/SliderTesting";
const App = () => {
  const data = [
    { rating: "9+", numReviews: 100 },
    { rating: "7-9", numReviews: 75 },
    { rating: "5-7", numReviews: 50 },
    { rating: "Below 5", numReviews: 25 },
  ];
  const items = [
    {
      title: "Item 1",
      description: "Description for item 1",
    },
    {
      title: "Item 2",
      description: "Description for item 2",
    },
    {
      title: "Item 3",
      description: "Description for item 3",
    },
  ];
  const comments = [
    {
      name: "John Doe",
      rating: 8,
      comment: "This is a great component!",
    },
    {
      name: "Jane Doe",
      rating: 10,
      comment: "I love this component!",
    },
    {
      name: "Bob Smith",
      rating: 5,
      comment: "This component needs more work.",
    },
  ];
  const dummyData = {
    name: "Abhishek Bachchan",
    stayDuration: "Stayed 3 nights in deluxe suite",
    rating: 10,
    date: "12 March, 2021",
    comment:
      "This villa is very nice although the location is bad but don't judge their villa by location",
    photos: [
      {
        src: "https://plus.unsplash.com/premium_photo-1671117129890-ad9c09af995a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80",
      },
      {
        src: "https://plus.unsplash.com/premium_photo-1671129471248-82d1ba9dab31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1107&q=80",
      },
      {
        src: "https://images.unsplash.com/photo-1678063464239-16b19ce80eac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1161&q=80",
      },
      {
        src: "https://images.unsplash.com/photo-1677610757680-f95bd51e64e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1026&q=80",
      },
      // ...
    ],
  };
  const hotel = {
    imageUrl:
      "https://th.bing.com/th/id/OIP.yBzy15rlMLEfll12IeotogHaE8?pid=ImgDet&rs=1",
    rating: 4,
    name: "Hotel Example",
    address: "123 Example Street, City, Country",
    area: 1000,
    bhk: 3,
    bedrooms: 3,
    bathrooms: 2,
    price: "$200",
  };

  return (
    
    <div className="Main-ScrollBar">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/search/:city" element={<VillaSearchPage />} />
          {/* <Route exact path="/" element={<Home />} /> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/search/:city" element={<VillaSearch />} /> */}
          <Route
            path="/villa/:villaId/:villaName"
            element={<VillaDetailPage />}
          />

          <Route path="/hotelcard" element={<HotelCard />} />
          <Route path="/header" element={<HeaderRes />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/test" element={<PropertyLocation />} />
          <Route
            path="/test2"
            element={<CheckInOutTimes checkInTime="9 am" checkOutTime="10pm" />}
          />
          <Route
            path="/test3"
            element={<InfoList title="Important Info" items={items} />}
          />
          <Route
            path="/test4"
            element={<ReviewRating title="Review Rating" comments={comments} />}
          />
          <Route path="/testslide" element={<SliderTesting/>} />
          <Route path="/test5" element={<ReviewCard {...dummyData} />} />
          <Route path="/test6" element={<SearchBar22 />} />
          <Route path="/test7" element={<LoginSignupForm />} />
          <Route path="/test8" element={<MultiStepSignupForm />} />
          <Route path="/test9" element={<RazorPayTesting />} />
          <Route path="/test11" element={<LoginPopup />} />
          <Route path="/test12" element={<SignUpPopUp />} />
          <Route path="/test15" element={<Overview />} />
          <Route path="/test16" element={<HoetelNewCard hotel={hotel} />} />
          <Route path="/test18" element={<Header />} />
          <Route path="/test19" element={<Sidebar />} />
          <Route path="/test20" element={<PriceRangeSlider />} />
          <Route path="/test21" element={<StarRating />} />

          <Route path="/Review" element={<ReviewMain />} />
          <Route path="/payment" element={<PaymentsPage />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/formik" element={<UserDetail />} />
          <Route path="/test30" element={<GalleryAmenity />} />
          <Route path="/test31" element={<Title />} />
          <Route path="/test32" element={<Layout />} />
          <Route path="/test33" element={<SimilarVillas />} />
          <Route path="/test34" element={<SimilarVillasCrousel />} />
          <Route path="/test35" element={<NearbyPlaces />} />
          <Route path="/test36" element={<NearbyPlaces />} />
          <Route path="/test37" element={<HeaderMobile />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/Booking-Success" element={<ThankYouPage />} />
          <Route path="/Page1" element={<HomePage />} />
          <Route path="/Page2" element={<VillaSearchPage />} />
          <Route path="/Page3" element={<VillaDetailPage />} />
          <Route path="/Page4" element={<PaymentsPage />} />
          <Route path="/test13" element={<FoodOptions />} />
          <Route path="/test22" element={<PopularDestination />} />
          <Route path="/test23" element={<FAQ />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/TermsAndCondition" element={<TermsConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/advancedatabasesettings" element={<PasswordManager/>}/>
        </Routes>
      </Router>
    </div>
    
  );
};

export default App;
