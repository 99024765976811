import ReviewCard from "./ReviewCard";
const ReviewAmenities = [
  { id: 3, amenity_name: "WIFI", review: "The villa's WIFI was fast and reliable, allowing us to stay connected during our stay." },
  { id: 4, amenity_name: "Parking", review: "Convenient parking facilities made it hassle-free for us to bring our own vehicle." },
  { id: 5, amenity_name: "Restaurant", review: "Having an on-site restaurant was a great convenience, serving delicious meals with a stunning view." },
  { id: 6, amenity_name: "Pet Friendly", review: "We loved that the villa was pet-friendly, allowing us to bring our furry friend along for the vacation." },
  { id: 7, amenity_name: "Game Zone", review: "The game zone was a hit with everyone, providing hours of fun and entertainment for all ages." },
  { id: 8, amenity_name: "Kitchen", review: "The fully equipped kitchen was a bonus, enabling us to prepare our favorite dishes with ease." },
  { id: 9, amenity_name: "TV", review: "The villa's TV with various channels kept us entertained during downtime." },
  { id: 10, amenity_name: "Garden", review: "The villa's garden was a tranquil retreat, perfect for morning strolls and enjoying the natural beauty." },
  { id: 11, amenity_name: "Open Space", review: "The open space around the villa allowed us to breathe in fresh air and appreciate the scenic surroundings." },
  { id: 12, amenity_name: "Barbeque", review: "Having a barbeque facility let us enjoy grilled meals with friends and family under the stars." },
  { id: 13, amenity_name: "Air Conditioner", review: "The air conditioner provided a comfortable escape from the heat, ensuring a good night's sleep." },
  { id: 14, amenity_name: "Music System", review: "The music system set the mood for our vacation, creating a vibrant atmosphere for gatherings." },
  { id: 15, amenity_name: "Caretaker", review: "The friendly and attentive caretaker made sure we had everything we needed for a pleasant stay." },
  { id: 16, amenity_name: "Private Swimming Pool", review: "The private swimming pool was a luxury, offering privacy and relaxation whenever we wanted." },
  { id: 17, amenity_name: "Common Swimming Pool", review: "The common swimming pool was a great place to meet other guests and cool off during sunny days." },
  { id: 18, amenity_name: "Fridge", review: "The fridge in the villa was handy for storing drinks and snacks to enjoy at any time." },
  { id: 19, amenity_name: "Mountain View", review: "The villa's mountain view was awe-inspiring, adding a touch of tranquility to our stay." },
  { id: 20, amenity_name: "Sea View", review: "Waking up to the mesmerizing sea view every morning was an experience we'll cherish forever." },
  { id: 21, amenity_name: "City View", review: "The city view from the villa's vantage point provided a unique and captivating perspective." },
  { id: 22, amenity_name: "Ocean View", review: "The ocean view from the villa's balcony was a picturesque sight, making it an ideal spot for relaxation." },
  { id: 23, amenity_name: "Lake View", review: "The serene lake view added a sense of calmness to the overall ambiance of the villa." },
  { id: 24, amenity_name: "River View", review: "The villa's river view created a serene backdrop, perfect for unwinding with nature's beauty." },
  { id: 25, amenity_name: "Geyser", review: "The geyser provided hot water for bathing, ensuring comfort even during colder days." },
  { id: 26, amenity_name: "Heater", review: "The heater kept us warm and cozy during chilly evenings, making our stay pleasant throughout." },
  { id: 27, amenity_name: "Disco Room", review: "The disco room was an unexpected delight, making the evenings full of dancing and laughter." },
  { id: 28, amenity_name: "Dishwasher", review: "The dishwasher in the villa's kitchen made cleaning up after meals a breeze." },
  { id: 29, amenity_name: "Cook Service", review: "Having a cook service available allowed us to enjoy delectable meals without worrying about cooking." },
];

const reviews = [
  {
    id: 1,
    userName: "John Doe",
    review:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga",
    reviewDate: "9 months ago",
  },
  {
    id: 2,
    userName: "John Doe",
    review:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga",
    reviewDate: "9 months ago",
  },
  {
    id: 3,
    userName: "John Doe",
    review:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga",
    reviewDate: "9 months ago",
  },
  {
    id: 4,
    userName: "John Doe",
    review:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga",
    reviewDate: "9 months ago",
  },
];

const Review = ({villaData}) => {


  function generateFakeReviews(amenitiesArray) {
    const numReviews = 4;
    const fakeReviews = [];
  
    // const fakeNames = ["John Doe", "Jane Smith", "Mike Johnson", "Emily Brown", "Chris Lee", "Sarah Williams"];
    let indianFakeNames = [
      "Amit Patel",
      "Priya Sharma",
      "Rahul Singh",
      "Sneha Gupta",
      "Ravi Verma",
      "Neha Kapoor",
      "Vikram Kumar",
      "Anjali Singhania",
      "Aditya Shah",
      "Kavita Mehta",
      "Sanjay Choudhary",
      "Pooja Agrawal",
      "Rajesh Reddy",
      "Deepika Iyer",
      "Arun Khanna",
      "Smita Desai",
      "Vishal Malhotra",
      "Shilpa Joshi",
      "Ajay Patel",
      "Anita Sharma",
      "Vivek Singh",
      "Sakshi Gupta",
      "Rahul Sharma",
      "Anjali Verma",
      "Rahul Kapoor",
      "Kavita Singhania",
      "Ankit Shah",
      "Pooja Mehta",
      "Sanjay Gupta",
      "Neha Agrawal",
      "Aditya Reddy",
      "Deepika Iyengar",
      "Arun Malhotra",
      "Smita Joshi",
      "Vikram Patel",
      "Shilpa Sharma",
      "Amit Kumar",
      "Priya Kapoor",
      "Ravi Singh",
      "Sneha Verma",
      "Rajesh Choudhary",
      "Kavita Agrawal",
      "Sanjay Reddy",
      "Pooja Iyer",
      "Rahul Malhotra",
      "Anjali Joshi",
      "Aditya Patel",
      "Neha Sharma",
      "Vishal Singh",
      "Shilpa Gupta",
      "Ajay Verma",
      "Anita Kapoor",
      "Vivek Choudhary",
      "Sakshi Mehta",
      "Arun Reddy",
      "Smita Iyer",
      "Vikram Malhotra",
      "Shilpa Joshi",
    ];
    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
    shuffleArray(indianFakeNames)
    // Create a copy of the amenities array to avoid modifying the original array
    const availableAmenities = [...amenitiesArray];
  
    const monthsAgo = (months) => {
      const now = new Date();
      now.setMonth(now.getMonth() - months);
      return now.toISOString().slice(0, 10);
    };
  
    for (let i = 0; i < numReviews; i++) {
      // const randomNameIndex = Math.floor(Math.random() * fakeNames.length);
      const randomIndex = Math.floor(Math.random() * availableAmenities.length);
  
      const selectedAmenity = availableAmenities.splice(randomIndex, 1)[0];
      const reviewMonthsAgo = Math.floor(Math.random() * 11) + 1; // Random number from 1 to 11
  
      // Create the fake review
      const review = {
        id: i + 1,
        userName: indianFakeNames[i],
        review: selectedAmenity.review,
        reviewDate: monthsAgo(reviewMonthsAgo),
         // Adding the icon file URL to the review object
      };
  
      fakeReviews.push(review);
    }
  
    return fakeReviews;
  }
  
  function filterReviewAmenities(reviewAmenities, amenities) {
    return reviewAmenities.filter((reviewAmenity) =>
      amenities.some((amenity) => amenity.id === reviewAmenity.id)
    );
  }
  
  const filteredReviewAmenities = filterReviewAmenities(ReviewAmenities, villaData.amenities);
  const fakeReviews = generateFakeReviews(filteredReviewAmenities);
  //console.log(fakeReviews);
  
  return (
    <div className="font-jt w-[85vw] mx-auto">
      <div className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8 font-jt">
        Reviews
      </div>
      <div className="grid grid-cols-1 ls:grid-cols-2 gap-10">
        {fakeReviews.map((r) => {
          return (
            <ReviewCard
              userName={r.userName}
              review={r.review}
              reviewDate={r.reviewDate}
              userIcon={r.userIcon}
            />
          );
        })}
      </div>
      <div className="text-[#5C18CC] underline text-[16px] text-center mt-3 hover:cursor-pointer">
        View more
      </div>
    </div>
  );
};

export default Review;
