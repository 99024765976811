import React from "react";
import BasicHeader from "../../components/Payment/BasicHeader";

function ComingSoon() {
  return (
    <>
      <BasicHeader />
      <div className="text-center text-black font-bold text-[40px] items-center mt-48">
        This Page is coming soon!
      </div>
    </>
  );
}

export default ComingSoon;
