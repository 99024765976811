import { useState } from "react";
import {
  FiMapPin,
  FiCalendar,
  FiUser,
  FiMinus,
  FiPlus,
  FiSearch,
} from "react-icons/fi";
import DateCalender from "./about";
import InputBox from "./Input-box";
import { useNavigate } from 'react-router-dom';
const data = [
  {
    id: 2,
    name: "Goa",
  },
  {
    id: 29,
    name: "Kodaikanal",
  },
  {
    id: 21,
    name: "Dhanaulti",
  },
  {
    id: 22,
    name: "Mount Abu",
  },
  {
    id: 3,
    name: "Lonavala",
  },
  {
    id: 4,
    name: "Manali",
  },
  {
    id: 6,
    name: "Khopoli",
  },
  {
    id: 7,
    name: "Mahabaleshwar",
  },
  {
    id: 8,
    name: "Panchgani",
  },
  {
    id: 9,
    name: "Alibag",
  },
  {
    id: 10,
    name: "Pune",
  },
  {
    id: 11,
    name: "Udaipur",
  },
  {
    id: 12,
    name: "Indore",
  },
  {
    id: 13,
    name: "Madikeri",
  },
  {
    id: 14,
    name: "Nashik",
  },
  {
    id: 30,
    name: "Ooty",
  },
  {
    id: 15,
    name: "Neemuch",
  },
  {
    id: 16,
    name: "Palgarh",
  },
  {
    id: 17,
    name: "Uttan",
  },
  {
    id: 24,
    name: "Kasauli",
  },
  {
    id: 20,
    name: "Kamshet",
  },
  {
    id: 25,
    name: "Jaipur",
  },
  {
    id: 31,
    name: "Bengaluru",
  },
  {
    id: 18,
    name: "Mussoorie",
  },
  {
    id: 26,
    name: "Dehradun",
  },
  {
    id: 27,
    name: "Shimla",
  },
  {
    id: 19,
    name: "Raipur",
  },
  {
    id: 23,
    name: "Kanatal",
  },
  {
    id: 5,
    name: "Karjat",
  },
  {
    id: 28,
    name: "Lansdowne",
  },
  {
    id: 1,
    name: "Igatpuri",
  },
];

const SearchBar22 = ({
  location,
  setLocation,
  adults,
  setAdults,
  children,
  setChildren,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSearchClick,
}) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePopupClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAdultsMinusClick = () => {
    setAdults(Math.max(1, adults - 1));
  };

  const handleAdultsPlusClick = () => {
    setAdults(adults + 1);
  };

  const handleChildrenMinusClick = () => {
    setChildren(Math.max(0, children - 1));
  };

  const handleChildrenPlusClick = () => {
    setChildren(children + 1);
  };

  //////////////////////////////////////////////////////////////

  let countries = data;
  const [suggestion, setSuggestions] = useState([]);
  const [select, setSelect] = useState("");

  const handleChange = (e) => {
    let value = e.target.value;
    let matches = [];

    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = countries.filter((item) => regex.test(item.name));
    }
    setSuggestions(matches);
    setLocation(value);
  };

  const selectValue = (item) => {
    setSelect(item);
    setSuggestions("");
    setLocation(item);
    
  };

  const clearSearch = () => {
    setLocation("");
    setSuggestions("");
    setSelect("");
  };

  // //console.log(startDate, endDate, "DATES searcg");

  ///////////////////////////////////////////////////////

  return (
    <div className="flex w-full flex-col md:flex-row items-center justify-between bg-[#f1f1f1]  shadow-lg px-4 py-4 font-hk rounded-full">
      <div className="flex flex-row items-center w-full md:w-1/3 justify-center ">
        <div className="scale-[0.8] w-1/4">
          {/* <FiMapPin className="text-gray-500" /> */}
          {/* <i class="fa fa-2x fa-map-marker"></i> */}
          <img src="/Map.svg" />
        </div>

        <div className="relative w-3/4 bg-none">
          {/* <InputBox
            inputbox={location}
            setInputBox={setLocation}
            cities={data}
          /> */}

          <div className="font-hk ">
            <input
              className="outline-none py-1 w-full h-[7vh] mt-5 mx-5 md:mx-0 md:mt-0  md:h-auto text-center text-[18px] bg-transparent placeholder-zinc-600 lg:placeholder-black md:font-semibold lg:font-normal "
              type="text"
              placeholder="Type your Destination"
              value={location}
              onChange={handleChange}
              style={{ textTransform: "capitalize" }}
            />

            {suggestion?.length > 0 ? (
              <div className="flex flex-row mt-6 absolute px-2">
                <div className="bg-white cursor-pointer rounded-lg opacity-90 w-full">
                  {suggestion.slice(0, 8).map((item) => {
                    return (
                      <div
                        className=" suggestions text-center px-[20px] my-2 py-2 text-[14px] border-b-[1px] font-semibold "
                        key={item.id}
                        onClick={() => selectValue(item.name)}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>

          {/* ////////////////////////////////////// */}
        </div>
        {/* <input
          type="text"
          placeholder="Enter location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="outline-none border-none mx-4 md:mx-6 text-gray-500 w-full md:w-auto"
        /> */}
      </div>
      {/* <div className="flex items-center w-full md:w-auto mt-3 md:mt-0">
        <FiCalendar className="text-gray-500" />
        <input
          type="date"
          placeholder="Check in"
          value={checkIn}
          onChange={(e) => setCheckIn(e.target.value)}
          className="outline-none border-none mx-4 md:mx-6 text-gray-500 w-full md:w-auto"
        />
        <input
          type="date"
          placeholder="Check out"
          value={checkOut}
          onChange={(e) => setCheckOut(e.target.value)}
          className="outline-none border-none mx-4 md:mx-6 text-gray-500 w-full md:w-auto"
        />
      </div> */}
      <div className="flex w-full md:w-1/3 justify-center">
        <DateCalender
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      <div className="flex items-center w-full md:w-1/3 justify-center mt-3 md:mt-0">
        <FiUser className="text-gray-500" />
        <div className="relative">
          <button
            className="py-2 px-3  rounded-lg text-[18px] text-black "
            onClick={handlePopupClick}
          >
            {`Adults : ${adults}, Children : ${children}`}
          </button>
          {isPopupOpen && (
            <div
              className="absolute top-12 left-1 bg-white border border-gray-300 "
              onMouseLeave={handlePopupClick}
            >
              <div className="flex justify-between px-3 py-1">
                <div className="flex items-center">
                  <div>
                    <div className="text-gray-500">Adults</div>
                    <div className="flex items-center justify-center">
                      <button onClick={handleAdultsMinusClick}>
                        <FiMinus />
                      </button>
                      <div className="mx-2">{adults}</div>
                      <button onClick={handleAdultsPlusClick}>
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                  <div className="mx-4 border-l border-gray-400"></div>
                  <div>
                    <div className="text-gray-500">Children</div>
                    <div className="flex items-center">
                      <button onClick={handleChildrenMinusClick}>
                        <FiMinus />
                      </button>
                      <div className="mx-2">{children}</div>
                      <button onClick={handleChildrenPlusClick}>
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          className="ml-4 bg-black text-white px-4 py-2 rounded-lg"
          onClick={() => {
            handleSearchClick(true);
          }}
        >
          <FiSearch />
        </button>
      </div>
    </div>
  );
};

export default SearchBar22;
