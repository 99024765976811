import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BatchPasswordUpdate = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [newPassword, setNewPassword] = useState('');
  const [update,setUpdate]=useState(false);
  useEffect(() => {
    console.log(selectedUsers)
  }, [selectedUsers]);
  useEffect(() => {
    // Fetch user data from the backend
    axios.get(`${process.env.REACT_APP_API_AWS}/adminusersx0982/`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleUserSelect = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleBatchUpdate = () => {
    if (selectedUsers.length === 0) {
      console.error('Please select at least one user.');
      return;
    }

    if (!newPassword) {
      console.error('Please provide a new password.');
      return;
    }

    axios.post(`${process.env.REACT_APP_API_AWS}/adminusersx0982/batch_password_update/`, {
      user_ids: selectedUsers,
      new_password: newPassword,
    })
      .then((response) => {
        // Handle successful password update (optional)
        console.log('Passwords updated successfully.');
        setUpdate(true);
      })
      .catch((error) => {
        // Handle error if password update fails (optional)
        console.error('Error updating passwords:', error);
      });
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-4">Batch Password Update</h1>
      <div className="mb-4">
        <label className="block font-bold">New Password:</label>
        <input
          type="text"
          value={newPassword}
          onChange={handlePasswordChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold">Select Users:</label>
        <ul>
          {users.map((user) => (
            <li key={user.id} className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleUserSelect(user.id)}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2">
                ({user.user_type}) {user.first_name} {user.last_name} || {user.email}
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={handleBatchUpdate}
        className={`${
          update
            ? 'bg-green-500 hover:bg-green-400'
            : 'bg-blue-500 hover:bg-blue-400'
        } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
      >
        {!update ? 'Update Passwords' : 'Passwords Updated Successfully'}
      </button>
    </div>
  );
};

export default BatchPasswordUpdate;
