// App.js
import React, { useState } from 'react';
import KeyForm from './KeyForm';
import UserSelection from './UserSelection';
import BatchPasswordUpdate from './Batch';
import axios from 'axios';

const dummyUsers = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'john@example.com',
    password: 'password123',
    phoneNumber: '555-123-4567',
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    email: 'jane@example.com',
    password: 'secret456',
    phoneNumber: '555-987-6543',
  },
  // Add more dummy users as needed
];

const PasswordManager = () => {
    const [keyInput, setKeyInput] = useState('');
  const [validKey, setValidKey] = useState(false);
  const [users, setUsers] = useState([]);
  const [batch,setBatch]=useState(false);
  const checkKeyValidity = () => {
    axios
    .post(`${process.env.REACT_APP_API_AWS}/adminusersx0982/validate_key/`, { keyInput })
    .then((response) => {
      // If the API response indicates a valid key, set validKey to true and fetch users
      if (response.data.isValid) {
        setValidKey(true);
        // Call a function to fetch users from the backend
      } else {
        // Handle invalid key case
        console.error('Invalid key.');
      }
    })
    .catch((error) => {
      // Handle error case
      console.error('Error validating key:', error);
    });
    //    if(keyInput===keyy) {
    //     console.log(keyInput)
    //        setValidKey(true);
    //        setUsers(dummyUsers);
    //    }
    
  };

  return (
    <div className='font-jt'>
      {!validKey ? (
        <KeyForm keyInput={keyInput} setKeyInput={setKeyInput} onSubmit={checkKeyValidity} setBatch={setBatch} />
      ) : (
        !batch?(<UserSelection users={users} />)
        :(<BatchPasswordUpdate/>)
      )}
    </div>
  );
};

export default PasswordManager;
