import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const UserSelection = ({ onUserSelect }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
const [update,setUpdate]=useState(false);

  const handleUpdateUser = async () => {
    try {
      // Make a PUT request to update the user
      const response = await axios.put(`${process.env.REACT_APP_API_AWS}/adminusersx0982/${selectedUser.id}/`, selectedUser); // Replace with your API endpoint

      // Show a success toast notification
      setUpdate(true);
      // Handle the response or any other necessary actions after successful update
      //console.log('User updated:', response.data);
      // Optionally, you can call the onUserSelect callback to update the user on the parent component
    //   if (onUserSelect) {
    //     onUserSelect(response.data);
    //   }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  const handleUserDelete = () => {
    if (selectedUser) {
      axios.delete(`${process.env.REACT_APP_API_AWS}/adminusersx0982/${selectedUser.id}/delete/`)
        .then((response) => {
          // Handle successful user deletion (optional)
          setSelectedUser(null);
          // Optionally, you can update the user list after deletion
          // For example: onUserSelect(updatedUserList);
          console.log('User deleted successfully.');
        })
        .catch((error) => {
          // Handle error if deletion fails (optional)
          console.error('Error deleting user:', error);
        });
    }
  };
  useEffect(() => {
    // Fetch the user array using Axios
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_AWS}/adminusersx0982/`); // Replace with your API endpoint
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);
//   useEffect(() => {
//     console.log(selectedUser)
//   }, [selectedUser]);
  const handleUserSelect = (e) => {
    const selectedUserId = e.target.value;
    if (selectedUserId) {
      const user = users.find((user) => user.id === parseInt(selectedUserId));
      setSelectedUser(user);
      setUpdate(false);
    } else {
      setSelectedUser(null);
    }
  };

  const handleFieldChange = (field, value) => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const filteredUsers = users.filter(
    (user) =>
    user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex flex-col items-center justify-center my-8">
      <div className="w-full max-w-lg">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by name..."
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        />
        <select
          onChange={handleUserSelect}
          className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-4"
        >
          <option value="">Select a user</option>
          {filteredUsers.map((user) => (
            <option key={user.id} value={user.id}>
              ({user.user_type}) {user.first_name} {user.last_name} || {user.email}
            </option>
          ))}
        </select>
        {selectedUser && (
          <div className="mt-4">
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">First Name:</label>
              <input
                type="text"
                value={selectedUser.first_name || ''}
                onChange={(e) => handleFieldChange('first_name', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Last Name:</label>
              <input
                type="text"
                value={selectedUser.last_name || ''}
                onChange={(e) => handleFieldChange('last_name', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Email:</label>
              <input
                type="email"
                value={selectedUser.email || ''}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Password:</label>
              <input
                type="text"
                value={selectedUser.password || ''}
                onChange={(e) => handleFieldChange('password', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Phone Number:</label>
              <input
                type="text"
                value={selectedUser.phone_number || ''}
                onChange={(e) => handleFieldChange('phone_number', e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Is Superuser:</label>
              <input
                type="checkbox"
                checked={selectedUser.is_superuser || false}
                onChange={(e) => handleFieldChange('is_superuser', e.target.checked)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Is Staff:</label>
              <input
                type="checkbox"
                checked={selectedUser.is_staff || false}
                onChange={(e) => handleFieldChange('is_staff', e.target.checked)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Is Active:</label>
              <input
                type="checkbox"
                checked={selectedUser.is_active || false}
                onChange={(e) => handleFieldChange('is_active', e.target.checked)}
              />
            </div>
            {selectedUser && (
        <div className="mt-4">
          {/* ... */}
          <div className="text-end items-end justify-between flex">
          <button
                className='bg-red-500 text-white rounded-lg text-xl p-3 mr-2'
                onClick={handleUserDelete}
              >Delete</button>
            <button className="bg-black flex text-white rounded-lg text-xl p-3" onClick={handleUpdateUser}>
              {!update? "Update":"Successfully Updated"}
            </button>
          </div>
        </div>
      )}
      {/* The ToastContainer to display toast notifications */}

    
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSelection;
