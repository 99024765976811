// const Overview = () => {
//   return (
//     <div className="flex flex-wrap justify-center font-jt w-[90vw] mx-auto ">
//       <div className="w-[26%] shadow-md rounded-[22px] m-2">
//         <img src="/overview/9.svg" className="object-cover"></img>
//       </div>
//       <div className="flex flex-col w-[43%]">
//         <div className="flex flex-row justify-between m-2">
//           <div className="w-[33%] h-auto shadow-md rounded-[15px] mr-[6px]">
//             <img src="/overview/1.svg" className="object-cover"></img>
//           </div>
//           <div className="w-[33%] h-auto shadow-md rounded-[15px] ">
//             <img src="/overview/1.svg" className="object-cover"></img>
//           </div>
//           <div className="w-[33%] h-auto shadow-md rounded-[15px] ml-[6px]">
//             <img className="object-cover" src="/overview/1.svg"></img>
//           </div>
//         </div>
//         <div className=" h-1/3 bg-[#F4F4F4] shadow-md rounded-[25px] m-2 flex">
//           <div className="w-1/3 text-center flex flex-col justify-center">
//             <div className="text-[#020202] font-bold text-[38px]">800+</div>
//             <div className="text-[#020202] font-medium text-[20px]">
//               Properties
//             </div>
//           </div>
//           <div className="w-1/3 text-center flex flex-col justify-center">
//             <div className="text-[#020202] font-bold text-[38px]">45+</div>
//             <div className="text-[#020202] font-medium text-[20px]">Cities</div>
//           </div>
//           <div className="w-1/3 text-center flex flex-col justify-center">
//             <div className="text-[#020202] font-bold text-[38px]">80,000+</div>
//             <div className="text-[#020202] font-medium text-[20px]">
//               Happy Customers
//             </div>
//           </div>
//         </div>
//         <div className="flex flex-row justify-between m-2">
//           <div className="w-[33%] h-auto shadow-md rounded-[15px] mr-[6px]">
//             <img src="/overview/1.svg" className="object-cover"></img>
//           </div>
//           <div className="w-[33%] h-auto shadow-md rounded-[15px]">
//             <img src="/overview/1.svg" className="object-cover"></img>
//           </div>
//           <div className="w-[33%] h-auto shadow-md rounded-[15px] ml-[6px]">
//             <img src="/overview/1.svg" className="object-cover"></img>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col justify-between m-2 w-[26%]">
//         <div className=" h-1/2 shadow-md rounded-[22px]">
//           <img src="/overview/6.svg" className="object-cover"></img>
//         </div>
//         <div className=" h-1/2 shadow-md rounded-[22px]">
//           <img src="/overview/3.svg" className="object-cover"></img>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Overview;

const Overview = () => {
  return (
    <div className="flex flex-wrap justify-center font-jt w-full   lg:w-[90vw] mx-auto ">
      <div className="md:w-[26%] w-[35%] rounded-[8px] md:rounded-[22px] mx-2">
        <img
          src="/overview/9.svg"
          className="object-cover shadow-md rounded-[8px] md:rounded-[22px] "
        ></img>
      </div>
      <div className="md:flex flex-col w-[43%] hidden ">
        <div className="flex flex-row justify-between m-2">
          <div className="w-[33%] h-auto shadow-md rounded-[15px] mr-[6px]">
            <img src="/overview/1.svg" className="object-cover"></img>
          </div>
          <div className="w-[33%] h-auto shadow-md rounded-[15px] ">
            <img src="/overview/1.svg" className="object-cover"></img>
          </div>
          <div className="w-[33%] h-auto shadow-md rounded-[15px] ml-[6px]">
            <img className="object-cover" src="/overview/1.svg"></img>
          </div>
        </div>
        <div className=" h-1/3 bg-[#F4F4F4] shadow-md lg:rounded-[18px] rounded-[13px] ll:rounded-[22px] m-2 flex">
          <div className="w-1/3 text-center flex flex-col justify-center">
            <div className="text-[#020202] font-bold text-[22px] ls:text-[28px] ls:text[30px] lg:text-[32px] ll:text-[35px]  ">
              800+
            </div>
            <div className="text-[#020202] font-medium text-[13px] ls:text-[16px] lg:text-[18px] xl:text-[20px]">
              Properties
            </div>
          </div>
          <div className="w-1/3 text-center flex flex-col justify-center">
            <div className="text-[#020202] font-bold text-[22px] ls:text-[28px] ls:text[30px] lg:text-[32px] ll:text-[35px]  ">
              45+
            </div>
            <div className="text-[#020202] font-medium text-[13px] ls:text-[16px] lg:text-[18px] xl:text-[20px]">
              Cities
            </div>
          </div>
          <div className="w-1/3 text-center flex flex-col justify-center">
            <div className="text-[#020202] font-bold text-[22px] ls:text-[28px] ls:text[30px] lg:text-[32px] ll:text-[35px]  ">
              80,000+
            </div>
            <div className="text-[#020202] font-medium text-[13px] ls:text-[16px] lg:text-[18px] xl:text-[20px]">
              Happy Customers
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between m-2">
          <div className="w-[33%] h-auto shadow-md rounded-[15px] mr-[6px]">
            <img src="/overview/1.svg" className="object-cover"></img>
          </div>
          <div className="w-[33%] h-auto shadow-md rounded-[15px]">
            <img src="/overview/1.svg" className="object-cover"></img>
          </div>
          <div className="w-[33%] h-auto shadow-md rounded-[15px] ml-[6px]">
            <img src="/overview/1.svg" className="object-cover"></img>
          </div>
        </div>
      </div>
      <div className="w-[20%] text-[#020202] px-2  md:hidden justify-center px-auto flex flex-col bg-[#F4F4F4] shadow-md rounded-[10px] ">
        <div className="items-center flex flex-col justify-center my-1 lg:my-2">
          <div className="text-center font-bold text-[18px] ss:text-[18px] sm:text-[18px] ms:text-[26px] ">
            800+
          </div>
          <div className="text-center font-medium text-[14px] sm:text-[14px] ms:text-[14px] ">
            Properties
          </div>
        </div>
        <div className="text-center flex flex-col justify-center my-1 lg:my-2">
          <div className=" font-bold text-[18px] ss:text-[18px] sm:text-[18px] ms:text-[26px] ">
            45+
          </div>
          <div className=" font-medium text-[14px] sm:text-[14px] ms:text-[14px]">
            Cities
          </div>
        </div>
        <div className="text-center flex flex-col justify-center my-1 lg:my-2">
          <div className=" font-bold text-[18px] ss:text-[18px] sm:text-[18px] ms:text-[26px] ">
            80,000+
          </div>
          <div className=" font-medium text-[14px] sm:text-[14px] ms:text-[14px]">
            Happy Customers
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between mx-2 md:w-[26%] w-[35%]">
        <div className=" h-1/2 my-auto  rounded-[22px]">
          <img src="/overview/6.svg" className="object-cover shadow-lg"></img>
        </div>
        <div className=" h-1/2 my-auto  rounded-[22px]">
          <img src="/overview/3.svg" className="object-cover shadow-lg"></img>
        </div>
      </div>
    </div>
  );
};

export default Overview;
