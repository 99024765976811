import { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
const MapComponent = ({ latitude, longitude, nearby }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const FullscreenControl = () => {
    const map = useMap();

    const toggleFullscreen = () => {
      setFullscreen(!fullscreen);
      map.toggleFullscreen();
    };

    return (
      <button
        className="absolute top-2 right-2 p-2 bg-gray-200 rounded-md shadow-md hover:bg-gray-300"
        onClick={toggleFullscreen}
      >
        <i class="fa-solid fa-expand"></i>
      </button>
    );
  };

  return (
    <div className="flex flex-col w-full h-full md:flex-row justify-center">
      <div className="w-full h-96 md:w-1/2 flex justify-center">
        <MapContainer
          style={{
            height: "400px",
            width: "90%",
            maxWidth: "600px",
          }}
          center={[latitude, longitude]}
          zoom={13}
          scrollWheelZoom={!fullscreen}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={[latitude, longitude]}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          />

          <FullscreenControl />
        </MapContainer>
      </div>
      <div className="flex-grow  md:w-1/2 flex flex-col px-auto md:mx-auto  px-auto">
        <h2 className="text-xl font-bold mb-3">Map Nearby</h2>
        {nearby.length>0 && (<>
          <div>
            <ul className="flex flex-col">
              {nearby.map((item) => (
                <div className="my-[1px] ">
                <h1 className="my-[1px] text-[20px]">{item.type_of_place}</h1>
                <li className="text-[14px]" key={item.id}>
                  {item.name} {"- "}{item.distance_in_kms} Km
                </li>
                </div>
              ))}
            </ul>
          </div>
          <div className="text-[16px] font-semibold mt-6">
          * All distances are measured in straight lines. Actual travel
          distances may vary.
        </div>
        </>
        )}
        
      </div>
    </div>
  );
};

export default MapComponent;
