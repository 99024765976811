import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect, { components } from "react-select";
import { allCountries } from "country-telephone-data";
// import "react-select/dist/react-select.css";

const UserDetail = ({
  formData,
  setFormData,
  buttonActive,
  setButtonActive,
}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, "Must be at least 10 digits")
        .max(15, "Must be at most 15 digits")
        .required("Required"),
    }),
    onSubmit: () => {
      // Not needed since we're updating the state as soon as the form values change
    },
  });

  const handleInputChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    formik.setFieldValue(fieldName, fieldValue);
    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };
  //console.log(allCountries);
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="flex flex-row">
        <img
          src={`https://flagcdn.com/160x120/${props.data.value.toLowerCase()}.png`}
          alt={props.data.label}
          width="20"
          height="16"
          className="mr-2 w-8 h-6"
        />
        {console.log(props.data, "dsa")}
        (+{props.data.dialCode})
      </div>
    </components.SingleValue>
  );

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "180px",
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
      height: "40px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "40px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "300px",
      height: "400px",
    }),
  };
  const indiaOption = {
    data: {
      value: "IN",
      label: "India (+91)",
    },
  };
  //console.log(indiaOption, "india");
  const countryOptions = allCountries.map((country) => ({
    value: country.iso2,
    label: (
      <div className="flex flex-row">
        <img
          src={`https://flagcdn.com/160x120/${country.iso2.toLowerCase()}.png`}
          alt={country.name}
          width="16"
          height="12"
          className="mr-2 w-[20px] h-[16px]"
        />
        {country.name} ({country.dialCode})
      </div>
    ),
    dialCode: country.dialCode,
  }));

  const handleCountryCodeChange = (selectedOption) => {
    setFormData({
      ...formData,
      countryCode: selectedOption.dialCode,
    });
  };

  useEffect(() => {
    const noErrors = Object.keys(formik.errors).length === 0;
    const allFieldsFilled =
      formik.values.name && formik.values.email && formik.values.phone;

    setButtonActive(noErrors && allFieldsFilled);
  }, [formik.errors, formik.values, setButtonActive]);

  return (
    <form className="max-w-md  font-jt">
      <div className="mb-6">
        <label htmlFor="name" className="block text-black font-bold mb-2">
          Name
        </label>
        <input
          id="name"
          name="name"
          type="text"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          className={`appearance-none border ${
            formik.touched.name && formik.errors.name
              ? "border-red-500"
              : "border-black"
          } rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          placeholder="ex. John Doe"
        />
        {formik.touched.name && formik.errors.name && (
          <p className="text-red-500 text-xs font-jt">{formik.errors.name}</p>
        )}
      </div>
      <div className="mb-6">
        <label htmlFor="email" className="block text-black font-bold mb-2">
          Email
        </label>
        <input
          id="email"
          name="email"
          type="email"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className={`appearance-none border ${
            formik.touched.email && formik.errors.email
              ? "border-red-500"
              : "border-black"
          } rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          placeholder="ex. abc@gmail.com"
        />
        {formik.touched.email && formik.errors.email && (
          <p className="text-red-500 text-xs ">{formik.errors.email}</p>
        )}
      </div>
      <div className="mb-6">
        <label htmlFor="phone" className="block text-black font-bold mb-2">
          Phone
        </label>
        <div className="flex">
          <ReactSelect
            // className="w-1/3"
            options={countryOptions}
            onChange={handleCountryCodeChange}
            onBlur={formik.handleBlur}
            placeholder="Country"
            components={{ SingleValue }}
            styles={customStyles}
            maxMenuHeight={400}
          />
          <input
            id="phone"
            name="phone"
            type="text"
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            className={`appearance-none border ${
              formik.touched.phone && formik.errors.phone
                ? "border-red-500"
                : "border-black"
            } rounded-full w-2/3 py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            placeholder="ex. +91 9876543210"
          />
        </div>
        {formik.touched.phone && formik.errors.phone && (
          <p className="text-red-500 text-xs font-jt">{formik.errors.phone}</p>
        )}
      </div>
    </form>
  );
};

export default UserDetail;
