import { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
const MapComponentNew = ({ latitude, longitude}) => {
  const [fullscreen, setFullscreen] = useState(false);

  const FullscreenControl = () => {
    const map = useMap();

    const toggleFullscreen = () => {
      setFullscreen(!fullscreen);
      map.toggleFullscreen();
    };

    return (
      <button
        className="absolute top-2 right-2 p-2 bg-gray-200 rounded-md shadow-md hover:bg-gray-300"
        onClick={toggleFullscreen}
      >
        <i class="fa-solid fa-expand"></i>
      </button>
    );
  };

  return (
    
        <MapContainer
          style={{
            height: "400px",
            width: "90%",
            maxWidth: "600px",
          }}
          center={[latitude, longitude]}
          zoom={13}
          scrollWheelZoom={!fullscreen}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={[latitude, longitude]}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          />

          <FullscreenControl />
        </MapContainer>
     
  );
};

export default MapComponentNew;
