function InfoList({ title, items }) {
    return (
      <div className="w-full h-full bg-[#f5f1f1] mt-4 rounded-md p-4">
        <h2 className="text-lg font-medium mb-2">{title}</h2>
        {items.map((item) => (
          <div key={item.title} className="mb-4">
            <h3 className="text-md font-medium mb-2">{item.title}</h3>
            <p className="text-gray-500">{item.description}</p>
          </div>
        ))}
      </div>
    );
  }

  export default InfoList