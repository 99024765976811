import react, { useState, useEffect } from "react";

function PayNowCard({
  villaCity,
  villaLandmark,
  villaName,
  price,
  thumbnail,
  prepayment,
  taxFlag,
  buttonActive,
  showRazorpay
}) {
  let rentalCost = price;
  let totalCost = Math.floor(1.12 * price);
  let taxCost = totalCost - rentalCost;
  if (taxFlag) {
    rentalCost = Math.floor(price / 1.12);
    totalCost = price;
    taxCost = totalCost - rentalCost;
  }

  const handlePayNowClick = () => {
    //console.log("payment button clicked");
  };

  return (
    <div className="border-black rounded-md border-[1px] py-6 px-10 divide-y-[1px] divide-black ">
      <div className="flex justify-center w-full my-7">
        <div className="w-1/2 h-auto">
          <img
            className="object-scale-down"
            src={thumbnail ? thumbnail.media.file : "/Hosting-bg.png"}
          />
        </div>
        <div className="flex flex-col w-1/2 pl-4 items-center">
          <h1 className="text-[18px] text-center font-semibold my-1">
            {villaName}
          </h1>
          <h1 className="text-[16px] font-medium text-center my-1">
            {villaLandmark}
            {", "}
            {villaCity}
          </h1>
        </div>
      </div>

      <div className="font-hk my-4 pt-7">
        <div className="text-[18px] text-center font-semibold ">
          {" "}
          Price Details
        </div>
        <div className="flex flex-row justify-between my-2">
          <h1 className="text-[16px] font-medium">
            Rental Cost (after discount)
          </h1>
          <h1 className="text-[16px] font-medium">₹{rentalCost}</h1>
        </div>
        <div className="flex flex-row justify-between my-2">
          <h1 className="text-[16px] font-medium">Tax (12%)</h1>
          <h1 className="text-[16px] font-medium">₹{taxCost}</h1>
        </div>
        <div className="flex flex-row justify-between my-2 text-[16px] font-semibold">
          <h1 className="text-[16px] font-semibold">Total Cost</h1>
          <h1>₹{totalCost}</h1>
        </div>
      </div>
      <div className="font-hk my-3 py-4">
        <div className="flex flex-row justify-between my-2 font-semibold text-[16px]">
          <h1 className=" ">Amount to Pay Now ({prepayment}%)</h1>
          <h1 className="">₹{0.01 * prepayment * totalCost}</h1>
        </div>

        <div className="flex flex-row justify-between my-2 text-[16px] ">
          <h1 className="text-[16px] ">Amount to Pay Later(at Check-In)</h1>
          <h1>₹{Math.floor((1 - 0.01 * prepayment) * totalCost)}</h1>
        </div>
      </div>
      <div className="font-hk my-4 py-4 justify-center flex">
        <button
          className={`bg-black text-white text-center py-2 rounded-sm w-[80%]  ${
            !buttonActive ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!buttonActive}
          onClick={() => {
           showRazorpay()
          }}
        >
          Pay ₹{0.01 * prepayment * totalCost} Now
        </button>
      </div>
    </div>
  );
}

export default PayNowCard;
