import react, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserDetail from "../../components/Payment/UserDetail";

import Header4 from "../../NewComponents/Page-4/Header4";
import Footer from "../../NewComponents/Common/Footer/Footer";
import BookingDetails from "../../NewComponents/Page-4/BookingDetails";
import PaymentCard from "../../NewComponents/Page-4/PaymentCard";
import { useContext } from "react";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const PaymentsPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Location = useLocation();
  const bookingData = Location.state;
  //console.log("BOOKING DATA", bookingData);

  const {
    adults,
    children,
    price,
    checkIn,
    checkOut,
    villaName,
    villaAddress,
    cancellationPolicy,
    checkInTime,
    checkOutTime,
    damageDeposit,
    villaCity,
    villaLandmark,
    thumbnail,
    prepayment,
    taxFlag,
    villa_long,
    villa_lat,
    host_id,
    property_ptr_id,
    city_id
  } = bookingData;

  let cancellation = cancellationPolicy.split("_").join(" ").toLowerCase();

  const checkInDate = checkIn?.toDateString();
  const checkOutDate = checkOut?.toDateString();

  const handleEditClick = () => {
    //console.log("EDIT");
    navigate(-1);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [buttonActive, setButtonActive] = useState(false);

  const handlePaymentSuccess = async (response) => {
    try {

      //console.log(response, "response")
      await axios.post(
        `${process.env.REACT_APP_API_AWS}/payment-success/`, { response, villaName, villaAddress, villaLandmark, checkInTime, checkOutTime, villa_long, villa_lat, host_id,property_ptr_id,city_id })
        .then((res) => {

    //  navigate('/thankyou');
          navigate('/Booking-Success');
        })
        .catch((err) => {
         
          //console.log(err);
        });
    } catch (error) {
      //console.log(console.error());
    }
  };

  const loadScript = async () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };
  //console.log(checkIn, checkOut)
  const showRazorpay = async () => {
    const res = await loadScript();
    let name = formData.name
    let email = formData.email
    let phone = formData.phone
    let totalCost = !taxFlag ? Math.floor(1.12 * price) : price
    let price_to_pay = 0.01 * prepayment * totalCost
    let price_to_pay_later = totalCost - price_to_pay
    let checkin = checkIn.toISOString().split('T')[0]
    let checkout = checkOut.toISOString().split('T')[0]
    const data = await axios.post(`${process.env.REACT_APP_API_AWS}/pay/`, { name, email, phone, price_to_pay, checkin, checkout, adults, children, prepayment, totalCost, price_to_pay_later })
    //console.log(data, "first response data")

    var options = {
      key_id: "rzp_test_vFJ5bcRhRNsZXu",
      key_secret: "2vjlFmC125DhBnZgQdb8AdB7",
      amount: data.data.payment.amount,
      currency: "INR",
      name: "A2Z Villas",
      description: "Test teansaction",
      image: "./logopay.png",
      order_id: data.data.payment.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: "A2Z Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <>
      <Header4 />
      <div className="text-[48px] font-jt font-bold text-center mb-[60px]">
        Review & Pay
      </div>
      <div className="flex flex-col lg:flex-row px-2 lg:px-7">
        <div className="w-full lg:w-[50%]">
          <BookingDetails
            adults={adults}
            children={children}
            price={price}
            checkIn={checkIn}
            checkOut={checkOut}
            villaName={villaName}
            villaAddress={villaAddress}
            cancellationPolicy={cancellationPolicy}
            checkInTime={checkInTime}
            checkOutTime={checkOutTime}
            damageDeposit={damageDeposit}
            villaCity={villaCity}
            villaLandmark={villaLandmark}
            thumbnail={thumbnail}
            prepayment={prepayment}
            taxFlag={taxFlag}
            villa_long={villa_long}
            villa_lat={villa_lat}
            host_id={host_id}
            handleEditClick={handleEditClick}
            formData={formData}
            setFormData={setFormData}
            cancellation={cancellation}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
            handlePaymentSuccess={handlePaymentSuccess}
            loadScript={loadScript}
            showRazorpay={showRazorpay}
          />

        </div>
        <div className="w-full lg:w-[45%]  lg:mx-10">
          <PaymentCard
            villaName={villaName}
            price={price}
            villaCity={villaCity}
            villaLandmark={villaLandmark}
            thumbnail={thumbnail}
            prepayment={prepayment}
            taxFlag={taxFlag}
            buttonActive={buttonActive}
            showRazorpay={showRazorpay}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentsPage;
