import CommentInputBox from "./Input-review";
import RatingBarChart from "./progressbar-chart";
import ProgressBarReview from "./progressbar-chart";
import ReviewCard from "./Review-card";

const ReviewMain = () => {
  const data = [
    { rating: "Outstanding : 9+", numReviews: 100 },
    { rating: "Excellent : 7-9", numReviews: 75 },
    { rating: "Good : 5-7", numReviews: 50 },
    { rating: "Passable : Below 5", numReviews: 25 },
  ];

  const dummyData = {
    name: "Abhishek Bachchan",
    stayDuration: "Stayed 3 nights in deluxe suite",
    rating: 10,
    date: "12 March, 2021",
    comment:
      "This villa is very nice although the location is bad but don't judge their villa by location",
    photos: [
      {
        src: "https://plus.unsplash.com/premium_photo-1671117129890-ad9c09af995a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80",
      },
      {
        src: "https://plus.unsplash.com/premium_photo-1671129471248-82d1ba9dab31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1107&q=80",
      },
      {
        src: "https://images.unsplash.com/photo-1678063464239-16b19ce80eac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1161&q=80",
      },
      {
        src: "https://images.unsplash.com/photo-1677610757680-f95bd51e64e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1026&q=80",
      },
      // ...
    ],
  };
  return (
    <>
    {false?(<div className="flex justify-center font-sans font-Glory my-5">
      <div className="font-hk w-[90vw] p-6 bg-[#f1f1f1]  border-[1px] rounded-lg shadow-lg">
        <div className="flex flex-row ">
          <div>
            <div className="flex flex-row h-fit">
              <div className=" text-[22px]  bg-black text-white ml-4 px-2 rounded h-fit w-fit flex-shrink-0">
                9.2
              </div>
              <div className="my-auto text-[24px] ml-5 text-red-500">
                Excellent
              </div>
            </div>
            <div className="ml-4 mt-2 text-[18px]"> 400 Reviews</div>
          </div>

          <div className="flex lg:justify-end w-full ">
            <RatingBarChart data={data} />
          </div>
        </div>
        <div className="ml-4 mt-2 text-[20px] font-semibold">Guest Reviews</div>
        <ReviewCard {...dummyData} />
        <ReviewCard {...dummyData} />
        {/* <CommentInputBox/> */}
      </div>
    </div>):(
    <div className="flex justify-center font-sans font-Glory my-5">
    <div className="font-hk w-[90vw] p-6 bg-[#f1f1f1]  border-[1px] rounded-lg shadow-lg">
      <h1 className=" text-[25px] font-semibold">No Reviews Available</h1>
    </div>
    </div>
    )}
    </>
  );
};

export default ReviewMain;
