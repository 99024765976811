import React, { useState } from "react";
// import "./about.css"
const Abouts = () => {
  return (
    <>
      <aside className="w-fit bg-slate-200 rounded-lg shadow-xl mx-auto border-2 border-violet-600">
      <div className="border-b-2 border-violet-600">
      <h3 className="text-xl font-medium mt-2 mb-2">Popular Amenities</h3>
      <ul className="">
        <li className="ml-8">
          <input type="checkbox" /> WiFi connectivity
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Swimming Pool
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Breakfast
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Pet Friendly
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Restaurant
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Air Conditioning
        </li>
      </ul>
      </div>
      <div className="border-b-2 border-violet-600">
      <h3 className="text-xl font-medium mt-8 mb-4">Ratings</h3>
      <ul className="">
        <li className="ml-8 ">
          <input type="checkbox" /> 10  Marvellous
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 10 - 9 Outstanding
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 9 - 8  Excellent
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 8 - 7 Very Good
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 7 - 6 Good
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 6 - 5 Less Good
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 5 - 4 Okay
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 4 - 3 Okay
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 3 - 2 Bad
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 2 - 1 Poor
        </li>
      </ul>
      </div>
      
      <div className="border-b-2 border-violet-600">
      <h3 className="text-xl font-medium mt-8 mb-4">Property Type</h3>
      <ul className="">
        <li className="ml-8">
          <input type="checkbox" /> Villa
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Resorts
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Hotels
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Apartments
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Holiday Homes
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Guest Houses
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Homestays
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Bed and Breakfast
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Luxury Tents
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Farm Stays
        </li>
      </ul>
      </div>
      
      <div className="border-b-2 border-violet-600">
      <h3 className="text-xl font-medium mt-8 mb-4">Property Ratings</h3>
      <ul className="">
        <li className="ml-8">
          <input type="checkbox" /> 5 Star
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 4 Star
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 3 Star
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 2 Star
        </li>
        <li className="ml-8">
          <input type="checkbox" /> 1 Star
        </li>
      </ul>
      </div>
      
      <div className="border-b-2 border-violet-600">
      <h3 className="text-xl font-medium mt-8 mb-4">Cancellation Policy</h3>
      <ul className="">
        <li className="ml-8">
          <input type="checkbox" /> Cancellation Fee
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Partial Cancellation Fee
        </li>
        <li className="ml-8">
          <input type="checkbox" /> No Cancellation Fee
        </li>
      </ul>
      </div>

      <div className="border-b-2 border-violet-600">
      <h3 className="text-xl font-medium mt-8 mb-4">Meals included</h3>
      <ul className="">
        <li className="ml-8">
          <input type="checkbox" /> Breakfast
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Lunch
        </li>
        <li className="ml-8">
          <input type="checkbox" /> Dinner
        </li>
      </ul>
      </div>
      
    </aside>
    </>
  );
};

export default Abouts;
