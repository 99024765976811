import UserDetails from "./UserDetails";
import react, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";


const BookingDetails = ({
  adults,
    children,
    price,
    checkIn,
    checkOut,
    villaName,
    villaAddress,
    cancellationPolicy,
    checkInTime,
    checkOutTime,
    damageDeposit,
    villaCity,
    villaLandmark,
    thumbnail,
    prepayment,
    taxFlag,
    villa_long,
    villa_lat,
    host_id,
    handleEditClick,
    formData,
    setFormData,
    cancellation,
    checkInDate,
    checkOutDate,
    buttonActive,
    setButtonActive,
    handlePaymentSuccess,
    loadScript,
    showRazorpay,
}) => {
  
  

  
  return (
    <div>
      <div className="flex flex-col font-jt px-2 lg:px-12">
        <div className="">
          <div className="flex justify-between mb-6 text-center">
            <div className="text-[30px] font-semibold ">Booking Details</div>
            <div
              className="text-[20px] items-center lg:text-[28px] font-medium hover:cursor-pointer hover:text-blue-700 underline"
              onClick={handleEditClick}
            >
              {" "}
              Edit
            </div>
          </div>
          <div className="flex justify-between text-[17px] lg:text-[21px] font-medium my-[20px]">
            <div className="">Check-In (After 1 PM)</div>
            <div>{checkInDate}</div>
          </div>
          <div className="flex justify-between text-[17px] lg:text-[21px] font-medium my-[20px]">
            <div className="">Check-Out (Before 11 AM)</div>
            <div>{checkOutDate}</div>
          </div>
          <div className="flex justify-between text-[17px] lg:text-[21px] font-medium my-[20px]">
            <div className="">No. of Adults</div>
            <div>{adults}</div>
          </div>
          <div className="flex justify-between text-[17px] lg:text-[21px] font-medium my-[20px]">
            <div className="">No. of Children (6-12 yrs)</div>
            <div>{children}</div>
          </div>
        </div>
        <div className="">
          <div className="text-[30px] font-semibold my-6">Guest Details</div>
          <UserDetails
            formData={formData}
            setFormData={setFormData}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
        </div>
        <div className="my-2 pt-5 ">
          <div className="text-[30px] font-semibold ">Cancellation Policy</div>
          <div className="text-[21px] font-medium my-5">
            The booking can be cancelled for{" "}
            <span className="">{cancellation} </span>with no extra charge.{" "}
            <br />
            {/* To know more about our cancellation policy,{" "}
              <span className="text-blue-700 hover:underline hover:cursor-pointer">
                Click Here
              </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
