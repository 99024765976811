import NearbyCard from "./Cards"
import MapComponentNew from "./PropertyLocation"

const NearbyPlaces = ({ villaData }) => {
  return (
    <div className="font-jt items-center w-[95vw] mx-auto">
      <div className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8my-4 lg:m-8 font-jt">
        Checkout Popular Places Nearby
      </div>
      <div className="flex flex-col lg:flex-row justify-center mt-10 items-center">


        <MapComponentNew longitude={villaData.geo_long} latitude={villaData.geo_lat} />


        <div className="grid lg:ml-4 grid-cols-2 grid-rows-2 mt-4 lg:mt-0 gap-2 lg:gap-4">
          <div className="col-span-1 my-auto row-span-1">
            <NearbyCard />
          </div>
          <div className="col-span-1 my-auto row-span-1">
            <NearbyCard />
          </div>
          <div className="col-span-1 my-auto row-span-1">
            <NearbyCard />
          </div>
          <div className="col-span-1 my-auto row-span-1">
            <NearbyCard />
          </div>
        </div>


      </div>
    </div>
  )
}

export default NearbyPlaces