import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Modal from "./Modal";
import "./style.css"
const images = [
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
  {
    src: "/DefaultMenu.jpg",
  },
];

const FoodOptions = ({
  villaData
}) => {
  const points = [villaData.self_cook?villaData.self_cook:0, villaData.chef_charged, 1, 1];
  //   const { points, images } = foodData;
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="font-jt my-8 ">
      <div className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8my-4 lg:m-8 font-jt">
        Food Options
      </div>
      <div className="flex flex-row py-4   lg:mx-auto text-[16px] text-black ">
        <div className="w-[95%] lg:w-[65%] flex flex-col items-center justify-center ">
          <ul className="lg:text-[24px] text-[19px] w-full lg:w-auto  lg:font-semibold list-outside ">
            {points[0]!=0 && (
              <li className="list-disc ml-8 lg:ml-0  my-5">The guest can cook by Himself</li>
            )}
            {points[1] != -1 && (
              <li className="list-disc ml-8 lg:ml-0 my-5">
                The guest can hire a cook from the property at Rs {points[1]}{" "}
                per day. Groceries charges are extra
              </li>
            )}
            {points[2] && (
              <li className="list-disc ml-8 lg:ml-0 my-5">
                The guest can Select <span className="text-green-400">Veg</span>
                {villaData.only_veg_meal ? (<>/<span className="text-red-600">NonVeg</span></>):(<></>)} Food package
              </li>
            )}
            {points[3] && (
              <li className="list-disc ml-8 lg:ml-0 my-5">
                The guest can order from nearby restaurants or can Zomato,
                Swiggy.{" "}
              </li>
            )}
            <div className="flex flex-row justify-center lg:justify-start space-x-4">
              <button
                className="px-4 p-2 shadow-lg border-black border-2 rounded-full"
                onClick={() => setOpen(true)}
              >
                See Food Menu
              </button>
              <button
                className="px-6 p-2 shadow-lg lg:bg-white bg-black lg:text-black text-white border-black border-2 rounded-full"
                onClick={() => setIsOpen(true)}
              >
                View More
              </button>
            </div>
          </ul>
          {/* <div className="">
            <div className="flex flex-row my-2">
              <img src="/veg.jpg" className="scale-[0.85]" />
              <div className="ml-4">
                <h1 className="text-[14px] font-semibold">VEG</h1>
                {meal_package_veg_adult_price ? (
                  <h2 className="">
                    Adult : Rs.{meal_package_veg_adult_price} per head
                  </h2>
                ) : (
                  <h2>Adult : N/A</h2>
                )}
                {meal_package_veg_adult_price ? (
                  <h2 className="">
                    Children : Rs.{meal_package_veg_child_price} per head
                  </h2>
                ) : (
                  <h2>Children : N/A</h2>
                )}
              </div>
            </div>
            <div className="flex flex-row">
              <img src="/non-veg.jpg" className="scale-[0.9] ml-2" />
              <div className="ml-4">
                <h1 className="text-[14px] font-semibold">NON-VEG</h1>
                {meal_package_veg_adult_price ? (
                  <h2 className="">
                    Adult : Rs.{meal_package_veg_adult_price} per head
                  </h2>
                ) : (
                  <h2>Adult : N/A</h2>
                )}
                {meal_package_veg_adult_price ? (
                  <h2 className="">
                    Children : Rs.{meal_package_veg_child_price} per head
                  </h2>
                ) : (
                  <h2>Children : N/A</h2>
                )}
              </div>
            </div>
          </div> */}
        </div>
        <div className="hidden lg:w-[35%] lg:flex flex-col items-center">
          <img
            className="object-cover w-[50%]"
            src={images[0].src}
            alt="image"
          />
        </div>
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={images}
          plugins={[Thumbnails]}
          
        />
      </div>
      <Modal villaData={villaData} isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </div>
  );
};

export default FoodOptions;
