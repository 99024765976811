import React, { useEffect, useState } from "react";
import LandMarkDropdown from "./LandmarkDropdown";
import InputBoxSubHeader from "./inputbox-page2";
import InputBoxSubHeaderMobile from "./inputbox-page2-mobile";
import SortPopup from "./Sort Popup";
import SidebarMobile from "../Sidebar/Sidebar-mobile";


function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};

const SubHeader = ({ setSubHeaderQuery, villalist, landmarklist, amenities,query,setQuery }) => {
  const [selectedLandmark, setSelectedLandmark] = useState("Landmark 1");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVilla, setSearchVilla] = useState("");
  const [villa, setVilla] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLandmarkOpen, setLandmarkOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAmenityOpen, setIsAmenityOpen] = useState(false);
  const handleOptionChange = (option) => {
    //console.log("Selected option: ", option);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const toggleAmenityPopup = () => {
    setIsAmenityOpen(!isAmenityOpen)}

  const scrollDirection = useScrollDirection();



  const handleLandmarkChange = (event) => {
    setSelectedLandmark(event.target.value);
  };

  const handleSortOrderChange = (value) => {
    if (value == sortOrder) setSortOrder("");
    else setSortOrder(value);
  };

  const handleSearchVillaChange = (event) => {
    setSearchVilla(event.target.value);
  };

  const handleVillaSearch = () => {
    const input = document.getElementById("villa-input");
    setVilla(input.value);
  };
  const [location, setLocation] = useState(null);
  const [cities, setCities] = useState();

  const handleSelection = async () => {
    const transformedVillaList = villalist?.map((villa) => ({
      id: villa.property_ptr_id,
      name: villa.listing_name?villa.listing_name:villa.property_name,
      rating: villa.a2z_score || 0,
    }));

    setCities(transformedVillaList);
  };

  useEffect(() => {
    handleSelection();
  }, [villalist]);

  useEffect(() => {
    let query = "";
    if (sortOrder != "") query = `&sortorder=${sortOrder}`;
    location === null
      ? (query += `&property_id=`)
      : (query += `&property_id=${location}`);

    let landmarkQuery = "";

    if (selectedOptions.length > 0) {
      landmarkQuery = "&landmark=";
      selectedOptions.forEach((item, i) => {
        landmarkQuery += `${item}+`;
      });
      landmarkQuery = landmarkQuery.slice(0, -1);
    }
    query += landmarkQuery;

    setSubHeaderQuery(query);
  }, [selectedLandmark, sortOrder, villa, location, selectedOptions]);

  return (
    <div className={` font-jt hidden lg:block sticky  z-[2000] ${ scrollDirection === "down" ? "-top-28 lg:-top-0" : "top-0"}   transition-all duration-700`}>
    {isAmenityOpen && <SidebarMobile
                amenities={amenities}
                query={query}
                setQuery={setQuery}
                isAmenityOpen={isAmenityOpen}
                setIsAmenityOpen={setIsAmenityOpen}
              />}
     <SortPopup isOpen={isOpen} setIsOpen={setIsOpen} onOptionChange={handleOptionChange} handleSortOrderChange={handleSortOrderChange}/>
        <div className="bg-[#1d1d1e] lg:bg-white space-x-2 lg:space-x-0 py-2 lg:py-2 px-1 lg:px-4 flex flex-row justify-between  items-center">
      {/* Dropdown for Landmark Names */}
      <div className="relative w-[33%] lg:w-[30%] mx-0 lg:mx-3">
        <LandMarkDropdown
          landmarklist={landmarklist}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </div>

      {/* Sort Options */}

      <div className="w-[33%] bg-white rounded-full lg:border-[2px] lg:hidden flex ">
        <button 
          onClick={togglePopup}
        className="w-full px-3 p-2 flex flex-row text-black bg-none  font-semibold  items-center justify-between">
          <svg
            width="22"
            height="22"
            viewBox="0 0 25 25"
            fill="none"
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 3.75C3.05965 3.75 2.5 4.30965 2.5 5C2.5 5.69035 3.05965 6.25 3.75 6.25H17.5C18.1904 6.25 18.75 5.69035 18.75 5C18.75 4.30965 18.1904 3.75 17.5 3.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 8.75C3.05965 8.75 2.5 9.30965 2.5 10C2.5 10.6904 3.05965 11.25 3.75 11.25H12.5C13.1904 11.25 13.75 10.6904 13.75 10C13.75 9.30965 13.1904 8.75 12.5 8.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 13.75C3.05965 13.75 2.5 14.3096 2.5 15C2.5 15.6904 3.05965 16.25 3.75 16.25H8.75C9.44035 16.25 10 15.6904 10 15C10 14.3096 9.44035 13.75 8.75 13.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M18.75 10C18.75 9.30965 18.1904 8.75 17.5 8.75C16.8096 8.75 16.25 9.30964 16.25 10V16.9823L14.6339 15.3661C14.1457 14.878 13.3542 14.878 12.8661 15.3661C12.378 15.8542 12.378 16.6458 12.8661 17.1339L16.6161 20.8839C16.8505 21.1182 17.1685 21.25 17.5 21.25C17.8315 21.25 18.1495 21.1182 18.3839 20.8839L22.1339 17.1339C22.622 16.6458 22.622 15.8542 22.1339 15.3661C21.6457 14.878 20.8542 14.878 20.3661 15.3661L18.75 16.9823V10Z"
              fill="#020202"
            />
          </svg>
          Sort
        </button>
      </div>
      
      <div className="w-[33%] bg-white rounded-full lg:border-[2px] lg:hidden flex ">
        <button 
        onClick={toggleAmenityPopup}
        className="w-full px-3 p-2 flex flex-row text-black bg-none font-semibold rounded-full  items-center justify-between">
          <svg
            width="15"
            height="15"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M8.5777 0H0.422365C0.0476348 0 -0.141453 0.454676 0.124065 0.720193L3.375 3.97162V7.59375C3.375 7.73141 3.44216 7.86041 3.55495 7.93937L4.9612 8.9234C5.23863 9.1176 5.625 8.92078 5.625 8.57778V3.97162L8.87602 0.720193C9.14099 0.455203 8.95321 0 8.5777 0Z"
              fill="black"
            />
          </svg>
          Filter
        </button>
      </div>

      <div className="hidden lg:flex flex-row w-[40%] justify-center items-center mx-4">
        <div
          className={`px-2 ${
            sortOrder === "asc" ? "underline  " : ""
          } w-1/3 justify-center items-center font-semibold py-2 rounded-2xl hover:cursor-pointer flex flex-row  `}
          onClick={() => handleSortOrderChange("asc")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 25 25"
            fill="none"
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 3.75C3.05965 3.75 2.5 4.30965 2.5 5C2.5 5.69035 3.05965 6.25 3.75 6.25H17.5C18.1904 6.25 18.75 5.69035 18.75 5C18.75 4.30965 18.1904 3.75 17.5 3.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 8.75C3.05965 8.75 2.5 9.30965 2.5 10C2.5 10.6904 3.05965 11.25 3.75 11.25H12.5C13.1904 11.25 13.75 10.6904 13.75 10C13.75 9.30965 13.1904 8.75 12.5 8.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 13.75C3.05965 13.75 2.5 14.3096 2.5 15C2.5 15.6904 3.05965 16.25 3.75 16.25H8.75C9.44035 16.25 10 15.6904 10 15C10 14.3096 9.44035 13.75 8.75 13.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M18.75 10C18.75 9.30965 18.1904 8.75 17.5 8.75C16.8096 8.75 16.25 9.30964 16.25 10V16.9823L14.6339 15.3661C14.1457 14.878 13.3542 14.878 12.8661 15.3661C12.378 15.8542 12.378 16.6458 12.8661 17.1339L16.6161 20.8839C16.8505 21.1182 17.1685 21.25 17.5 21.25C17.8315 21.25 18.1495 21.1182 18.3839 20.8839L22.1339 17.1339C22.622 16.6458 22.622 15.8542 22.1339 15.3661C21.6457 14.878 20.8542 14.878 20.3661 15.3661L18.75 16.9823V10Z"
              fill="#020202"
            />
          </svg>
          Price (Low to High)
        </div>
        <div
          className={`px-2 ${
            sortOrder === "desc" ? "underline " : ""
          } w-1/3 justify-center items-center font-semibold py-2 rounded-2xl hover:cursor-pointer flex flex-row`}
          onClick={() => handleSortOrderChange("desc")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 25 25"
            fill="none"
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 3.75C3.05965 3.75 2.5 4.30965 2.5 5C2.5 5.69035 3.05965 6.25 3.75 6.25H17.5C18.1904 6.25 18.75 5.69035 18.75 5C18.75 4.30965 18.1904 3.75 17.5 3.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 8.75C3.05965 8.75 2.5 9.30965 2.5 10C2.5 10.6904 3.05965 11.25 3.75 11.25H12.5C13.1904 11.25 13.75 10.6904 13.75 10C13.75 9.30965 13.1904 8.75 12.5 8.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 13.75C3.05965 13.75 2.5 14.3096 2.5 15C2.5 15.6904 3.05965 16.25 3.75 16.25H8.75C9.44035 16.25 10 15.6904 10 15C10 14.3096 9.44035 13.75 8.75 13.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M18.75 10C18.75 9.30965 18.1904 8.75 17.5 8.75C16.8096 8.75 16.25 9.30964 16.25 10V16.9823L14.6339 15.3661C14.1457 14.878 13.3542 14.878 12.8661 15.3661C12.378 15.8542 12.378 16.6458 12.8661 17.1339L16.6161 20.8839C16.8505 21.1182 17.1685 21.25 17.5 21.25C17.8315 21.25 18.1495 21.1182 18.3839 20.8839L22.1339 17.1339C22.622 16.6458 22.622 15.8542 22.1339 15.3661C21.6457 14.878 20.8542 14.878 20.3661 15.3661L18.75 16.9823V10Z"
              fill="#020202"
            />
          </svg>
          Price (High to Low)
        </div>
        <div
          className={`px-2 ${
            sortOrder === "rating" ? "underline " : ""
          } w-1/3 justify-center items-center font-semibold py-2 rounded-2xl hover:cursor-pointer flex flex-row`}
          onClick={() => handleSortOrderChange("rating")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 25 25"
            fill="none"
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 3.75C3.05965 3.75 2.5 4.30965 2.5 5C2.5 5.69035 3.05965 6.25 3.75 6.25H17.5C18.1904 6.25 18.75 5.69035 18.75 5C18.75 4.30965 18.1904 3.75 17.5 3.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 8.75C3.05965 8.75 2.5 9.30965 2.5 10C2.5 10.6904 3.05965 11.25 3.75 11.25H12.5C13.1904 11.25 13.75 10.6904 13.75 10C13.75 9.30965 13.1904 8.75 12.5 8.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M3.75 13.75C3.05965 13.75 2.5 14.3096 2.5 15C2.5 15.6904 3.05965 16.25 3.75 16.25H8.75C9.44035 16.25 10 15.6904 10 15C10 14.3096 9.44035 13.75 8.75 13.75H3.75Z"
              fill="#020202"
            />
            <path
              d="M18.75 10C18.75 9.30965 18.1904 8.75 17.5 8.75C16.8096 8.75 16.25 9.30964 16.25 10V16.9823L14.6339 15.3661C14.1457 14.878 13.3542 14.878 12.8661 15.3661C12.378 15.8542 12.378 16.6458 12.8661 17.1339L16.6161 20.8839C16.8505 21.1182 17.1685 21.25 17.5 21.25C17.8315 21.25 18.1495 21.1182 18.3839 20.8839L22.1339 17.1339C22.622 16.6458 22.622 15.8542 22.1339 15.3661C21.6457 14.878 20.8542 14.878 20.3661 15.3661L18.75 16.9823V10Z"
              fill="#020202"
            />
          </svg>
          Customer Ratings
        </div>
      </div>

      {cities && (
        <InputBoxSubHeader
          inputbox={location}
          setInputBox={setLocation}
          cities={cities}
        />
      )}  
    </div>

    
    </div>
   

    
  );
};

export default SubHeader;
