import React from 'react';

const TermsConditions = () => (
    <div className="w-full h-full bg-white p-8 font-jt">
        <h1 className="text-3xl font-bold mb-8">Terms & Conditions</h1>
        These terms and conditions are detailed here for the guests and the hosts to determine the terms and conditions for using the A2Z Villaz platform for renting rooms, apartments, houses, or any other place for a short period of time. By using the platform – the website, and or mobile applications on iOS or Android platform you agree to abide by the terms and conditions and are bound by these terms and conditions. Any dispute that may arise would be governed by the Indian jurisdiction and any arbitration that may be required to be carried out would be carried out in India by arbitrator chosen mutually.

        The A2Z Villaz platform is owned and operated by A2Z Villaz LLP, a limited liability partnership incorporated in India and governed by Indian laws. Its registered office is310, AKD Tower, Sector 14, Gurugram, Haryana 122001, India, and its operating office is at 60/2 Inder Bawa Marg, Jakhan, Dehradun, Uttarakhand, India.

        <h1 className='text-2xl font-sem-bold my-5'>1. Definitions</h1>

        A2Z Villaz provides an online platform that acts as a marketplace for hosts to rent out their apartments, houses, or rooms to guests on a short or long term basis (collectively, known as "Services"), this service is accessible

        at www.A2zvillaz.com or affiliate or partner sites (collectively, the "Site") and as applications for mobile, tablet and other smart devices and application program interfaces (collectively, the "Application").

        “A2Z Villaz ” means A2Z Villaz LLP and may be represented as we, us, our, company, organization

        “Place” means accommodation in rooms, apartments, houses, boats, huts, tents, trees or any such place.

        “Host” means user who is renting out the place on the platform “Guest” means user who is renting the place on the platform

        “Listing” means information made available on the platform for renting of place “Booking” means the time period for which the host and guest agree to rent the place

        “Content” means any content that is on the platform, whether user generated or generated by A2Z Villaz

        “User” means any member who is registered on the platform by using his email id or phone number

        “Tax” means any form of tax or taxes levied by local government, state government of central government of India. These need to be remitted to the government. “Fees” means fee that is collected by platform for providing the service

        <h1 className='text-2xl font-sem-bold my-5'>2. Registering for A2Z Villaz</h1>

        Anyone over the age of 18 years can register at A2Z Villaz , by registering you agree
        that you are more than 18 years of age. You can register by providing all the
        information, or you can register using a social network such as Facebook. A2Z Villaz
        may gather information from social network on your profile based on your privacy
        settings. You agree to provide complete access of your social networking profile as
        accessible through the social network API to A2Z Villaz . While registering you agree
        to provide the correct information at the time of registration, and not enter any fake
        information on the website. A2Z Villaz reserves the right to verify your mobile phone
        number and or your email address using electronic methods. In case a guest or a
        host determines that the information about your profile that you shared is insufficient
        to conduct business, we may ask you to provide additional information on your
        identification.

        There is no civil, criminal or administrative action, written claim, investigation or other proceedings against the registered user and / or which has been taken or made or is pending or, threatened, from any regulatory authority or third party against you which gives

        rise or, is likely to give rise to any liability against you under any Applicable Laws. You certify that you have not received any written complaint, notice, or correspondence from any regulatory authority or third party, alleging any actual or potential breach of Applicable Laws.

        YOU AGREE TO GIVE US THE RIGHTS TO OBTAIN INFORMATION ON YOUR PROFILE USING THE INFORMATION PROVIDED BY YOU.

        A2Z Villaz RESERVES THE RIGHT TO DEREGISTER OR SUSPEND YOUR MEMBERSHIP FOR SAFETY AND SECURITY OF ITS MEMBERS IN CASE OF ANOMALY IN THE INFORMATION PROVIDED BY YOU WHILE REGISTERING YOURSELF OR LISTING YOUR PROPERTY.

        <h1 className='text-2xl font-sem-bold my-5'> 3. Purpose of the platform</h1>

        A2Z Villaz platform including its website, mobile site, applications on iOS and Android platform and application programming interface are meant for the sole purpose of acting as a marketplace for rentals. The platform has been designed for hosts and guests to meet online and agree to do business together. The platform is not intended for any other purpose. Any registered user activity that goes beyond using the platform for activities other than intended may lead to suspension of the registered account.

        Unless explicitly specified, A2Z Villaz does not own, rent, re-rent, manage or control the properties, the properties are owned and operated by the hosts and A2Z Villaz has no control over them. While listing the property or making a booking the hosts and guests understand the risks that may be involved. A2Z Villaz would not be liable for compliance to any local, municipal, state and central laws that may be specified for the purpose of renting out your premises for short and long terms. Similarly A2Z Villaz is not liable for taxes that may be due for renting out premises for short and long term, it is the ownership of the host to comply with all the tax laws. A2Z Villaz would not be liable for any tax related issues that may arise for using our platform.

        <h1 className='text-2xl font-sem-bold my-5'>4. Listings</h1>

        The host shall provide the Information for the place for inclusion on the Platforms, called listing and it shall include information relating to the place (including pictures, photos and descriptions), its amenities and services and the rooms available for reservation, details of the rates (including all applicable taxes, levies, surcharges and fees) and availability, cancellation and cancellation policies and other policies and restrictions which shall be provided in the form of House Rules. The Information such provided shall not contain any contact information including telephone or fax numbers or email (including Skype) address or social media website/app/platform (including Twitter and Facebook), with direct references to the place or its websites, apps, platform, tools or other devices, or to websites, apps, platform, tools or other devices of third parties. A2Z Villaz reserves the right to edit or exclude any information upon becoming aware that it is incorrect or incomplete or in violation of the terms and conditions of this Agreement.
        The host agrees to make sure that the information being provided on the site is
        current and upto date at all times. This includes the availability and pricing
        information of the listing. If there is any renovation or alteration going on in the
        listing, the host should make the listing unavailable for the duration of this activity.
        The information provided by the listing will remain the property of the host, A2Z Villaz
        would have a non-exclusive right on the content which it may use to translate,
        convert or use for promotion of the listing and A2Z Villaz . Any alteration/editing done
        by A2Z Villaz will become the property of A2Z Villaz . In cases where A2Z Villaz is
        involved in gathering and publishing information we may use the term A2Z Villaz
        verified, which may not constitute endorsement of the place.

        The rates posted by the host shall take care of all applicable taxes, including but not limited to municipal, local, luxury, city, service tax, sales tax, GST, entertainment or any other tax levied by concerned authorities. A2Z Villaz is not liable for any taxes to be collected and deposited on behalf of the host.

        The host is liable to check for all applicable laws to ensure that he is in compliance and has a license (if required) to provide home stay and or accommodation services in his geography. This is usually based on local zoning and trade laws, hosts are recommended to check for these before deciding to opt for hosting.

        If you are a Host, you understand and agree that A2Z Villaz does not act as an insurer or as your contracting agent. If a Guest requests a booking of your Accommodation and stays at your Accommodation, any agreement you enter into with such Guest is between you and the Guest and A2Z Villaz is not a party to it.

        A2Z Villaz recommends all hosts get adequate insurance coverage to cover for any

        incident that may happen at the property, A2Z Villaz will not be liable for any claims arising from an incident.

        <h1 className='text-2xl font-sem-bold my-5'>5. Bookings & Fees</h1>

        "Booking Fees" means the amounts that are due and payable by a Guest in exchange for that Guest's stay in a place.

        "Guest Fees" means the fee that A2Z Villaz charges a Guest for the use of the Services, which is calculated as a percentage of the applicable Accommodation Fees. The Guest Fees will be displayed to the Guest when the Guest is asked whether to send a booking request to a Host. A2Z Villaz at its sole discretion can increase or reduce this rate.

        "Host Fees" means the fee that A2Z Villaz charges a Host for the use of the Services, which is calculated as a percentage of the applicable Accommodation Fees. The Host Fees will be displayed to the Host when the Host is asked whether to confirm or reject a booking request from a prospective Guest. A2Z Villaz at its sole discretion can increase or reduce this rate.

        "Payment Method" means a payment method that you have added to your A2Z Villaz , such as a credit card, debit card or wallet.
        "Service Fees" means collectively the Guest Fees and the Host Fees.
        "Total Fees" means collectively the Accommodation Fees and the Guest Fees plus any Taxes.

        Unless otherwise specified the host has the rights to approve, or reject a booking within 24 hours of the booking request being made. If the host has chosen to make the property available for the instant booking, no host intervention would be required to confirm the booking.

        A2Z Villaz may charge the guests with a token amount, or pre-authorize or charge fully the Total Fees from the guest at the time of making a booking, this will be fully refunded to the guest if the host does not approve the booking. In case the booking is approved by host, and the guest requests a refund then the refund would be based on the cancellation terms of the place. See the Refund Policy for more details on the same.

        You as a Guest agree to pay the Total Fees for any booking requested, and in most cases confirmed, in connection with your A2Z Villaz Account.

        A2Z Villaz country of operation is India and hence all the payments may be charged in Indian currency, if you are paying using a foreign currency card, your payment may be charged in Indian rupee equivalent using the forex rate of that day. A2Z Villaz is not liable for any bank charges or fees that your credit card or payment method provider may levy on you.

        Once a booking has been confirmed, the host cannot alter, change or cancel the booking terms. Only under extenuating circumstances or force majeure can a host cancel a booking after having accepted the booking. The host may familiarize itself with the Host Cancellation Policy of A2Z Villaz .

        The host will make sure that the booking made by the guest at the price listed by it, is honored at the time of check-in.

        The host will not charge any money for accommodation from the guest at the time of check-in, unless there is a change in the booking terms.

        The host will not charge extra for any of the amenities provided by the place and advertised by the host. Any services not advertised can be charged by the host at a price amenable to both the parties. This would not constitute any endorsement from A2Z Villaz or make A2Z Villaz liable for any risk arising out of this transaction.

        The host will clearly communicate the checkin times and procedure for checking into the place. Any lapses by host that prevents guests from checking in at the appointed time may lead to cancellation of fees or reductions on the booking amount payable by A2Z Villaz .

        The host will ensure that all the amenities listed as available on the A2Z Villaz platform are made available to the guests without prejudice and the guest has unencumbered access to the amenities.

        If a Host cancelled a confirmed booking and you, as a Guest, have not received an email or other communication from A2Z Villaz , please contact A2Z Villaz .

        In case the host refuses to honor a booking or If the customer decides to cancel or

        opt for another accommodation because of lapses on the part of host, the host would be liable to pay for extra fees including transportation that may be required to find another similar suitable accommodation for the guest.

        Hosts will receive their accommodation fee in full after the completion of 48 hours from the time of checkin of the guest. In case the end of 48 hours falls on a holiday or weekend, the same shall be transmitted on the next business day.

        <h1 className='text-2xl font-sem-bold my-5'>6. Cleaning & Other Guest Services</h1>

        Unless otherwise provided as a written waiver to a host, cleaning services would be delivered by A2Z Villaz , for which a fee may be collected by A2Z Villaz . Cleaning services would include cleaning the room, common areas (if being used by the guest) and private bathroom being used by the guest. This does not include any change of linen or toiletries in the bathroom.

        The host has no right over this fees and any service that maybe required to be delivered under cleaning services would be the sole responsibility of A2Z Villaz . A2Z Villaz may deliver other guest services that may be required to be delivered for the comfort of the guests. These services may include laundry, food, travel and any other service that the guest may want or need. By agreeing to list on A2Z Villaz , hosts agree to provide A2Z Villaz a non exclusive right to provide services to the guests that are registered on the A2Z Villaz platform.

        <h1 className='text-2xl font-sem-bold my-5'>7. Taxes</h1>

        Because of the nascent nature of the online businesses in India, there is a lot of grey areas in the collection and remittance of taxes. Notwithstanding that, the hosts agree to comply with the applicable taxes of the country of operation.

        The hosts agree to levy and collect the luxury, occupancy, city, service, sales, service tax or any other tax that the local, municipal, state or central government may levy on short term. The host also agrees to remit the tax such collected to the appropriate tax authorities. In case of violations, A2Z Villaz may withhold the tax that is required to be collected by the host and remit it to the concerned authorities. A2Z Villaz may withhold payment to hosts for TDS payment if it is so required by law. In case your income is classified as rental income then you may be liable for TDS to be collected by A2Z Villaz .

        All fees being charged by A2Z Villaz includes service tax that it is liable to charge from the users for the services it provides.

        <h1 className='text-2xl font-sem-bold my-5'>8. Guest Conduct</h1>

        By agreeing to book on the A2Z Villaz platform the guest agrees to all the terms and conditions laid down on the site and additionally agrees to comply with the house rules that the host may have published on the platform. Any violation of the terms and conditions and house rules would lead to suspension of membership of A2Z Villaz and of booking. The guest will not be eligible for any refund in case of eviction from property for violation of terms and conditions laid down on the website.

        As a Guest, you are responsible for leaving the Accommodation in the condition it was in when you arrived. You acknowledge and agree that, as a Guest, you are responsible for your own acts and omissions and are also responsible for the acts and omissions of any individuals whom you invite to, or otherwise provide access to, the Accommodation. In the event that a Host claims otherwise, you agree to pay the cost of replacing the damaged items with equivalent items.

        If a Host makes a Damage Claim for a confirmed booking of an Accommodation, you as a Guest will be notified of any Damage Claim you will be given reasonable time to respond. If you are not able to satisfactorily prove otherwise, A2Z Villaz may deduct the amount required to compensate the host from the security deposit that you may have made or charge your credit or debit card recorded with us for the aforesaid claim amount. In case we are unable to charge your card, we can pursue other methods of recovering as permissible.

        We expect both guests and hosts to act with good faith while making and servicing a booking and expect bot the parties to cooperate in case of a dispute that may arise in case of a booking.

        We don’t expect you to overstay at the hosts house without prior consent, however if you choose to do so, the hosts or A2Z Villaz or both may use all available avenues to evict you from the premises and charge twice the accommodation fees from you for every night of overstay at the said place.

        Security Deposits, if required by a Host, may be applied to any fees due from a Guest overstaying at a Listing without the Host's consent.

        <h1 className='text-2xl font-sem-bold my-5'>9. Member Conduct</h1>

        All members of A2Z Villaz agree to the following code of conduct for A2Z Villaz to provide a the services through its platform

        •	While hosting or staying at a place, you agree to abide by all the local laws, covenants and regulations.
        •	Hosts are solely responsible for checking the applicable laws and its compliance
        •	Guests follow all the local laws while staying at the place
        •	Guests do not violate any terms and condition laid down on the site, including house rules
        •	Members are expected not to create multiple profiles on the site
        •	Members are expected not to use fake information to create profiles on the website
        •	Members are expected to provide reviews that are accurate and reflect their opinion of the stay
        •	Members are expected not to create fake reviews
        •	Members agree to not copy content from the site for use elsewhere on the site or other sites
        •	Members agree not to use the place for purpose other than intended and in violation of house rules
        •	Members agree not to do illegal activities at the rented accommodation
        •	Use messaging platform for purpose other than the purpose of booking accommodation
        •	Members agree to use civil language while communicating with other members A2Z Villaz may access, preserve and disclose any of your information if we are required to do so by law, or if we believe in good faith that it is reasonably necessary to ensure good behavior on the site or to respond to any legal claims that may arise from bookings.

        <h1 className='text-2xl font-sem-bold my-5'>10. Privacy</h1>

        Please refer our Privacy Policy for more details.

        <h1 className='text-2xl font-sem-bold my-5'>11. Right to Refuse Service</h1>

        A2Z Villaz and its registered host reserves the right to refuse service to any person or entity without assigning any reason. Your continued use of the site would mean you agree to this clause.

        <h1 className='text-2xl font-sem-bold my-5'>12. Non Transferability of Agreement</h1>

        You may not assign or transfer this agreement, without A2Z Villaz prior written consent. Any attempt by you to assign or transfer this agreement, without such consent, will be void. A2Z Villaz may assign or transfer these Terms, at its sole discretion, without restriction. Any transfer of this agreement to third party, successors and inheritors needs to be approved by A2Z Villaz for it to take effect.

        THERE IS NO AUTOMATIC TRANSFER OF LISTING IN CASE OF SALE / PURCHASE OF THE SAID PROPERTY TO ANOTHER PARTY.

        A2Z Villaz reserves the rights to suspend or cancel any such account and listing where the ownership has been transferred without its knowledge and requisite approval.

        <h1 className='text-2xl font-sem-bold my-5'>13. Unauthorized Access</h1>

        The content and the listing and membership details hosted on A2Z Villaz platform is the exclusive property of A2Z Villaz . Any attempts to gain access to data and content using program, robots, scripts, bots, hacking, will constitute theft and will lead to legal action by A2Z Villaz to claim back the data and content.

        <h1 className='text-2xl font-sem-bold my-5'>14. Intellectual Property</h1>

        The Design and code of the site, application, application programming interface, and
        the platform in general is the property of A2Z Villaz . All rights in all Intellectual
        Property and confidential business information owned or otherwise required for the
        business of A2Z Villaz as currently conducted or contemplated to be conducted are
        vested in or validly granted to the Company and is not subject to any limit as to time
        or any other limitation, right of termination (including on any change in the underlying
        ownership or Control of the Company or restriction).
        Any attempts to copy, remove, alter, deface or unauthorized access to the design
        and content of the site would be in violation of all the content hosted on the site is
        the intellectual property of A2Z Villaz and its partners, will be in violation of the cyber

        laws of the country of operation and would invite legal action from A2Z Villaz .

        <h1 className='text-2xl font-sem-bold my-5'>15. Brand & Trademark</h1>

        Registering on the website confers you the rights to display the brand of A2Z Villaz in your property. However this right does not provide you with any license to alter the brand markings and or trademark, and any violation of the brand guidelines would lead to suspension of the listing or registration from the site.

        <h1 className='text-2xl font-sem-bold my-5'>16. Copyright</h1>

        All the content hosted on the website is the property of A2Z Villaz and its members and partners and protected by the copyright law of India. In case you feel the some of the content hosted on the website may belong to you or may have been illegally obtained by our members, please feel free to send us a mail

        at contact@A2Z Villaz .com. In case a member is found to be in repeated violation of the copyright law, the membership maybe suspended by A2Z Villaz .

        <h1 className='text-2xl font-sem-bold my-5'>17. Limitation of Liability</h1>

        YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF

        THE SITE, APPLICATION, SERVICES AND COLLECTIVE CONTENT, YOUR LISTING OR BOOKING OF ANY ACCOMMODATIONS VIA THE SITE, APPLICATION AND SERVICES, YOUR PARTICIPATION IN THE REFERRAL PROGRAM, AND ANY CONTACT YOU HAVE WITH OTHER USERS OF A2Z Villaz WHETHER IN PERSON OR ONLINE REMAINS WITH YOU. NEITHER A2Z Villaz NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT OR THE REFERRAL PROGRAM WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, FROM THE USE OF OR INABILITY TO USE THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT, FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE SITE, APPLICATION, OR SERVICES OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION, SERVICES, OR YOUR PARTICIPATION IN THE REFERRAL PROGRAM OR FROM YOUR LISTING OR BOOKING OF ANY ACCOMMODATION VIA THE SITE, APPLICATION AND SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT A2Z Villaz HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.

        EXCEPT FOR OUR OBLIGATIONS TO PAY AMOUNTS TO APPLICABLE HOSTS PURSUANT TO THESE TERMS OR AN APPROVED PAYMENT REQUEST UNDER THE A2Z Villaz HOST GUARANTEE, IN NO EVENT WILL A2Z Villaz ’S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND YOUR USE OF THE SITE, APPLICATION AND SERVICES INCLUDING, BUT NOT LIMITED

        TO, FROM YOUR LISTING OR BOOKING OF ANY ACCOMMODATION VIA THE SITE, APPLICATION AND SERVICES, OR FROM THE USE OF OR INABILITY TO USE THE SITE, APPLICATION, SERVICES, OR COLLECTIVE CONTENT OR YOUR PARTICIPATION IN THE REFERRAL PROGRAM AND IN CONNECTION WITH ANY ACCOMMODATION OR INTERACTIONS WITH ANY OTHER MEMBERS, EXCEED THE AMOUNTS YOU HAVE PAID OR OWE FOR BOOKING VIA THE PLATFORM. The Company and/or the Promoters shall have no liability in respect of any matter, act, omission or circumstances which have arisen, solely, as a result of the

        Company and/or the Promoters (as the case may be) having acted in accordance with any written instructions of the Investor.

        <h1 className='text-2xl font-sem-bold my-5'>18. Disclaimers</h1>
        YOU AGREE TO USE THE SITE ON AS IS WHERE BASIS ON THE STRENGTH OF THE INFORMATION PROVIDED BY A2Z Villaz USERS AND MEMBERS. A2Z Villaz

        DOES NOT CLAIM ANY AUTHENTICITY OR VERACITY OF THE CONTENT THAT THE USERS POSTS ON THE WEBSITE AND IS IN NO WAY RESPONSIBLE FOR THE AUTHENTICITY AND VERACITY OF THE CONTENT.

        A2Z Villaz , UNLESS OTHERWISE SPECIFIED DOES NOT PERFORM ANY BACKGROUND CHECKS ON HOSTS AND GUESTS AND WE DO NOT GUARANTEE THAT THE MEMBERS ARE WHO THEY SAY THEY ARE, THE USERS ARE EXPECTED TO USE THEIR JUSGEMENT TO GET THE REQUISITE INFORMATION AND CROSS CHECK TO MAKE SURE THAT THE PERSON IS WHO HE CLAIMS HE IS.

        THE MESSAGING PLATFORM PROVIDED ON THE PLATFORM IS FOR THE SOLE USE OF COMMUNICATIION BETWEEN MEMBERS REGARDING RENTAL OF THE PLACE. WE DO NOT VOUCH FOR AUTHENTICITY OF ANY COMMUNICATION BETWEEN TWO MEMBERS. WE ARE NOT LIABLE FOR ANY MISCOMMUNICATION BETWEEN THE MEMBERS. WE ADVISE THE MEMBERS TO USE THEIR JUDGEMENT ON THE COMMUNICATION AND MAKE DECISIONS ACCORDINGLY. A2Z Villaz IS NOT LIABLE FOR ANY INTERPRETATION AND OTHER SUCH ERRORS ON BEHALF OF ANY PARTY.

        ANY OFF PLATFORM TRANSACTION CONDUCTED BY ANY MEMBER AUTOMATICALLY DISQUALIFIES THEM FROM OUR HOST PROTECTION PROGRAMS AND ABSOLVES A2Z Villaz FROM ANY LIABILITY THAT MAY ARISE OUT OF THE OFF PLATFORM TRANSACTIONS.

        <h1 className='text-2xl font-sem-bold my-5'>19. Indemnification</h1>

        You agree to release, defend, indemnify, and hold A2Z Villaz and its affiliates and subsidiaries, and their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (a) your access to or use of the Site, Application, Services, or Collective Content or your violation of these Terms; (b) your Member Content; (c) your (i) interaction with any Member, (ii) booking of a place, or (iii) creation of a Listing; (d) the use, condition or rental of a place by you, including but not limited to any injuries, losses, or damages (compensatory, direct, incidental, consequential or otherwise) of any kind arising in connection with or as a result of a rental, booking or use of an place.

        <h1 className='text-2xl font-sem-bold my-5'>20. Country of Jurisdiction</h1>

        This Agreement shall be governed by and construed in accordance with the laws of India without reference to its conflict of laws principles.

        <h1 className='text-2xl font-sem-bold my-5'>21. Dispute Resolution & Arbitration</h1>

        If any dispute arises in relation to or in connection with this Agreement including in respect of the validity, interpretation, implementation or alleged breach of any provision of this Agreement or regarding a question, and including the questions as to whether the termination of this Agreement by one party hereto has been legitimate arising out of this Agreement (a “Dispute”) between the Parties (“Disputing Parties”), then any Disputing Party may submit the claim or dispute to be finally

        settled by arbitration to be conducted in Singapore, in accordance with the

        Singapore International Arbitration Centre (“SIAC”) rules as in force at the time of any such arbitration, and as may be amended by the rest of this Clause 10. Each Disputing Party shall appoint 1 (one) arbitrator each, and the 2 (two) arbitrators so appointed would appoint the 3 rd (third) arbitrator (the “Arbitral Board”). All arbitration proceedings shall be conducted in the

        English language. The Arbitral Board shall decide any such dispute or claim strictly in accordance with the governing law specified in Clause 21.

        <h1 className='text-2xl font-sem-bold my-5'>22. Notices</h1>

        Each notice, demand or other communication given or made under this Agreement shall be in writing and delivered or sent to the relevant Party at its address set out in contact page (or such other address as the addressee has by 5 (five) Business Days’; prior written notice specified to the other Parties). Any notice, demand or other communication given or made by letter between countries shall be delivered by courier. Any notice, demand or other communication so addressed to the relevant Party shall be deemed to have been delivered (a) if delivered in person or by courier, when proof of delivery is obtained by the delivering Party; (b) if sent by post within the same country, on the 10 th (tenth) day following posting and if sent by post to another country, on the 20 th (twentieth) day following posting. Pursuant to the dispatch of the notice as above, the Party sending the notice shall also email the contents of the entire notice to the receiving party at the contact email address mention in the contact page.

        <h1 className='text-2xl font-sem-bold my-5'>23. Termination of Agreement</h1>

        This agreement can be terminated by A2Z Villaz without assigning any notice to the host or guest. However The termination of this Agreement shall in no event terminate or prejudice (a) any right or obligation arising out of or accruing under this Agreement attributable to events or circumstances occurring prior to such termination (including any claim for Losses for a Warranty Breach); and (b) any provision which by its nature is intended to survive termination, including the provisions of [Clauses 6 (Representations and Warranties), 7 (Indemnity), 8 (Confidentiality), 9 (Non-Compete), 10 (Governing Law and Dispute Resolution) and 13 (Miscellaneous), respectively].

        <h1 className='text-2xl font-sem-bold my-5'>24. Waiver, Remedies and Consents</h1>

        No waiver of any provision of this Agreement shall be effective unless set forth in a written instrument signed by the Party waiving such provision. No failure or delay by a Party in exercising any right, power or remedy under this Agreement shall operate as a waiver thereof, nor shall any single or partial exercise of the same preclude any further exercise thereof or the exercise of any other right, power or remedy. Without limiting the foregoing, no waiver by a Party of any breach by any other Party of any provision hereof shall be deemed to be a waiver of any prior, concurrent or subsequent breach of that or any other provision hereof, and waiver by a Party in exercising any rights available to it under this Agreement shall not preclude such Party from exercising such rights at a later stage (unless expressly or impliedly prohibited by this Agreement). The rights and remedies provided in this Agreement are cumulative and not exclusive of any rights or remedies provided by Applicable Law. Any waiver, and any consent by any of the Parties under any provision of this Agreement, must be in writing and may be given subject to any conditions thought fit by the person giving that waiver or consent. Any waiver or consent shall be effective only in the instance and for the purpose for which it is given.

        <h1 className='text-2xl font-sem-bold my-5'>25. Entire Agreement</h1>

        This Agreement (together with the other Transaction Documents and any other documents referred to herein or therein) constitutes the whole agreement between the Parties relating to the subject matter hereof and supersedes any prior agreements or understandings relating to such subject matter including but not limited to listing and booking.

    </div>
);

export default TermsConditions;
