import React from "react";

const HostProperty = () => {
  return (
    <div className="flex flex-row items-center w-full px-0 lg:px-2 lg:w-[90vw] mx-auto font-jt ">
      <div className="w-[54%] flex flex-col justify-center px-2 ms:p-4 md:p-5 lg:p-6 ll:p-8">
        <h1 className=" font-semibold text-[22px] ss:text-[25px] sm:text-[30px] ms:text-[35px] ls:text-[39px] lg:text-[44px] ll:text-[48px]">
          Host your property
        </h1>
        <h1 className=" font-semibold  text-[22px] ss:text-[25px] sm:text-[30px] ms:text-[35px] ls:text-[39px] lg:text-[44px] ll:text-[48px]">
          with us.
        </h1>
        <p className="text-black  my-3 sm:my-7 ms:my-9 ls:my-11 lg:my-15 ll:my-21 text-[15px] ss:text-[15px] ms:text-[18px] ls:text-[20px] lg:text-[22px] ll:text-[25px]">
          Maximize your rental income with our hassle-free hosting solutions.
          Join now and start earning more from your property.
        </p>

        <button className="bg-black  text-white p-1 ls:p-2 ll:px-4 ll:py-3 rounded-full">
          <a href="http://host.a2zroomz.com/login/">

          Try Hosting
          </a>
        </button>
      </div>
      <div className="relative w-[46%]">
        <img
          src="./oceanhouse.png"
          alt="Property"
          className="rounded-[38px] shadow-2xl"
        />
      </div>
    </div>
  );
};

export default HostProperty;
