import react, { useState, useEffect } from "react";

function PaymentCard({
  villaCity,
  villaLandmark,
  villaName,
  price,
  thumbnail,
  prepayment,
  taxFlag,
  buttonActive,
  showRazorpay,
}) {
    let rentalCost = price;
    let totalCost = Math.floor(1.12 * price);
    let taxCost = totalCost - rentalCost;
  // let rentalCost = 466071;
  // let totalCost = 522000;
  // let taxCost = 55929;
  // villaName = "KC1 Villa";
  // villaLandmark = "Near Club Cabana, Arpora";
  // villaCity = "Goa";
  // prepayment = 30;

  if (taxFlag) {
    rentalCost = Math.floor(price / 1.12);
    totalCost = price;
    taxCost = totalCost - rentalCost;
  }

  const handlePayNowClick = () => {
    //console.log("payment button clicked");
  };

  return (
    <div className="font-jt">
        <div className="border-[#B4B4B4] rounded-3xl bg-[#EAEAEA] border-[3px] py-2 lg:py-6 px-2 lg:px-8 font-jt">
      <div className="flex  flex-col lg:flex-row justify-center w-full mt-1 lg:mt-6 mb-9">
        <div className="w-[100%] lg:w-[50%] h-auto ">
          <img
            className="object-scale-down rounded-3xl lg:rounded-xl "
            src={thumbnail ? thumbnail.media.file : "/Hosting-bg.png"}
          />
        </div>
        <div className="flex flex-col w-[100%] lg:w-[50%] pl-2 lg:pl-8 justify-center ">
          <h1 className="text-[32px] text-start font-bold my-1 lg:my-3">{villaName}</h1>

          <div className="flex flex-row my-1 lg:my-3">
            <svg
              width="16"
              height="26"
              viewBox="0 0 20 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0003 0C4.50859 0 0.0585938 4.475 0.0585938 10C0.0585938 19.55 10.0003 32 10.0003 32C10.0003 32 19.9419 19.55 19.9419 10C19.9419 4.475 15.4919 0 10.0003 0ZM10.0003 14.85C7.40026 14.85 5.30026 12.7333 5.30026 10.125C5.30026 7.51667 7.40859 5.39167 10.0003 5.39167C12.6003 5.39167 14.7003 7.50833 14.7003 10.125C14.7003 12.7333 12.6003 14.85 10.0003 14.85Z"
                fill="#020202"
              />
            </svg>
            <h1 className="text-[18px] font-medium text-start  ml-1">
              {villaLandmark}
              {", "}
              {villaCity}
            </h1>
          </div>
        </div>
      </div>

      <div className="my-4 lg:my-8">
        <div className="text-[30px] text-center font-semibold mb-6 ">
          {" "}
          Price Details
        </div>
        <div className="flex flex-row justify-between my-4 text-[22px]">
          <h1 className=" font-medium">Rental Cost (after discount)</h1>
          <h1 className="font-medium">₹{rentalCost}</h1>
        </div>
        <div className="flex flex-row justify-between my-4 text-[22px] font-medium">
          <h1>Tax (12%)</h1>
          <h1>₹{taxCost}</h1>
        </div>
      </div>
      <div className="my-5 px-4 py-2 bg-white border-[#B4B4B4] border-[1px] rounded-xl font-bold">
        <div className="flex flex-row justify-between my-4 text-[25px] ">
          <h1 className=" ">Total Cost</h1>
          <h1>₹{totalCost}</h1>
        </div>
        <div className="flex flex-row justify-between my-4 font-semibold text-[21px] text-[#5F5E5E]">
          <h1 className=" ">Amount to Pay Now ({prepayment}%)</h1>
          <h1 className="">₹{0.01 * prepayment * totalCost}</h1>
        </div>

        <div className="flex flex-row justify-between my-4 text-[21px] text-[#5F5E5E] ">
          <h1 className="text-[20px] ">Amount to Pay Later(at Check-In)</h1>
          <h1>₹{Math.floor((1 - 0.01 * prepayment) * totalCost)}</h1>
        </div>
      </div>
      
    </div>
    <div className="mt-4 py-8 justify-center flex">
        <button
          className={`bg-black text-white shadow-2xl font-bold text-[22px] text-center py-[12px] rounded-full w-full  ${
            !buttonActive ? "opacity-50 shadow-2xl cursor-not-allowed" : ""
          }`}
          disabled={!buttonActive}
          onClick={() => {
            showRazorpay();
          }}
        >
          Proceed to Pay ₹{0.01 * prepayment * totalCost}
        </button>
      </div>
    </div>
    
  );
}

export default PaymentCard;
