import { useContext } from "react";
import { login } from "../../authContext/apiCalls";
import { AuthContext } from "../../authContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const Login = ({ handlePage }) => {
  const { dispatch, user } = useContext(AuthContext);
  const history = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      login(values, dispatch);
      //console.log(user);
      handlePage(-1);
    },
  });

  const handleRegister = async () => {
    try {
      history.push("/register");
    } catch (err) {}
  };

  return (
    <>
      <div className="font-hk flex flex-col justify-center items-center h-screen bg-black bg-opacity-70">
        <div className="relative flex flex-col items-center bg-white p-8 rounded-lg shadow-lg justify-around w-[95vw] md:w-[44vw] md:h-[60vh]">
          <button
            className="absolute z-[110] top-3 right-3 text-[30px]"
            onClick={() => {
              handlePage(-1);
            }}
          >
            <i className="fa-regular fa-circle-xmark"></i>
          </button>
          <h1 className="text-3xl font-bold mb-4 text-gray-800">Welcome</h1>
          <p className="text-lg font-semibold mb-6 text-gray-700">
            Login with Email
          </p>
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="border-2 border-gray-300 p-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-red-500 text-sm">{formik.errors.email}</p>
              )}
            </div>
            <div className="mb-6">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className="border-2 border-gray-300 p-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              {formik.touched.password && formik.errors.password && (
                <p className="text-red-500 text-sm">{formik.errors.password}</p>
              )}
            </div>
            <button
              type="submit"
              className="bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 w-full mb-4"
            >
              Log in
            </button>
          </form>
          <Link
            to="/forgotPassword"
            className="text-sm text-blue-500 hover:text-blue-700 mb-4"
          >
            Forgot Password?
          </Link>
          <button
            onClick={() => handlePage(1)}
            className="text-sm text-blue-500 hover:text-blue-700"
          >
            Sign up
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
