import React from "react";
import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import "./style.css";

function DateCalender({ startDate, setStartDate, endDate, setEndDate }) {
  return (
    <>
      {/* <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        minimumDate={new Date()}
        minimumLength={1}
        format="dd MMM yyyy"
        locale={enGB}
      >
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <div className="date-range flex flex-col md:flex-row  mx-auto justify-center">
            <input
              className={
                "placeholder-zinc-600  w-[80vw] mt-5 mx-5 md:mx-0 md:mt-0 h-[7vh]  outline-0  md:bg-transparent lg:font-normal lg:placeholder-black text-[18px]  pr-1  md:border-0 md:pr-0  md:text-center placeholder:text-center md:h-full md:w-[110px] " +
                "input" +
                (focus === START_DATE ? " -focused" : "")
              }
              {...startDateInputProps}
              placeholder="Check IN"
            />
            <input
              className={
                "placeholder-zinc-600 w-[80vw] mt-5 mx-5 md:mx-0 md:mt-0 md:mb-0 h-[7vh] outline-0 md:bg-transparent lg:font-normal lg:placeholder-black text-[18px]  pl-1 md:pl-0 md:text-center placeholder:text-center md:h-full md:w-[110px] " +
                "input" +
                (focus === END_DATE ? " -focused" : "")
              }
              {...endDateInputProps}
              placeholder="Check OUT"
            />
          </div>
        )}
      </DateRangePicker> */}
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={(date) => {
          const startDateIST = date
            ? utcToZonedTime(date, "Asia/Kolkata")
            : null;
          setStartDate(startDateIST);
        }}
        onEndDateChange={(date) => {
          const endDateIST = date ? utcToZonedTime(date, "Asia/Kolkata") : null;
          setEndDate(endDateIST);
        }}
        minimumDate={new Date()}
        minimumLength={1}
        format="dd MMM yyyy"
        locale={enGB}
      >
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <div className="date-range flex flex-col md:flex-row  mx-auto justify-center">
            <input
              className={
                "placeholder-zinc-600  w-[80vw] mt-5 mx-5 md:mx-0 md:mt-0 h-[7vh]  outline-0  md:bg-transparent lg:font-normal lg:placeholder-black text-[18px]  pr-1  md:border-0 md:pr-0  md:text-center placeholder:text-center md:h-full md:w-[110px] " +
                "input" +
                (focus === START_DATE ? " -focused" : "")
              }
              {...startDateInputProps}
              placeholder="Check IN"
            />
            <input
              className={
                "placeholder-zinc-600 w-[80vw] mt-5 mx-5 md:mx-0 md:mt-0 md:mb-0 h-[7vh] outline-0 md:bg-transparent lg:font-normal lg:placeholder-black text-[18px]  pl-1 md:pl-0 md:text-center placeholder:text-center md:h-full md:w-[110px] " +
                "input" +
                (focus === END_DATE ? " -focused" : "")
              }
              {...endDateInputProps}
              placeholder="Check OUT"
            />
          </div>
        )}
      </DateRangePicker>
    </>
  );
}

export default DateCalender;
