import React, { useState } from "react";
import "./style.css";
import { enGB } from "date-fns/locale";
import { getDay } from 'date-fns'
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useMediaQuery } from "react-responsive";
import SignUpPopUp from "../Signup/Signup";
import LoginPopup from "../Login/Login";
import { useContext } from "react";
import { logOut } from "../../../authContext/apiCalls";
import { AuthContext } from "../../../authContext/AuthContext";
import { FiChevronDown } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { HiOutlinePhotograph } from "react-icons/hi";
import { BsGear } from "react-icons/bs";
import { FaBookmark, FaStar, FaWallet } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const citySuggestions = [
  {
    id: 2,
    name: "Goa",
  },
  {
    id: 29,
    name: "Kodaikanal",
  },
  {
    id: 21,
    name: "Dhanaulti",
  },
  {
    id: 22,
    name: "Mount Abu",
  },
  {
    id: 3,
    name: "Lonavala",
  },
];

const cityOptions = [
  {
    id: 2,
    name: "Goa",
  },
  {
    id: 29,
    name: "Kodaikanal",
  },
  {
    id: 21,
    name: "Dhanaulti",
  },
  {
    id: 22,
    name: "Mount Abu",
  },
  {
    id: 3,
    name: "Lonavala",
  },
  {
    id: 4,
    name: "Manali",
  },
  {
    id: 6,
    name: "Khopoli",
  },
  {
    id: 7,
    name: "Mahabaleshwar",
  },
  {
    id: 8,
    name: "Panchgani",
  },
  {
    id: 9,
    name: "Alibag",
  },
  {
    id: 10,
    name: "Pune",
  },
  {
    id: 11,
    name: "Udaipur",
  },
  {
    id: 12,
    name: "Indore",
  },
  {
    id: 13,
    name: "Madikeri",
  },
  {
    id: 14,
    name: "Nashik",
  },
  {
    id: 30,
    name: "Ooty",
  },
  {
    id: 15,
    name: "Neemuch",
  },
  {
    id: 16,
    name: "Palgarh",
  },
  {
    id: 17,
    name: "Uttan",
  },
  {
    id: 24,
    name: "Kasauli",
  },
  {
    id: 20,
    name: "Kamshet",
  },
  {
    id: 25,
    name: "Jaipur",
  },
  {
    id: 31,
    name: "Bengaluru",
  },
  {
    id: 18,
    name: "Mussoorie",
  },
  {
    id: 26,
    name: "Dehradun",
  },
  {
    id: 27,
    name: "Shimla",
  },
  {
    id: 19,
    name: "Raipur",
  },
  {
    id: 23,
    name: "Kanatal",
  },
  {
    id: 5,
    name: "Karjat",
  },
  {
    id: 28,
    name: "Lansdowne",
  },
  {
    id: 1,
    name: "Igatpuri",
  },
];

const Header = ({
  location,
  setLocation,
  adults,
  setAdults,
  children,
  setChildren,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSearchClick,
  check
}) => {
  ////
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { user, dispatch } = useContext(AuthContext);
  const handleLogout = () => {
    logOut(dispatch);
    setIsDropdownOpen(false);
    navigate.push("/");
  };
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
/////////
const [clicked, setClicked] = useState(false);
  const [suggestion, setSuggestions] = useState([]);
  const [select, setSelect] = useState("");
  const isLargeScreen = useMediaQuery({ minWidth: 768 }); // Set the breakpoint as you need

  const dateFormat = isLargeScreen ? "dd MMM yyyy" : "dd MMM";
  const handleChange = (e) => {
    let value = e.target.value;
    let matches = [];

    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = cityOptions.filter((item) => regex.test(item.name));
    }
    setSuggestions(matches);
    setLocation(value);
  };

  const selectValue = (item) => {
    setSelect(item);
    setSuggestions("");
    setLocation(item);
  };
  const [Loginpop,SetLoginPop]=useState(false)
  const [Registerpop,SetRegisterpop]=useState(false)

  const modifiers = {

    highlight: date => getDay(date) === 6 // Highlights Tuesdays
  }
  const modifiersClassNames = {
    highlight: '-highlight'
  }
  const customLocale = {
    ...enGB,
    options: {
      ...enGB.options,
      weekStartsOn: 0, // Set the week to start on Saturday
    }
  };
  return (
    <>
    {Loginpop &&(<LoginPopup SetLoginPop={SetLoginPop} Loginpop={Loginpop} Registerpop={Registerpop} SetRegisterpop={SetRegisterpop}/>)}
      {Registerpop &&(<SignUpPopUp Registerpop={Registerpop} SetRegisterpop={SetRegisterpop} SetLoginPop={SetLoginPop} Loginpop={Loginpop}/>)}
    <div className="bg-white lg:py-2 font-jt hidden lg:flex flex-col z-20000">
      <div className="flex flex-row justify-between items-center mt-2 mb-4 ll:hidden">
        <div className="h-[20px] flex items-center justify-center">
          <img src="/logo-black.jpeg" className="h-[50px]"></img>
        </div>
        <div className="flex items-center justify-center">
          <button onClick={()=>{SetRegisterpop(!Registerpop)}} className="bg-white font-bold  text-black border-black border-2 w-[100px] h-[35px] rounded-full shadow-md">
            Register
          </button>
          <button onClick={()=>{SetLoginPop(!Loginpop)}} className="bg-black font-bold   ml-3 text-white  w-[100px] h-[35px] rounded-full  shadow-md">
            Sign in
          </button>
        </div>
      </div>
      <div className=" flex flex-row ">
        <div className="h-[16px] hidden ll:flex justify-center w-[5%]">
        <Link to="/"> <img src="/logo-black.jpeg" className="h-[60px]"></img></Link>
        </div>
        <div className="w-full ll:w-[77%] font-jt flex flex-row  bg-[#F4F4F4] rounded-full relative">
          <div className="w-[26%] lg:w-[26%] bg-white rounded-full flex items-center justify-center my-[4px] lg:my-[6px] mx-[4px] ll:mx-[8px] md:py-[4px] lg:py-3 relative">
            <svg
              width="21"
              height="28"
              viewBox="0 0 30 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-[0px] md:left-[2px] ls:left-[5px] lg:left-[8px]  ll:left-3 scale-[50%] ms:scale-[60%] md:scale-[70%] ls:scale-[80%] lg:scale-[90%] ll:scale-100"
            >
              <path
                d="M14.9999 0C6.7624 0 0.0874023 6.7125 0.0874023 15C0.0874023 29.325 14.9999 48 14.9999 48C14.9999 48 29.9124 29.325 29.9124 15C29.9124 6.7125 23.2374 0 14.9999 0ZM14.9999 22.275C11.0999 22.275 7.9499 19.1 7.9499 15.1875C7.9499 11.275 11.1124 8.0875 14.9999 8.0875C18.8999 8.0875 22.0499 11.2625 22.0499 15.1875C22.0499 19.1 18.8999 22.275 14.9999 22.275Z"
                fill="black"
              />
            </svg>

            <input
              className="outline-none text-center text-[13px] ss:text-[14px] ms:text-[16px] lg:text-[18px] font-bold bg-transparent placeholder-black  "
              type="text"
              placeholder={`Your Destination`}
              value={location}
              onChange={handleChange}
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                setClicked(!clicked);
              }}
            />

            {suggestion?.length > 0 ? (
              <div className="flex flex-row z-[3000] absolute top-[56px] lg:px-2 w-[85%]">
                <div className="bg-white border-[#E8E8E8] border-[1px] shadow-lg cursor-pointer rounded-lg  w-full">
                  {suggestion.slice(0, 8).map((item) => {
                    return (
                      <div
                        className="tracking-wider suggestions text-center lg:px-[20px] mt-2 py-[1px] lg:py-2 text-[12px] lg:text-[18px] border-[#E8E8E8] border-b-[1px] font-semibold "
                        key={item.id}
                        onClick={() => {selectValue(item.name);
                          setClicked(false);
                        }}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : clicked && (
              <div className="flex flex-row z-[3000] absolute top-[56px] lg:px-2 w-[85%] ">
                <div className="bg-white border-[#E8E8E8] border-[1px] shadow-lg cursor-pointer rounded-lg  w-full ">
                  {citySuggestions.map((item) => {
                    return (
                      <div
                        className=" tracking-wider suggestions text-center lg:px-[20px] mt-2 py-[1px] lg:py-2 text-[12px] lg:text-[18px] border-[#E8E8E8] border-b-[1px] font-semibold "
                        key={item.id}
                        onClick={() => {
                          selectValue(item.name);
                          setClicked(false);
                        }}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <svg
              width="17"
              height="11"
              viewBox="0 0 23 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute scale-[60%] ms:scale-[70%] md:scale-[80%] ls:scale-[90%] cursor-pointer  ll:scale-[100%] right-0 ms:right-[3px] md:right-[5px] lg:right-[8px] ll:right-3 "
              onClick={() => {
                
                setClicked(!clicked);
              }}
            >
              <path
                d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="w-[40%] lg:w-[48%] items-center">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              minimumDate={new Date()}
              minimumLength={1}
              format={dateFormat}
              modifiersClassNames={modifiersClassNames}
              modifiers={modifiers}
              locale={customLocale}
              firstDayOfWeek={7}
            >
              {({ startDateInputProps, endDateInputProps, focus }) => (
                <div className="date-range grid grid-cols-2 gap-[2px] lg:gap-2 w-full">
                  <div className="flex  flex-row items-center rounded-full bg-white justify-center lg:my-[6px] my-[4px] lg:py-3 relative">
                    <svg
                      className="hidden lg:block absolute left-[1px] ms:left-[6px] md:left-[8px] ls:left-[10px] lg:left-[12px] ll:left-4 "
                      width="17"
                      height="19"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z"
                        fill="#020202"
                      />
                    </svg>
                    <svg
                      className="lg:hidden block absolute left-[1px] ms:left-[6px] md:left-[8px] ls:left-[10px] lg:left-[12px] ll:left-4 "
                      width="16"
                      height="14"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z"
                        fill="#020202"
                      />
                    </svg>
                    <input
                      className={
                        "bg-transparent text-center  ml-2 mt-[2px] text-[12px] ss:text-[12px] ms:text-[14px] lg:text-[18px] placeholder-black font-semibold w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none input" +
                        (focus === START_DATE ? " -focused" : "")
                      }
                      {...startDateInputProps}
                      placeholder="Check in"
                    />
                  </div>
                  <div className="flex flex-row items-center rounded-full bg-white justify-center my-[6px]  py-3 relative">
                    <svg
                      className="hidden lg:block absolute left-[1px] ms:left-[6px] md:left-[8px] ls:left-[10px] lg:left-[12px] ll:left-4 "
                      width="17"
                      height="19"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z"
                        fill="#020202"
                      />
                    </svg>
                    <svg
                      className="lg:hidden block absolute left-[1px] ms:left-[6px] md:left-[8px] ls:left-[10px] lg:left-[12px] ll:left-4 "
                      width="16"
                      height="14"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z"
                        fill="#020202"
                      />
                    </svg>

                    <input
                      className={
                        "bg-transparent text-center  ml-3 mt-[2px] text-[12px] ss:text-[12px] ms:text-[14px] lg:text-[18px] placeholder-black font-semibold w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none input" +
                        (focus === END_DATE ? " -focused" : "")
                      }
                      {...endDateInputProps}
                      placeholder="Check out"
                    />
                  </div>
                </div>
              )}
            </DateRangePicker>
          </div>
          <div className="w-[26%] lg:w-[22%] flex flex-row  items-center justify-between">
            <div className="w-[50%] mx-0 lg:mx-1 flex flex-row items-center justify-between rounded-full bg-white px-1 py-[10px] ms:py-[14px] ">
              <div className="flex flex-row justify-between items-center">
                <div className="">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ll:mx-2 hidden lg:block"
                  >
                    <path
                      d="M16.5 14C15 14 13.8 14.4 12.9 14.9C14.3 16.1 14.9 17.5 15 17.6L15.1 17.8V20H23.1V18C23 18 21.4 14 16.5 14Z"
                      fill="#020202"
                    />
                    <path
                      d="M16.5 12C18.433 12 20 10.433 20 8.5C20 6.567 18.433 5 16.5 5C14.567 5 13 6.567 13 8.5C13 10.433 14.567 12 16.5 12Z"
                      fill="#020202"
                    />
                    <path
                      d="M4 8.5C4 6.6 5.6 5 7.5 5C9.4 5 11 6.6 11 8.5C11 10.4 9.4 12 7.5 12C5.6 12 4 10.4 4 8.5ZM7.5 14C2.6 14 1 18 1 18V20H14V18C14 18 12.4 14 7.5 14Z"
                      fill="#020202"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ll:mx-2 mr-1 lg:hidden block"
                  >
                    <path
                      d="M16.5 14C15 14 13.8 14.4 12.9 14.9C14.3 16.1 14.9 17.5 15 17.6L15.1 17.8V20H23.1V18C23 18 21.4 14 16.5 14Z"
                      fill="#020202"
                    />
                    <path
                      d="M16.5 12C18.433 12 20 10.433 20 8.5C20 6.567 18.433 5 16.5 5C14.567 5 13 6.567 13 8.5C13 10.433 14.567 12 16.5 12Z"
                      fill="#020202"
                    />
                    <path
                      d="M4 8.5C4 6.6 5.6 5 7.5 5C9.4 5 11 6.6 11 8.5C11 10.4 9.4 12 7.5 12C5.6 12 4 10.4 4 8.5ZM7.5 14C2.6 14 1 18 1 18V20H14V18C14 18 12.4 14 7.5 14Z"
                      fill="#020202"
                    />
                  </svg>
                </div>
                <div className="text-[13px] ms:text-[15px] lg:text-[17px] text-center ll:hidden font-bold ">
                  {adults}
                </div>
              </div>

              <div className="hidden ll:flex items-center xl:mr-[8px]">
                <button
                  onClick={() => {
                    if (adults > 0) setAdults(adults - 1);
                  }}
                  className=""
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-[90%] xl:scale-[100%]"
                  >
                    <path
                      d="M9 0C13.9711 0 18 4.02891 18 9C18 13.9711 13.9711 18 9 18C4.02891 18 0 13.9711 0 9C0 4.02891 4.02891 0 9 0ZM5.625 9.84375H12.375C12.8391 9.84375 13.2188 9.46406 13.2188 9C13.2188 8.53594 12.8391 8.15625 12.375 8.15625H5.625C5.16094 8.15625 4.78125 8.53594 4.78125 9C4.78125 9.46406 5.16094 9.84375 5.625 9.84375Z"
                      fill="#020202"
                    />
                  </svg>
                </button>
                <input
                  id="adults"
                  value={adults}
                  className="ll:w-4 xl:w-6 xxl:w-7 xxxl:w-8 text-center appearance-none block  bg-transparent  border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                />
                <button
                  onClick={() => setAdults(adults + 1)}
                  className="hidden ll:block"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-[90%] xl:scale-[100%]"
                  >
                    <path
                      d="M9 0.0625C7.23233 0.0625 5.50436 0.586675 4.0346 1.56874C2.56483 2.55081 1.41929 3.94665 0.742831 5.57977C0.0663725 7.21288 -0.11062 9.00992 0.234236 10.7436C0.579091 12.4773 1.43031 14.0698 2.68024 15.3198C3.93017 16.5697 5.52268 17.4209 7.25638 17.7658C8.99009 18.1106 10.7871 17.9336 12.4202 17.2572C14.0534 16.5807 15.4492 15.4352 16.4313 13.9654C17.4133 12.4956 17.9375 10.7677 17.9375 9C17.933 6.63102 16.9899 4.36037 15.3148 2.68525C13.6396 1.01013 11.369 0.0670426 9 0.0625ZM12.4375 9.6875H9.6875V12.4375C9.6875 12.6198 9.61507 12.7947 9.48614 12.9236C9.35721 13.0526 9.18234 13.125 9 13.125C8.81767 13.125 8.6428 13.0526 8.51387 12.9236C8.38494 12.7947 8.3125 12.6198 8.3125 12.4375V9.6875H5.5625C5.38017 9.6875 5.2053 9.61507 5.07637 9.48614C4.94744 9.35721 4.875 9.18234 4.875 9C4.875 8.81766 4.94744 8.6428 5.07637 8.51386C5.2053 8.38493 5.38017 8.3125 5.5625 8.3125H8.3125V5.5625C8.3125 5.38016 8.38494 5.2053 8.51387 5.07636C8.6428 4.94743 8.81767 4.875 9 4.875C9.18234 4.875 9.35721 4.94743 9.48614 5.07636C9.61507 5.2053 9.6875 5.38016 9.6875 5.5625V8.3125H12.4375C12.6198 8.3125 12.7947 8.38493 12.9236 8.51386C13.0526 8.6428 13.125 8.81766 13.125 9C13.125 9.18234 13.0526 9.35721 12.9236 9.48614C12.7947 9.61507 12.6198 9.6875 12.4375 9.6875Z"
                      fill="#020202"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="w-[50%] lg:mx-1 flex flex-row items-center justify-between rounded-full bg-white px-1 py-[10px] ms:py-[14px]">
              <div className=" flex flex-row justify-between items-center">
                <div>
                  <svg
                    width="21"
                    height="21"
                    className="ll:mx-2 "
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 7.21875C12.0102 7.21875 13.2344 5.99453 13.2344 4.48438C13.2344 2.97422 12.0102 1.75 10.5 1.75C8.98985 1.75 7.76562 2.97422 7.76562 4.48438C7.76562 5.99453 8.98985 7.21875 10.5 7.21875Z"
                      fill="#020202"
                    />
                    <path
                      d="M12.4687 13.6719C12.4687 13.6938 12.4687 13.7156 12.4687 13.7375C11.7031 13.8031 11.1344 14.4375 11.1562 15.2031C11.1562 15.4219 11.2219 15.6406 11.3312 15.8156C11.0906 15.9031 10.7844 15.9469 10.4562 15.9469C10.1062 15.9469 9.82187 15.9031 9.60312 15.8156C9.93124 15.3344 9.93124 14.6781 9.58124 14.175C9.31874 13.825 8.94687 13.6063 8.53124 13.5625V13.125H12.4687V13.6719ZM16.6687 9.66875L12.9062 7.7C12.8187 7.67813 12.7312 7.65625 12.6437 7.65625H8.53124C8.42187 7.65625 8.33436 7.67813 8.22499 7.7L4.28749 9.8875C3.82812 10.0625 3.60937 10.5656 3.76249 11.0031C3.89374 11.375 4.24374 11.5938 4.59374 11.5938C4.70312 11.5938 4.79062 11.5719 4.89999 11.55L7.65624 10.1063C7.65624 10.1063 7.65624 13.7156 7.65624 13.7812C7.61249 13.8031 5.86249 15.0281 5.86249 15.0281C5.49062 15.2906 5.38124 15.8156 5.62187 16.2094L7.15312 18.6156C7.41562 19.0312 7.96249 19.1406 8.35624 18.8781C8.74999 18.6156 8.88124 18.0688 8.61874 17.675L7.87499 16.4719H8.35624C8.50937 16.4719 8.66249 16.45 8.81562 16.4062C9.20937 16.6688 9.73437 16.8219 10.4344 16.8219C11.0906 16.8219 11.6156 16.6687 12.0312 16.45C12.2062 16.5375 12.425 16.5594 12.6437 16.5594L13.0375 16.5375L12.25 17.6312C11.9656 18.025 12.0531 18.5719 12.4469 18.8562C12.8406 19.1406 13.3875 19.0531 13.6719 18.6594L15.4219 16.2531C15.4437 16.2313 15.4656 16.2094 15.4656 16.1656C15.7062 15.75 15.5531 15.2031 15.1375 14.9625L13.3437 13.9562C13.3437 13.8687 13.3437 10.0187 13.3437 10.0187L16.1437 11.3531C16.2312 11.375 16.3187 11.3969 16.4062 11.3969C16.7781 11.3969 17.1281 11.1562 17.2375 10.7844C17.3906 10.3031 17.1281 9.82188 16.6687 9.66875Z"
                      fill="#020202"
                    />
                  </svg>
                </div>
                <div className="text-[13px] ms:text-[15px] lg:text-[17px] text-center ll:hidden font-bold ">
                  {children}
                </div>
              </div>

              <div className="ll:flex items-center mr-2 hidden">
                <button
                  onClick={() => {
                    if (children > 0) setChildren(children - 1);
                  }}
                  className=""
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-[90%] xl:scale-[100%]"
                  >
                    <path
                      d="M9 0C13.9711 0 18 4.02891 18 9C18 13.9711 13.9711 18 9 18C4.02891 18 0 13.9711 0 9C0 4.02891 4.02891 0 9 0ZM5.625 9.84375H12.375C12.8391 9.84375 13.2188 9.46406 13.2188 9C13.2188 8.53594 12.8391 8.15625 12.375 8.15625H5.625C5.16094 8.15625 4.78125 8.53594 4.78125 9C4.78125 9.46406 5.16094 9.84375 5.625 9.84375Z"
                      fill="#020202"
                    />
                  </svg>
                </button>
                <input
                  id="children"
                  value={children}
                  className="ll:w-4 xl:w-6 xxl:w-7 xxxl:w-8 text-center appearance-none block  bg-transparent  border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                />
                <button
                  onClick={() => setChildren(children + 1)}
                  className="hidden ll:block"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="scale-[90%] xl:scale-[100%]"
                  >
                    <path
                      d="M9 0.0625C7.23233 0.0625 5.50436 0.586675 4.0346 1.56874C2.56483 2.55081 1.41929 3.94665 0.742831 5.57977C0.0663725 7.21288 -0.11062 9.00992 0.234236 10.7436C0.579091 12.4773 1.43031 14.0698 2.68024 15.3198C3.93017 16.5697 5.52268 17.4209 7.25638 17.7658C8.99009 18.1106 10.7871 17.9336 12.4202 17.2572C14.0534 16.5807 15.4492 15.4352 16.4313 13.9654C17.4133 12.4956 17.9375 10.7677 17.9375 9C17.933 6.63102 16.9899 4.36037 15.3148 2.68525C13.6396 1.01013 11.369 0.0670426 9 0.0625ZM12.4375 9.6875H9.6875V12.4375C9.6875 12.6198 9.61507 12.7947 9.48614 12.9236C9.35721 13.0526 9.18234 13.125 9 13.125C8.81767 13.125 8.6428 13.0526 8.51387 12.9236C8.38494 12.7947 8.3125 12.6198 8.3125 12.4375V9.6875H5.5625C5.38017 9.6875 5.2053 9.61507 5.07637 9.48614C4.94744 9.35721 4.875 9.18234 4.875 9C4.875 8.81766 4.94744 8.6428 5.07637 8.51386C5.2053 8.38493 5.38017 8.3125 5.5625 8.3125H8.3125V5.5625C8.3125 5.38016 8.38494 5.2053 8.51387 5.07636C8.6428 4.94743 8.81767 4.875 9 4.875C9.18234 4.875 9.35721 4.94743 9.48614 5.07636C9.61507 5.2053 9.6875 5.38016 9.6875 5.5625V8.3125H12.4375C12.6198 8.3125 12.7947 8.38493 12.9236 8.51386C13.0526 8.6428 13.125 8.81766 13.125 9C13.125 9.18234 13.0526 9.35721 12.9236 9.48614C12.7947 9.61507 12.6198 9.6875 12.4375 9.6875Z"
                      fill="#020202"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if(check) handleSearchClick(true);
            }}
            className="w-[8%] lg:w-[4%] ll:mx-2 justify-center flex hover:cursor-pointer"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 41 40"
              fill="none"
              className="my-auto  mx-1 lg:mx-0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39.7067 35.8283L30.702 26.8236C32.8699 23.9376 34.0402 20.4246 34.0363 16.815C34.0363 7.59496 26.535 0.09375 17.315 0.09375C8.09496 0.09375 0.59375 7.59496 0.59375 16.815C0.59375 26.035 8.09496 33.5363 17.315 33.5363C20.9246 33.5402 24.4376 32.3699 27.3236 30.202L36.3283 39.2067C36.7842 39.6141 37.3787 39.8316 37.9898 39.8145C38.601 39.7974 39.1824 39.547 39.6147 39.1147C40.047 38.6824 40.2974 38.101 40.3145 37.4898C40.3316 36.8787 40.1141 36.2842 39.7067 35.8283ZM5.37125 16.815C5.37125 14.4527 6.07174 12.1435 7.38413 10.1794C8.69653 8.21527 10.5619 6.68441 12.7443 5.78041C14.9268 4.87642 17.3282 4.63989 19.6451 5.10075C21.962 5.5616 24.0901 6.69913 25.7605 8.36949C27.4309 10.0399 28.5684 12.168 29.0293 14.4849C29.4901 16.8018 29.2536 19.2032 28.3496 21.3857C27.4456 23.5681 25.9147 25.4335 23.9506 26.7459C21.9865 28.0583 19.6772 28.7587 17.315 28.7587C14.1485 28.7549 11.1128 27.4954 8.87369 25.2563C6.63462 23.0172 5.37505 19.9815 5.37125 16.815Z"
                fill="#020202"
              />
            </svg>
          </div>
        </div>

        <div className="w-[18%] px-3 hidden ll:flex items-center justify-center">
        {user ? (
            <div className="flex flex-row items-center">
              <div className="rounded-full bg-white w-10 h-10 flex items-center justify-center mr-2">
                <img
                  width="34"
                  height="34"
                  src="/user.png"
                  alt="user-male-circle"
                />
              </div>
              <div onClick={handleDropdownToggle} className="text-xl -ml-1 font-bold cursor-pointer" style={{ textTransform: "capitalize" }}>{user.first_name}</div>
              <button
                className="flex items-center focus:outline-none"
                onClick={handleDropdownToggle}
              >
                <FiChevronDown size={24} />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-2 top-14 z-[400000]   border-2 w-48 bg-white rounded-md shadow-lg py-2">
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <BsGear className="inline-block mr-2" size={16} />
                    Manage Account
                  </Link>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaBookmark className="inline-block mr-2" size={16} />
                    Bookings
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaWallet className="inline-block mr-2" size={16} />
                    Rewards and Wallet
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaStar className="inline-block mr-2" size={16} />
                    Reviews
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaBookmark className="inline-block mr-2" size={16} />
                    Saved
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    <IoMdLogOut className="inline-block mr-2" size={16} />
                    Sign Out
                  </a>
                </div>
              )}
            </div>
          ) : (
          <>
          <button onClick={()=>{SetRegisterpop(!Registerpop)}} className="bg-white font-bold  text-black border-black border-2 w-[130px] h-[35px] rounded-full shadow-md">
            Register
          </button>
          <button onClick={()=>{SetLoginPop(!Loginpop)}} className="bg-black font-bold   ml-3 text-white  w-[130px] h-[35px] rounded-full  shadow-md">
            Sign in
          </button>
          </>
           )}
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
