import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Title from "../Title and Address/Title";
import Layout from "../Layout/Layout";
import { Carousel } from "react-responsive-carousel";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./test.css"
// const images=[{"src":"https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"},
// {"src":"https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"},
// {"src":"https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"}]
const GalleryAmenity = ({villaData}) => {
  villaData.media?.sort((a, b) => {
    const indexA = villaData.media_order.indexOf(a.mediafiles_id);
    const indexB = villaData.media_order.indexOf(b.mediafiles_id);
    return indexA - indexB;
});
if(villaData.media.length>0){
  while (villaData.media.length < 6) {
    villaData.media = villaData.media.concat(villaData.media);
  }
}
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const images = villaData.media.map(item => {
      return { "src": item.media.file };
  });
  const [tempimage, setTempImage]=useState(villaData.media.map(item => {
    return { "src": item.media.file }}))


  const freeAmenities = villaData.amenities.filter((amenity) => !villaData.amenities_paid.includes(amenity.id));
const paidAmenities = villaData.amenities.filter((amenity) => villaData.amenities_paid.includes(amenity.id));
  return (<>
    
    <div className="lg:bg-[#EAEAEA] font-jt h-fit w-[99%] lg:w-[95vw] p-[2px] lg:p-4 rounded-3xl lg:rounded-3xl mt-2 mx-auto">
      <div className="hidden lg:flex flex-col lg:flex-row h-[600px] lg:h-[550px] w-full">
        <div className="w-full lg:w-[70%] h-[40%] lg:h-full ">
          <img
            onClick={() => setOpen(true)} 
            className="p-1 lg:p-2 cursor-pointer  rounded-3xl lg:rounded-3xl  w-full h-full  "
            src={villaData.media.length>0? villaData.media[0].media.file: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
            alt=""
          />
        </div>
        <div className="w-full lg:w-[30%] h-[25%] lg:h-full  flex flex-row lg:flex-col justify-around">
          <img
            onClick={() => setOpen(true)} 
            className="p-1 lg:p-2  cursor-pointer rounded-3xl lg:rounded-3xl  w-1/2 lg:w-full h-full lg:h-1/2 max-h-full"
            src={villaData.media.length>0? villaData.media[1].media.file: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
            alt=""
          />
          <img
            onClick={() => setOpen(true)} 
            className="p-1 lg:p-2 cursor-pointer rounded-3xl lg:rounded-3xl  w-1/2 lg:w-full h-full lg:h-1/2 max-h-full"
            src={villaData.media.length>0? villaData.media[2].media.file: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
            alt=""
          />
        </div>
        <div className="w-full lg:w-[15%] h-[20%] lg:h-full flex flex-row lg:flex-col justify-around">
          <img
            onClick={() => setOpen(true)} 
            className="p-1 lg:p-2 cursor-pointer  rounded-3xl lg:rounded-3xl h-full w-1/3  lg:w-full lg:h-1/3 "
            src={villaData.media.length>0? villaData.media[3].media.file: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
            alt=""
          />
          <img
            onClick={() => setOpen(true)} 
            className="p-1 lg:p-2 cursor-pointer rounded-3xl lg:rounded-3xl h-full w-1/3 lg:w-full lg:h-1/3 "
            src={villaData.media.length>0? villaData.media[4].media.file: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
            alt=""
          />
          <img
            onClick={() => setOpen(true)} 
            className="p-1 lg:p-2 cursor-pointer rounded-3xl lg:rounded-3xl h-full w-1/3  lg:w-full lg:h-1/3 "
            src={villaData.media.length>0? villaData.media[5].media.file: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
            alt=""
          />
          <div className="hidden lg:flex flex-row  m-4">
            <svg
              className="my-auto ml-6 mr-1"
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.670117 1.8541L0.736914 1.93203L5.78379 7.73965C5.95449 7.93633 6.21055 8.05879 6.49629 8.05879C6.78203 8.05879 7.03809 7.93262 7.20879 7.73965L12.252 1.94316L12.3373 1.84668C12.4004 1.75391 12.4375 1.64258 12.4375 1.52383C12.4375 1.20098 12.1629 0.9375 11.8215 0.9375H1.17852C0.837109 0.9375 0.5625 1.20098 0.5625 1.52383C0.5625 1.64629 0.60332 1.76133 0.670117 1.8541Z"
                fill="#5C18CC"
              />
            </svg>
            <button onClick={() => setOpen(true)} className=" underline text-sm  text-[#5C18CC]">Show More</button>
          </div>
        </div>
        <div className="lg:hidden flex flex-row pb-4 my-4 text-center mr-6 justify-center">
            <svg
              className="my-auto ml-6 mr-1"
              width="20"
              height="14"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.670117 1.8541L0.736914 1.93203L5.78379 7.73965C5.95449 7.93633 6.21055 8.05879 6.49629 8.05879C6.78203 8.05879 7.03809 7.93262 7.20879 7.73965L12.252 1.94316L12.3373 1.84668C12.4004 1.75391 12.4375 1.64258 12.4375 1.52383C12.4375 1.20098 12.1629 0.9375 11.8215 0.9375H1.17852C0.837109 0.9375 0.5625 1.20098 0.5625 1.52383C0.5625 1.64629 0.60332 1.76133 0.670117 1.8541Z"
                fill="#5C18CC"
              />
            </svg>
            <button onClick={() => setOpen(true)} className=" underline text-xl  text-[#5C18CC]">Show More</button>
          </div>
      </div>

      <div className="lg:hidden block p-2 ml-2 text-[30px] font-bold ">
        Amenities at Property
      </div>

      <div className="lg:hidden block px-2 ml-2 text-[25px] font-bold ">
        Free
      </div>
      <div className="hidden lg:block p-2 ml-2 text-[30px] font-bold ">
        Free Amenities at Property
      </div>
      <div className=" p-2 justify-center lg:justify-start lg:ml-2 w-full flex flex-wrap flex-row   lg:h-auto lg:flex-row">
        {freeAmenities.map(a=>(<div className="flex flex-col w-[80px] h-[80px] items-cemter lg:h-auto rounded-md lg:w-auto lg:flex-row mt-2   lg:min-w-[200px] bg-orange-200 lg:bg-white lg:rounded-full p-1 justify-center lg:p-2 mx-1 lg:mx-2 lg:px-4 items-center">
          <div className="mr-2 ">
            <img className="lg:w-8 lg:h-8 w-8 h-8" src={a.icon.file} alt={a.icon.file} />
          </div>
          {a.name.length>15?(<div className="text-[11.5px] -tracking-wider lg:tracking-normal text-center lg:text-lg">{a.name}</div>):(<div className="text-sm text-center lg:text-lg">{a.name}</div>)}
        </div>))}
        
        
      </div>
      {villaData.amenities_paid.length>0?(<><div className="lg:hidden block px-2 ml-2 text-[25px] font-bold ">
        Paid
      </div>
      <div className="hidden lg:block p-2 ml-2 text-[30px] font-bold ">
        Paid Amenities at Property
      </div>
      <div className=" p-2 justify-center lg:justify-start lg:ml-2 w-full flex flex-wrap flex-row   lg:h-auto lg:flex-row">
        {paidAmenities.map(a=>(<div className="flex flex-col w-[80px] h-[80px] items-cemter lg:h-auto rounded-md lg:w-auto lg:flex-row mt-2   lg:min-w-[200px] bg-purple-200 lg:bg-white lg:rounded-full p-1 justify-center lg:p-2 mx-1 lg:mx-2 lg:px-4 items-center">
          <div className="mr-2 ">
            <img className="lg:w-8 lg:h-8 w-8 h-8" src={a.icon.file} alt={a.icon.file} />
          </div>
          {a.name.length>15?(<div className="text-[11.5px] -tracking-wider lg:tracking-normal text-center lg:text-lg">{a.name}</div>):(<div className="text-sm text-center lg:text-lg">{a.name}</div>)}
        </div>))}
        
        
      </div></>):(<></>)}
      <Lightbox
          open={open2}
          close={() => setOpen2(false)}
          slides={tempimage}
          
        />
        {open && (<div className="w-[100vw] bg-white z-[100000] fixed inset-0"><div className=" fixed z-[100000] inset-0 w-[99vw] custom-scrollbar bg-white rounded-md   h-screen overflow-y-auto">
          <div className="flex top-0 right-6 sticky justify-end"><button onClick={()=>{setOpen(!open)}} className="text-end text-2xl w-fit m-2 border-2 rounded-full  px-2" >X</button></div>
          {images.map((option,index) => (
            <div className="p-6 w-[60%] mx-auto">
            <img src={option.src} alt="" onClick={()=>{
              
              const clickedItem = images.splice(index, 1)[0]; // Remove clicked item
              const newArr = [...images.slice(index), ...images.slice(0, index)]; // Rearrange array
              setTempImage([clickedItem, ...newArr]); // Set rearranged array

              setOpen2(true)}} className=" cursor-pointer rounded-lg"/>
            </div>
          ))}

        </div></div>)}
    </div>
    
   
    </>
  );
};

export default GalleryAmenity;
