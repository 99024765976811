import React, { useState } from 'react';

const KeyForm = ({ onSubmit, setBatch,keyInput,setKeyInput }) => {
  

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(keyInput, setBatch);
  };

  const handleRadioChange = (e) => {
    const isBatch = e.target.value === 'batch';
    setBatch(isBatch);
  };

  return (
    <div>
      <div className="flex items-center justify-center text-[40px]">Advance Database Settings</div>
      <div className='flex flex-col items-center justify-center '>
      <div className="text-blue-500 font-bold lg:text-[25px] mb-2">
      Key Changes Every 12 Hours. Contact the Admin for Key Access.
</div>
<div className="text-red-600 font-bold lg:text-[25px] text-center ">
  Caution: This section covers advanced database settings, and any mistake could result in data loss or corruption. Please make sure to back up your data before making significant changes. If you are not an experienced database administrator, seek professional assistance or test changes in a controlled environment.
</div>
      </div>
      <div className="flex items-center justify-center h-[50vh]">
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              htmlFor="keyInput"
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            >
              Enter Key:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              type="text"
              id="keyInput"
              value={keyInput}
              onChange={(e) => setKeyInput(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              htmlFor="userType"
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            >
              Select Option:
            </label>
          </div>
          <div className="md:w-2/3">
            <div className="flex items-center">
              <input
                type="radio"
                id="updateUser"
                name="userType"
                value="user"
                onChange={handleRadioChange}
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <label htmlFor="updateUser" className="ml-2">
                Update One User
              </label>
            </div>
            <div className="flex items-center mt-2">
              <input
                type="radio"
                id="batchPassword"
                name="userType"
                value="batch"
                onChange={handleRadioChange}
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <label htmlFor="batchPassword" className="ml-2">
                Change Batch Password
              </label>
            </div>
          </div>
        </div>
        <div className="md:flex md:items-center">
          <div className="md:w-1/3"></div>
          <div className="md:w-2/3">
            <button
              type="submit"
              className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    </div>
  );
};

export default KeyForm;
