import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "tailwindcss/tailwind.css";


const userPageValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    emailAddress: Yup.string().email("Invalid email address").required("Required"),
    displayName: Yup.string().required("Required"),
    dateOfBirth: Yup.date().required("Required"),
    address: Yup.string().required("Required"),
  });
const UserProfilePage2 = () => {
    const [isEditing, setIsEditing] = useState(true);
  
    const initialValues = {
      name: "John Doe",
      nationality: "American",
      gender: "Male",
      emailAddress: "john.doe@example.com",
      displayName: "john_doe",
      dateOfBirth: "1990-01-01",
      address: "123 Main St, New York, NY 10001",
    };
  
    const toggleEditing = () => {
      setIsEditing(!isEditing);
    };
  
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white rounded p-8 shadow-xl w-full sm:w-96">
          <div className="flex flex-col items-center mb-6">
            <img
              src="https://via.placeholder.com/100"
              alt="Profile"
              className="w-24 h-24 rounded-full mb-4"
            />
            <h1 className="text-2xl font-bold">{initialValues.name}</h1>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={userPageValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                //console.log(JSON.stringify(values, null, 2));
                setSubmitting(false);
                toggleEditing();
              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                {Object.keys(initialValues).map((key) => (
                  <div key={key}>
                    <label htmlFor={key} className="block text-sm font-medium">
                      {key.split(/(?=[A-Z])/).join(" ")}
                    </label>
                    <Field
                      id={key}
                      name={key}
                      type={key === "dateOfBirth" ? "date" : "text"}
                      className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
                        isEditing ? "" : "bg-gray-200"
                      }`}
                      disabled={!isEditing}
                    />
                    <ErrorMessage name={key} className="text-red-600 text-sm" component="div" />
                  </div>
                ))}
                <div className="flex justify-end">
                {isEditing ? (
                  <button
                    type="submit"
                    className="bg-black text-white px-4 py-2 rounded"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Save"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="bg-black text-white px-4 py-2 rounded"
                    onClick={toggleEditing}
                  >
                    Edit
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserProfilePage2;

  