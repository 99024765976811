import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

const SliderTesting=()=>{
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      const slides=[
        "https://picsum.photos/id/237/800/600",
        "https://images.unsplash.com/photo-1517849845537-4d257902454a",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1024px-Image_created_with_a_mobile_phone.png"
      ]
      return (
        <div className="w-full md:w-[40%]  my-auto">
        <Slider {...settings}>
           {slides.map((i) => (
            
            <div className="rounded-3xl w-fit lg:rounded-2xl">
              <img
                className=" rounded-3xl w-fit lg:rounded-2xl lg:h-[300px]  h-[210px]"
                src={i}
              />
            </div>
          
        ))}
        
        </Slider>
        </div>
        )
}

export default SliderTesting