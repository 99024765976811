import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import axios from "axios";


const emailValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });
  
  const signupValidationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    contactNumber: Yup.string().required("Required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const MultiStepSignupForm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(3);
    const [email, setEmail] = useState("");
    const [emailExists, setEmailExists] = useState(true);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
    async function checkEmail(email) {
      try {
        const response = await axios.post("/api/check-email", { email });
        setEmailExists(response.data.emailExists);
      } catch (error) {
        console.error("Error checking email:", error);
      }
    }
  
    return (
      <div className="font-hk min-h-screen bg-gray-100 flex items-center justify-center">
        <button className="bg-white px-6 py-2 rounded font-bold" onClick={openModal}>
          Signup
        </button>
        <Modal isOpen={isOpen} onRequestClose={closeModal} className="font-hk  bg-white rounded p-8 shadow-xl">
          {step === 1 && (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={emailValidationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setEmail(values.email);
                await checkEmail(values.email);
                setSubmitting(false);
                setStep(2);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4">
                  <h1 className="text-2xl font-bold mb-6">Enter your Email</h1>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium">
                      Email
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    />
                    <ErrorMessage name="email" className="text-red-600 text-sm" component="div" />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="bg-black text-white px-4 py-2 rounded"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
  
  {step === 2 && (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              contactNumber: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={signupValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              // Perform your API request to register the user here
              try {
                const response = await axios.post("/api/register", {
                  email,
                  ...values,
                });
                if (response.status === 200) {
                  setStep(3);
                }
              } catch (error) {
                console.error("Error registering user:", error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                <h1 className="text-2xl font-bold mb-6">Register your Account</h1>
                <p>Please provide the following details:</p>
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium">
                    First Name
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                  <ErrorMessage name="firstName" className="text-red-600 text-sm" component="div" />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium">
                    Last Name
                  </label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                  <ErrorMessage name="lastName" className="text-red-600 text-sm" component="div" />
                </div>
                <div>
                  <label htmlFor="contactNumber" className="block text-sm font-medium">
                    Contact Number
                  </label>
                  <Field
                    id="contactNumber"
                    name="contactNumber"
                    type="text"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                  <ErrorMessage name="contactNumber" className="text-red-600 text-sm" component="div" />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium">
                    Create Password
                  </label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                  <ErrorMessage name="password" className="text-red-600 text-sm" component="div" />
                </div>
                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium">
                    Confirm Password
                  </label>
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                  <ErrorMessage name="confirmPassword" className="text-red-600 text-sm" component="div" />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-black text-white px-4 py-2 rounded"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Signup"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}

{step === 3 && (
          <div className="space-y-4">
            <h1 className="text-2xl font-bold mb-6">Thanks for Registering</h1>
            <p>
              We have sent you a Verification Link on your email address.
            </p>
            <p>Open the link to activate your account.</p>
            <div className="flex flex-col">
              <button
                onClick={() => {
                  // Resend verification code logic here
                }}
                className="bg-black text-white px-4 py-2 rounded mb-2"
              >
                Resend Code
              </button>
              <button
                onClick={() => {
                  // Redirect to login page or close the modal
                  closeModal();
                }}
                className="bg-black text-white px-4 py-2 rounded"
              >
                Login
              </button>
            </div>
          </div>
        )}
        <button className="text-gray-400 mt-4" onClick={closeModal}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default MultiStepSignupForm;

  
