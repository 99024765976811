import React, { useState } from "react";
// import "./LandmarkDropDown.css"
function LandMarkDropdown({
  landmarklist,
  selectedOptions,
  setSelectedOptions,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleRemoveSelectedItem = (event, option) => {
    event.stopPropagation();
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  };

  return (
    <div className="w-full relative ">
      <div className="w-full p-1 flex flex-row text-black bg-none border-[2px] rounded-full  items-center justify-between">
        <div className="w-[8%] pl-2">
          <svg
            width="17"
            height="25"
            viewBox="0 0 17 25"
            fill="none"
            className=""
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.4998 0.125C4.25234 0.125 0.810547 3.58613 0.810547 7.85938C0.810547 15.2457 8.4998 24.875 8.4998 24.875C8.4998 24.875 16.1891 15.2457 16.1891 7.85938C16.1891 3.58613 12.7473 0.125 8.4998 0.125ZM8.4998 11.6105C6.48887 11.6105 4.86465 9.97344 4.86465 7.95605C4.86465 5.93867 6.49531 4.29512 8.4998 4.29512C10.5107 4.29512 12.135 5.93223 12.135 7.95605C12.135 9.97344 10.5107 11.6105 8.4998 11.6105Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="w-[92%]">
          <button
            className="w-full py-2 px-4 text-black  flex flex-row items-center justify-between"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {selectedOptions.length === 0 ? (
              <span className="font-semibold text-center w-full ">
                Select Landmarks
              </span>
            ) : (
              <div className="flex flex-wrap">
                {selectedOptions.map((optionId) => {
                  const option = landmarklist.find(
                    (item) => item.id === optionId
                  );
                  return (
                    <span
                      key={option.id}
                      className="bg-gray-200 rounded-full py-1 px-3   inline-flex items-center text-sm"
                    >
                      {option.title}
                      <button
                        className="ml-2 scale-[1.5] transition-all hover:scale-[2] focus:ring-0 hover:focus:outline-none text-gray-500 hover:text-gray-600 focus:outline-none"
                        onClick={(event) =>
                          handleRemoveSelectedItem(event, option.id)
                        }
                      >
                        &times;
                      </button>
                    </span>
                  );
                })}
              </div>
            )}
            <svg
              width="20"
              height="13"
              viewBox="0 0 23 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="absolute w-[95%] mt-1 custom-scrollbar bg-white rounded-md shadow-lg border-2 border-[#F4F4F4] p-1 max-h-[250px] overflow-y-auto">
          {landmarklist.map((option) => (
            <div
              key={option.id}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleOptionClick(option.id)}
            >
              <input
                type="checkbox"
                className="mr-2 cursor-pointer accent-black"
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleOptionClick(option.id)}
              />
              <span className="select-none text-lg">{option.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LandMarkDropdown;
