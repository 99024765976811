import { useEffect } from "react";

const Pagination = ({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
  setPageQuery
}) => {
  let pages = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }
  let limit = pages.length;
  useEffect(()=>{
     const temp = `&page_number=${currentPage}`;
     setPageQuery(temp);
  },[currentPage])
  
  return (
    <div className=" font-jt flex flex-wrap justify-center w-full my-4">
      <>
        <button
          className="px-2 py-1 font-semibold rounded-full cursor-pointer transition-all duration-300 bg-transparent text-black border-black mx-1"
          onClick={() => {
            if (currentPage > 0) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          {"← Previous"}
        </button>
        {pages.map((page, index) => {
          return (
            <button
              key={index}
              onClick={() => {
                setCurrentPage(page);
                currentPage = page;
              }}
              className={`w-10 h-10 font-semibold rounded-full cursor-pointer transition-all duration-300 mx-1 ${page == currentPage ? 'font-black bg-[#ECECEC] text-black ' : 'bg-transparent text-black border-black'}`}
            >
              {page}
            </button>
          );
        })}
        {currentPage > limit ? (
          <>
            {" "}
            <button className={`w-10 h-10 font-black bg-[#ECECEC] text-white border-black rounded-full cursor-pointer transition-all duration-300 mx-1`}>{currentPage}</button>
          </>
        ) : (
          <></>
        )}
        <button
          className="px-10 py-2 font-semibold  rounded-lg   cursor-pointer transition-all duration-300  text-white bg-black border-black mx-1"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {"Next →"}
        </button>
      </>
    </div>
  );
};

export default Pagination;
