import React, { useState } from "react";

const aboutUs =
  "Lodged amidst landscaped verdure, extensive gardens and sumptuous amenities, The Charcoal Villa is the definition of modern, laidback luxury. Sweeping you to a secluded sanctuary, this opulent abode isquite literally a refuge from the chaos and buzz of urban clamour. This generously proportioned villa is imbued with exquisite taste, evident in the abundance eometric designs, the soothing monochromes and the curated bold choice of furnishings. The ash-black facade mirrors its name, whilst its interiors consistently echo hints of the charcoal palette. The radiant indoor spaces glow under the plentiful sunlight and seamlessly open up to the sprawling outdoors.Lodged amidst landscaped verdure, extensive gardens and sumptuous amenities, The Charcoal Villa is the definition of modern, laidback luxury. Sweeping you to a secluded sanctuary, this opulent abode is quite literally a refuge from the chaos and buzz of urban clamour. This generously proportioned villa is imbued with exquisite taste, evident in the abundance of geometric designs, the soothing monochromes and the curated bold choice of furnishings. The ash-black facade mirrors its name, whilst its interiors consistently echo hints of the charcoal palette. The radiant indoor spaces glow under the plentiful sunlight and seamlessly open up to the sprawling outdoors.";
const policies = [
  "The full payment must be made for your booking to be confirmed.",
  "The number of guests must not exceed the count mentioned at the time of booking.",
  "Only the guests who have been accounted for are allowed at the villa.",
  "All guests must be able to share valid ID proofs when asked. This could be at the time of booking, and also upon arrival.",
  "All foreign nationals must be able to share their passport and visa details prior to their stay.",
  "Any damage to the property caused by the guest will be charged as per the actual cost of repair or replacement.",
  "Any illegal activity - including but not limited to prostitution and the use of narcotics - is strictly not permitted.",
  "Any commercial activity is strictly not permitted.",
];
const houseRules = [
  "The property is located in a secluded area.",
  "This home is not suitable for wheelchair users and guests with mobility challenges.",
  "In case of a power outage, the generator can support the ACs and refrigerator for up to 24 hours.",
  "There is also an inverter that can support all lights and fans for up to 5 hours.",
  "The road leading up to the villa may not be concrete.",
  "The caretaker’s pet dog resides on the property premises for security purposes.",
  "There are CCTV cameras that capture the external areas of the villa for security purposes.",
  "The rooms are in the form of separate cottages. These cottages are connected via a common patio.",
];

// const Policies = ({ policies, aboutUs, houseRules }) => {
const RulesAndPolicies = ({ villaData }) => {
  const [step, setStep] = useState(1);
  const active =
    "w-1/3  bg-[#F1F1F1]  font-bold text-black text-center  hover:cursor-pointer py-2 border-b-4 border-[#5C18CC] ";
  const unactive =
    "w-1/3  bg-none font-medium text-black  text-center hover:cursor-pointer py-2 border-b-4 border-[#F1F1F1] ";

  // //console.log(policies);

  return (
    <div className="flex flex-col justify-center font-jt w-[95vw] lg:w-[80vw] mx-auto">
      <div className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8 font-jt">
        Rules & Policies
      </div>
      <div className=" flex justify-center flex-col ">
        <div className="   flex justify-center mx-2">
          <div className="flex flex-row justify-center w-[100%]">
            <div
              className={step == 0 ? active : unactive}
              onClick={() => {
                setStep(0);
              }}
            >
              About Us
            </div>
            <div
              className={step == 1 ? active : unactive}
              onClick={() => {
                setStep(1);
              }}
            >
              House Rules
            </div>
            <div
              className={step == 2 ? active : unactive}
              onClick={() => {
                setStep(2);
              }}
            >
              Policies
            </div>
          </div>
        </div>
        <div className="text-[13px] lg:text-[18px] tracking-wider   lg:px-4">
          {step == 0 && (
            <div className="mb-4 lg:mt-10 mt-5   flex items-center flex-col">
              <div className="">{villaData.aboutus?aboutUs:villaData.aboutus}</div>
            </div>
          )}
          {step == 1 && (
            <div className="mb-4 lg:mt-10 mt-4  flex items-center flex-col">
              <ul className="text-[13px]  lg:text-[18px]">
                {villaData.houserules?.map((item, i) => {
                  return <li className="list-disc my-2">{item}</li>;
                })}
              </ul>
            </div>
          )}
          {step == 2 && (
            <div className="mb-4 lg:mt-10 mt-4  flex items-center flex-col">
              <ul className="text-[13px]  lg:text-[18px]">
                {villaData.policies?.map((item, i) => {
                  return <li className="list-disc my-2">{item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RulesAndPolicies;
