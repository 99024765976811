import React from 'react';

const RefundPolicy = () => (
  <div className="w-full h-full bg-white p-8 font-jt">
    <h2 className="text-2xl font-bold mb-6">What is the A2Z Villaz refund policy?</h2>

    <div className="mb-4">
      <h3 className="text-xl font-semibold mb-2">• Flexible</h3>
      <p>Full refund if you can cancel upto 2 days prior to arrival, full refund is applicable on the price of room night and cleaning fees, but no refund on service fees.</p>
    </div>

    <div className="mb-4">
      <h3 className="text-xl font-semibold mb-2">• Moderate</h3>
      <p>Full refund if you cancel upto 5 days prior to arrival, full refund is applicable on the price of room night and cleaning fees, but no refund on service fees.</p>
    </div>

    <div className="mb-4">
      <h3 className="text-xl font-semibold mb-2">• Strict</h3>
      <p>Under this policy if you cancel upto 5 days prior to arrival, you get 50% refund on the room night and cleaning fees, but no refund on service fees.</p>
    </div>

    <p>In case of no show, you will not be eligible for any refund on room night and cleaning fees or refund on platform fees.</p>
  </div>
);

export default RefundPolicy;
