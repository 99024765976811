import react, { useState, useEffect } from "react";
import axios from "axios";
import PayNowCard from "../../components/Payment/PayNowCard";
import { useLocation, useNavigate } from "react-router-dom";
import BasicHeader from "../../components/Payment/BasicHeader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserDetail from "../../components/Payment/UserDetail";
import Footer from "../../components/Footer/Footer.main";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const PaymentPage = () => {
  const navigate = useNavigate();
  const Location = useLocation();
  const bookingData = Location.state;
  //console.log("BOOKING DATA", bookingData);

  const {
    adults,
    children,
    price,
    checkIn,
    checkOut,
    villaName,
    villaAddress,
    cancellationPolicy,
    checkInTime,
    checkOutTime,
    damageDeposit,
    villaCity,
    villaLandmark,
    thumbnail,
    prepayment,
    taxFlag,
    villa_long,
    villa_lat,
    host_id,
  } = bookingData;

  let cancellation = cancellationPolicy.split("_").join(" ").toLowerCase();

  const checkInDate = checkIn?.toDateString();
  const checkOutDate = checkOut?.toDateString();

  const handleEditClick = () => {
    //console.log("EDIT");
    navigate(-1);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [buttonActive, setButtonActive] = useState(false);

  const handlePaymentSuccess = async (response) => {
    try {
     
      //console.log(response,"response")
      await axios.post(
        `http://localhost:8000/api/v1/payment-success/`,{response,villaName,villaAddress,villaLandmark,checkInTime,checkOutTime,villa_long,villa_lat,host_id})
        .then((res) => {
          //console.log("Everything is OK!");
        })
        .catch((err) => {
          //console.log(err);
        });
    } catch (error) {
      //console.log(console.error());
    }
  };


  const loadScript =async () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };
  //console.log(checkIn,checkOut)
  const showRazorpay = async () => {
    const res = await loadScript();
    let name=formData.name
    let email=formData.email
    let phone=formData.phone
    let totalCost= !taxFlag ?Math.floor(1.12 * price) : price
    let price_to_pay=0.01 * prepayment * totalCost 
    let price_to_pay_later=totalCost-price_to_pay
    let checkin=checkIn.toISOString().split('T')[0]
    let checkout=checkOut.toISOString().split('T')[0]
    const data = await axios.post(`http://localhost:8000/api/v1/pay/`,{name,email,phone,price_to_pay,checkin,checkout,adults,children,prepayment,totalCost,price_to_pay_later})
    //console.log(data,"first response data")

    
    var options = {
      key_id: "rzp_test_vFJ5bcRhRNsZXu", 
      key_secret: "2vjlFmC125DhBnZgQdb8AdB7",
      amount: data.data.payment.amount,
      currency: "INR",
      name: "A2Z Villas",
      description: "Test teansaction",
      image: "./logopay.png", 
      order_id: data.data.payment.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: "A2Z Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };









  return (
    <>
      <BasicHeader />
      <div className=" mx-auto w-[90vw] font-hk mb-10">
        <div className="flex flex-row">
          <div className="w-[55%]  px-10 flex flex-col">
            <div className="text-[30px] font-bold font-serif mt-1">
              Review & Pay
            </div>
            <div className="my-1 pt-4 font-serif">
              <div className="flex justify-between my-1 text-center">
                <div className="text-[20px] font-semibold font-hk">
                  Booking Details
                </div>
                <div
                  className="text-[17px] font-medium hover:cursor-pointer hover:text-blue-700 hover:underline"
                  onClick={handleEditClick}
                >
                  {" "}
                  Edit
                </div>
              </div>
              <div className="flex justify-between text-[17px] font-medium mt-4 mb-2">
                <div className="">Check-In (After 1 PM)</div>
                <div>{checkInDate}</div>
              </div>
              <div className="flex justify-between text-[17px] font-medium my-3">
                <div className="">Check-Out (Before 11 AM)</div>
                <div>{checkOutDate}</div>
              </div>
              <div className="flex justify-between text-[17px] font-medium mt-4 mb-2">
                <div className="">No. of Adults</div>
                <div>{adults}</div>
              </div>
              <div className="flex justify-between text-[17px] font-medium my-1 border-b-[1px] pb-3 border-black">
                <div className="">No. of Children (6-12 yrs)</div>
                <div>{children}</div>
              </div>
            </div>
            <div className="my-2 pt-5 ">
              <div className="text-[25px] font-semibold font-serif ">
                Guest Details
              </div>
              <UserDetail
                formData={formData}
                setFormData={setFormData}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
              />
            </div>
            <div className="my-2 pt-5 font-serif">
              <div className="text-[25px] font-semibold ">
                Cancellation Policy
              </div>
              <div className="text-[18px] font-medium my-2">
                The booking can be cancelled for{" "}
                <span className="">{cancellation} </span>with no extra charge.{" "}
                <br />
                {/* To know more about our cancellation policy,{" "}
              <span className="text-blue-700 hover:underline hover:cursor-pointer">
                Click Here
              </span> */}
              </div>
            </div>
          </div>

          <div className="w-[45%] mt-6 px-[80px] items-center ">
            <PayNowCard
              villaName={villaName}
              price={price}
              villaCity={villaCity}
              villaLandmark={villaLandmark}
              thumbnail={thumbnail}
              prepayment={prepayment}
              taxFlag={taxFlag}
              buttonActive={buttonActive}
              showRazorpay={showRazorpay}
            />
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default PaymentPage;
