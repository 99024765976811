import React, { useState } from "react";

const questions = [
  {
    id: 1,
    question:
      "This is a sample FAQ question for testing purposes. It should have sufficient length right?",
    answer:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga.",
  },
  {
    id: 2,
    question:
      "This is a sample FAQ question for testing purposes. It should have sufficient length right? s is a sample FAQ question for testing purposes. It should have sufficient length ",
    answer:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga.",
  },
  {
    id: 3,
    question:
      "This is a sample FAQ question for testing purposes. It should have sufficient length right?",
    answer:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga.",
  },
  {
    id: 4,
    question:
      "This is a sample FAQ question for testing purposes. It should have sufficient length right?",
    answer:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga.",
  },
  {
    id: 5,
    question:
      "This is a sample FAQ question for testing purposes. It should have sufficient length right?",
    answer:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga.",
  },
  {
    id: 6,
    question:
      "This is a sample FAQ question for testing purposes. It should have sufficient length right?",
    answer:
      "Lorem ipsum dolor sit amet. Et voluptas earum est officiis velit est animi voluptatem. Ex culpa minima et eveniet dolorem et maxime esse id facilis animi. In dolorem consequatur At voluptatum dolore qui exercitationem nihil sit quibusdam porro quo consectetur fuga.",
  },
];

const FAQ = ({ villaData }) => {
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const updatedData = villaData.faq?.map((item, index) => ({ ...item, id: index }))
  const toggleQuestion = (questionId) => {
    if (questionId === activeQuestionId) {
      setActiveQuestionId(null);
    } else {
      setActiveQuestionId(questionId);
    }
  };

  return (
    <>
    {updatedData?(<div className="flex flex-col justify-center items-center font-jt cursor-pointer w-[88vw] mx-auto">
      <div className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8my-4 lg:m-8 font-jt ">
        FAQs
      </div>
      <div className="lg:px-5 lg:my-4 items-center flex flex-col">
        {updatedData?.map((q) => (
          <div
            className={`my-3 w-full lg:w-[85vw] rounded-xl py-6 pl-8 pr-4 ${
              q.id !== activeQuestionId ? "bg-[#EAEAEA] " : "bg-none border-2"
            }  `}
          >
            <div
              className=" flex flex-row justify-between items-center text-[16px]  lg:text-[22px] font-semibold "
              onClick={() => toggleQuestion(q.id)}
            >
              <div className="text-left">{q.question}</div>
              <div className="">
                {q.id === activeQuestionId && (
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 32 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="lg:scale-110"
                  >
                    <path
                      d="M31.3418 16.9629L31.166 16.7578L17.8848 1.47461C17.4355 0.957033 16.7617 0.634766 16.0098 0.634766C15.2578 0.634766 14.584 0.966799 14.1348 1.47461L0.863281 16.7285L0.638674 16.9824C0.472658 17.2266 0.375 17.5195 0.375 17.832C0.375 18.6816 1.09766 19.375 1.99609 19.375L30.0039 19.375C30.9023 19.375 31.625 18.6816 31.625 17.832C31.625 17.5098 31.5176 17.207 31.3418 16.9629Z"
                      fill="#2A2A2A"
                    />
                  </svg>
                )}
                {q.id !== activeQuestionId && (
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 32 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="lg:scale-110"
                  >
                    <path
                      d="M0.658203 3.03711L0.833984 3.24219L14.1152 18.5254C14.5645 19.043 15.2383 19.3652 15.9902 19.3652C16.7422 19.3652 17.416 19.0332 17.8652 18.5254L31.1367 3.27148L31.3613 3.01758C31.5273 2.77344 31.625 2.48047 31.625 2.16797C31.625 1.31836 30.9023 0.625 30.0039 0.625H1.99609C1.09766 0.625 0.375 1.31836 0.375 2.16797C0.375 2.49023 0.482422 2.79297 0.658203 3.03711Z"
                      fill="#2A2A2A"
                    />
                  </svg>
                )}
              </div>
            </div>
            {q.id === activeQuestionId && (
              <div className="text-left text-[17px] lg:text-[24px] mt-4 ">
                {q.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>):(<></>)}
    </>
  );
};

export default FAQ;
