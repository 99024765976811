import React from 'react';
import { Slider } from 'antd';
import "./Slider.css"

function PriceRangeSlider() {
    const steps = [0, 10, 25, 50, 100]; // Define your custom steps here
  
    const log = (value) => {
      //console.log(steps[value[0]], steps[value[1]]);
    };
  
    return (
      <div className='mt-20 w-[80vh] mx-auto'>
        <Slider 
          range 
          defaultValue={[1, 2]} 
          onAfterChange={log} 
          tooltipVisible
          max={steps.length - 1}
          tipFormatter={(value) => steps[value]}
        />
      </div>
    );
  }
  
  export default PriceRangeSlider;