import "./style.css";
import { enGB } from "date-fns/locale";
import { getDay } from 'date-fns'
import { useState } from "react";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import GuestPopup from "./Guest Popup";
import "./style.css";
import SignUpPopUp from "../Signup/Signup";
import LoginPopup from "../Login/Login";
import { useContext } from "react";
import { logOut } from "../../../authContext/apiCalls";
import { AuthContext } from "../../../authContext/AuthContext";
import { FiChevronDown } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { HiOutlinePhotograph } from "react-icons/hi";
import { BsGear } from "react-icons/bs";
import { FaBookmark, FaStar, FaWallet } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const cityOptions = [
  {
    id: 2,
    name: "Goa",
  },
  {
    id: 29,
    name: "Kodaikanal",
  },
  {
    id: 21,
    name: "Dhanaulti",
  },
  {
    id: 22,
    name: "Mount Abu",
  },
  {
    id: 3,
    name: "Lonavala",
  },
  {
    id: 4,
    name: "Manali",
  },
  {
    id: 6,
    name: "Khopoli",
  },
  {
    id: 7,
    name: "Mahabaleshwar",
  },
  {
    id: 8,
    name: "Panchgani",
  },
  {
    id: 9,
    name: "Alibag",
  },
  {
    id: 10,
    name: "Pune",
  },
  {
    id: 11,
    name: "Udaipur",
  },
  {
    id: 12,
    name: "Indore",
  },
  {
    id: 13,
    name: "Madikeri",
  },
  {
    id: 14,
    name: "Nashik",
  },
  {
    id: 30,
    name: "Ooty",
  },
  {
    id: 15,
    name: "Neemuch",
  },
  {
    id: 16,
    name: "Palgarh",
  },
  {
    id: 17,
    name: "Uttan",
  },
  {
    id: 24,
    name: "Kasauli",
  },
  {
    id: 20,
    name: "Kamshet",
  },
  {
    id: 25,
    name: "Jaipur",
  },
  {
    id: 31,
    name: "Bengaluru",
  },
  {
    id: 18,
    name: "Mussoorie",
  },
  {
    id: 26,
    name: "Dehradun",
  },
  {
    id: 27,
    name: "Shimla",
  },
  {
    id: 19,
    name: "Raipur",
  },
  {
    id: 23,
    name: "Kanatal",
  },
  {
    id: 5,
    name: "Karjat",
  },
  {
    id: 28,
    name: "Lansdowne",
  },
  {
    id: 1,
    name: "Igatpuri",
  },
];

const HeaderMobile = ({
  location,
  setLocation,
  adults,
  setAdults,
  children,
  setChildren,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSearchClick,
  check
}) => {
////
const navigate = useNavigate();
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const { user, dispatch } = useContext(AuthContext);
const handleLogout = () => {
  logOut(dispatch);
  setIsDropdownOpen(false);
  navigate.push("/");
};
const handleDropdownToggle = () => {
  setIsDropdownOpen(!isDropdownOpen);
};
/////////

  const [isOpen, setIsOpen] = useState(false);
  const [suggestion, setSuggestions] = useState([]);
  const [select, setSelect] = useState("");

  const handleChange = (e) => {
    let value = e.target.value;
    let matches = [];

    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = cityOptions.filter((item) => regex.test(item.name));
    }
    setSuggestions(matches);
    setLocation(value);
  };

  const selectValue = (item) => {
    setSelect(item);
    setSuggestions("");
    setLocation(item);
  };
  const [Loginpop,SetLoginPop]=useState(false)
  const [Registerpop,SetRegisterpop]=useState(false)

  const modifiers = {

    highlight: date => getDay(date) === 6 // Highlights Tuesdays
  }
  const modifiersClassNames = {
    highlight: '-highlight'
  }
  const customLocale = {
    ...enGB,
    options: {
      ...enGB.options,
      weekStartsOn: 0, // Set the week to start on Saturday
    }
  };

  return (
   <>
   {Loginpop &&(<LoginPopup SetLoginPop={SetLoginPop} Loginpop={Loginpop} Registerpop={Registerpop} SetRegisterpop={SetRegisterpop}/>)}
      {Registerpop &&(<SignUpPopUp Registerpop={Registerpop} SetRegisterpop={SetRegisterpop} SetLoginPop={SetLoginPop} Loginpop={Loginpop}/>)}
    <div className=" bg-white lg:hidden flex flex-col font-jt w-full">
      <GuestPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        adults={adults}
        setAdults={setAdults}
        children={children}
        setChildren={setChildren}
      />
      <div className="flex flex-row justify-between items-center mx-2 mt-2 mb-4 ">
        <div className="h-[20px] flex items-center justify-center">
        <Link to="/"> <img src="/logo-black.jpeg" className="h-[44px]"></img></Link>
        </div>
        <div className="flex items-center justify-center">
        {user ? (
            <div className="flex flex-row items-center">
              <div className="rounded-full bg-white w-10 h-10 flex items-center justify-center mr-2">
                <img
                  width="34"
                  height="34"
                  src="/user.png"
                  alt="user-male-circle"
                />
              </div>
              <div onClick={handleDropdownToggle} className="text-xl -ml-1 font-bold cursor-pointer" style={{ textTransform: "capitalize" }}>{user.first_name}</div>
              <button
                className="flex items-center focus:outline-none"
                onClick={handleDropdownToggle}
              >
                <FiChevronDown size={24} />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-2 top-14 z-[40000]   border-2 w-48 bg-white rounded-md shadow-lg py-2">
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <BsGear className="inline-block mr-2" size={16} />
                    Manage Account
                  </Link>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaBookmark className="inline-block mr-2" size={16} />
                    Bookings
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaWallet className="inline-block mr-2" size={16} />
                    Rewards and Wallet
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaStar className="inline-block mr-2" size={16} />
                    Reviews
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaBookmark className="inline-block mr-2" size={16} />
                    Saved
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    <IoMdLogOut className="inline-block mr-2" size={16} />
                    Sign Out
                  </a>
                </div>
              )}
            </div>
          ) : (<><button onClick={()=>{SetRegisterpop(!Registerpop)}} className="bg-white font-bold  text-black border-black border-2 w-[100px] h-[35px] rounded-full shadow-md">
            Register
          </button>
          <button onClick={()=>{SetLoginPop(!Loginpop)}} className="bg-black font-bold   ml-3 text-white  w-[100px] h-[35px] rounded-full  shadow-md">
            Sign in
          </button></>)}
        </div>
      </div>

      <div className="p-2 w-full  flex items-end">
        <div className="w-[7%] items-center text-center text-lg font-semibold my-auto ">
          <svg
            width="18"
            height="24"
            viewBox="0 0 30 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=""
          >
            <path
              d="M14.9999 0C6.7624 0 0.0874023 6.7125 0.0874023 15C0.0874023 29.325 14.9999 48 14.9999 48C14.9999 48 29.9124 29.325 29.9124 15C29.9124 6.7125 23.2374 0 14.9999 0ZM14.9999 22.275C11.0999 22.275 7.9499 19.1 7.9499 15.1875C7.9499 11.275 11.1124 8.0875 14.9999 8.0875C18.8999 8.0875 22.0499 11.2625 22.0499 15.1875C22.0499 19.1 18.8999 22.275 14.9999 22.275Z"
              fill="black"
            />
          </svg>
        </div>
        <div className=" relative shadow-md w-[80%] text-lg rounded-full bg-[#F4F4F4] p-2">
          <input
            type="text"
            placeholder={`Your Destination`}
            value={location}
            onChange={handleChange}
            style={{ textTransform: "capitalize" }}
            className="bg-transparent text-center placeholder-black font-semibold w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
          />
          {suggestion?.length > 0 ? (
            <div className="flex flex-row z-[3000] absolute top-[56px] lg:px-2 w-[85%]">
              <div className="bg-white border-[#E8E8E8] border-[1px] shadow-lg cursor-pointer rounded-lg  w-full">
                {suggestion.slice(0, 8).map((item) => {
                  return (
                    <div
                      className="tracking-wider suggestions text-center lg:px-[20px] mt-2 py-[1px] lg:py-2 text-[12px] lg:text-[18px] border-[#E8E8E8] border-b-[1px] font-semibold "
                      key={item.id}
                      onClick={() => selectValue(item.name)}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
        <div
          onClick={() => {
            if(check) handleSearchClick(true);
          }}
          className="  flex justify-center  w-[13%] text-lg mr-[2px] "
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 41 40"
            fill="none"
            className="my-auto mb-1  mx-1 lg:mx-0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.7067 35.8283L30.702 26.8236C32.8699 23.9376 34.0402 20.4246 34.0363 16.815C34.0363 7.59496 26.535 0.09375 17.315 0.09375C8.09496 0.09375 0.59375 7.59496 0.59375 16.815C0.59375 26.035 8.09496 33.5363 17.315 33.5363C20.9246 33.5402 24.4376 32.3699 27.3236 30.202L36.3283 39.2067C36.7842 39.6141 37.3787 39.8316 37.9898 39.8145C38.601 39.7974 39.1824 39.547 39.6147 39.1147C40.047 38.6824 40.2974 38.101 40.3145 37.4898C40.3316 36.8787 40.1141 36.2842 39.7067 35.8283ZM5.37125 16.815C5.37125 14.4527 6.07174 12.1435 7.38413 10.1794C8.69653 8.21527 10.5619 6.68441 12.7443 5.78041C14.9268 4.87642 17.3282 4.63989 19.6451 5.10075C21.962 5.5616 24.0901 6.69913 25.7605 8.36949C27.4309 10.0399 28.5684 12.168 29.0293 14.4849C29.4901 16.8018 29.2536 19.2032 28.3496 21.3857C27.4456 23.5681 25.9147 25.4335 23.9506 26.7459C21.9865 28.0583 19.6772 28.7587 17.315 28.7587C14.1485 28.7549 11.1128 27.4954 8.87369 25.2563C6.63462 23.0172 5.37505 19.9815 5.37125 16.815Z"
              fill="#020202"
            />
          </svg>
        </div>
      </div>
      <div className="p-2 mb-2 w-full justify-center flex items-center">
        <div className=" shadow-md w-[90%] text-lg flex flex-row rounded-full bg-white p-2">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            minimumDate={new Date()}
            minimumLength={1}
            format={"dd MMM"}
            modifiersClassNames={modifiersClassNames}
            modifiers={modifiers}
            locale={customLocale}
            firstDayOfWeek={7}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => (
              <div className="date-range grid grid-cols-2 gap-[2px] lg:gap-2 w-full">
                <div className="flex  flex-row items-center rounded-full  justify-center  relative">
                  <svg
                    className="lg:hidden block absolute left-[1px] ms:left-[6px] md:left-[8px] ls:left-[10px] lg:left-[12px] ll:left-4 "
                    width="16"
                    height="14"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z"
                      fill="#020202"
                    />
                  </svg>
                  <input
                    className={
                      "bg-transparent text-center  mt-[2px] text-[14px] ss:text-[12px] ms:text-[14px] lg:text-[18px] placeholder-black font-semibold w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none input" +
                      (focus === START_DATE ? " -focused" : "")
                    }
                    {...startDateInputProps}
                    placeholder="Check in"
                  />
                </div>
                <div className="flex flex-row items-center rounded-full  justify-center relative">
                  <svg
                    className="lg:hidden block absolute left-[1px] ms:left-[6px] md:left-[8px] ls:left-[10px] lg:left-[12px] ll:left-4 "
                    width="16"
                    height="14"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z"
                      fill="#020202"
                    />
                  </svg>

                  <input
                    className={
                      "bg-transparent text-center   mt-[2px] text-[14px] ss:text-[12px] ms:text-[14px] lg:text-[18px] placeholder-black font-semibold w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none input" +
                      (focus === END_DATE ? " -focused" : "")
                    }
                    {...endDateInputProps}
                    placeholder="Check out"
                  />
                </div>
              </div>
            )}
          </DateRangePicker>
          <div className="flex mr-1 flex-row justify-between items-center">
            <div className="">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ll:mx-2 mr-1 lg:hidden block"
              >
                <path
                  d="M16.5 14C15 14 13.8 14.4 12.9 14.9C14.3 16.1 14.9 17.5 15 17.6L15.1 17.8V20H23.1V18C23 18 21.4 14 16.5 14Z"
                  fill="#020202"
                />
                <path
                  d="M16.5 12C18.433 12 20 10.433 20 8.5C20 6.567 18.433 5 16.5 5C14.567 5 13 6.567 13 8.5C13 10.433 14.567 12 16.5 12Z"
                  fill="#020202"
                />
                <path
                  d="M4 8.5C4 6.6 5.6 5 7.5 5C9.4 5 11 6.6 11 8.5C11 10.4 9.4 12 7.5 12C5.6 12 4 10.4 4 8.5ZM7.5 14C2.6 14 1 18 1 18V20H14V18C14 18 12.4 14 7.5 14Z"
                  fill="#020202"
                />
              </svg>
            </div>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="text-[14px] ms:text-[15px] lg:text-[17px] text-center  font-bold "
            >
              {"Guests"}
            </button>
          </div>
        </div>
      </div>
    </div>
   </>
  );
};

export default HeaderMobile;
