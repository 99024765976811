function ReviewRating({ title, comments }) {
    return (
      <div className="h-full w-full max-w-[200px] bg-[#f5f1f1] rounded-md p-4">
        <h2 className="text-lg font-medium mb-2">{title}</h2>
        {comments.map((comment) => (
          <div key={comment.name} className="flex mb-4">
            <div className="bg-gray-300 w-fit h-fit px-2 py-1 flex items-center justify-center rounded-md aspect-ratio-1 mr-2">
              <p className="text-center">{comment.rating}</p>
            </div>
            <div>
              <p className="text-gray-500 mb-1">{comment.comment}</p>
              <p className="text-right text-gray-600">-{comment.name}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
export default ReviewRating