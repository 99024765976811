import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SignUpPopUp from "../Common/Signup/Signup";
import LoginPopup from "../Common/Login/Login";
import { useContext } from "react";
import { logOut } from "../../authContext/apiCalls";
import { AuthContext } from "../../authContext/AuthContext";
import { FiChevronDown } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { HiOutlinePhotograph } from "react-icons/hi";
import { BsGear } from "react-icons/bs";
import { FaBookmark, FaStar, FaWallet } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Header4 = () => {
  ////
const navigate = useNavigate();
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const { user, dispatch } = useContext(AuthContext);
const handleLogout = () => {
  logOut(dispatch);
  setIsDropdownOpen(false);
  
};
const handleDropdownToggle = () => {
  setIsDropdownOpen(!isDropdownOpen);
};
/////////


  const handleCartClick = () => {
    navigate("/coming-soon");
  };
  const [Loginpop,SetLoginPop]=useState(false)
  const [Registerpop,SetRegisterpop]=useState(false)
  return (
    <>
    {Loginpop &&(<LoginPopup SetLoginPop={SetLoginPop} Loginpop={Loginpop} Registerpop={Registerpop} SetRegisterpop={SetRegisterpop}/>)}
      {Registerpop &&(<SignUpPopUp Registerpop={Registerpop} SetRegisterpop={SetRegisterpop} SetLoginPop={SetLoginPop} Loginpop={Loginpop}/>)}
      <div className="bg-white font-jt flex justify-between items-center z-50 top-0 sticky px-8 ">
      <div className="h-[40px] flex justify-center items-center w-[15%]">
       <Link to="/"> <img src="/logo-black.jpeg" className="h-[60px]"></img></Link>
      </div>

        <div className=" flex justify-end flex-row py-6">
        {user ? (
            <div className="flex flex-row items-center">
              <div className="rounded-full bg-white w-10 h-10 flex items-center justify-center mr-2">
                <img
                  width="34"
                  height="34"
                  src="./user.png"
                  alt="user-male-circle"
                />
              </div>
              <div onClick={handleDropdownToggle} className="text-xl -ml-1 font-bold cursor-pointer" style={{ textTransform: "capitalize" }}>{user.first_name}</div>
              <button
                className="flex items-center focus:outline-none"
                onClick={handleDropdownToggle}
              >
                <FiChevronDown size={24} />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-2 top-14 z-[400000]   border-2 w-48 bg-white rounded-md shadow-lg py-2">
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <BsGear className="inline-block mr-2" size={16} />
                    Manage Account
                  </Link>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaBookmark className="inline-block mr-2" size={16} />
                    Bookings
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaWallet className="inline-block mr-2" size={16} />
                    Rewards and Wallet
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaStar className="inline-block mr-2" size={16} />
                    Reviews
                  </a>
                  <a
                    href="#"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    <FaBookmark className="inline-block mr-2" size={16} />
                    Saved
                  </a>
                  <a
                    
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    <IoMdLogOut className="inline-block mr-2" size={16} />
                    Sign Out
                  </a>
                </div>
              )}
            </div>
          ) : (<>
          <button onClick={()=>{SetRegisterpop(!Registerpop)}} className="bg-white font-bold  text-black border-black border-2 w-[100px] lg:w-[130px] h-[35px] rounded-full shadow-md">
            Register
          </button>
          <button onClick={()=>{SetLoginPop(!Loginpop)}} className="bg-black font-bold   ml-3 text-white w-[100px] lg:w-[130px] h-[35px] rounded-full  shadow-md">
            Sign in
          </button>
          </>)}
        </div>
      </div>
    </>
  );
};

export default Header4;
