import Carousel from "react-multi-carousel";
import NewCard from "./card";

const citydata = [
  {
    city: "Lonavala",
    quote: "Embrace the monsoons in this misty paradise.",
    imageLink: "./cities/lonavala.jpg",
  },
  {
    city: "Goa",
    quote: "Dive into vibrant nightlife and sun-kissed sandy beaches.",
    imageLink: "./cities/goa.jpg",
  },
  {
    city: "Alibag",
    quote: "Your ultimate coastal retreat for the perfect beach escape.",
    imageLink: "./cities/alibag.jpg",
  },
  {
    city: "Igatpuri",
    quote:
      "Rejuvenate amid rolling hills and waterfalls.",
    imageLink: "./cities/igatpuri.jpg",
  },
  {
    city: "Mahabaleshwar",
    quote: "Indulge in panoramic vistas and fresh strawberries.",
    imageLink: "./cities/mahabaleshwar.jpg",
  },
  {
    city: "Panchgani",
    quote: "Experience colonial charm amid breathtaking tablelands.",
    imageLink: "./cities/panchgani.jpg",
  },
  {
    city: "Nashik",
    quote: "Savour the flavours of the wine capital of India.",
    imageLink: "./cities/nashik.jpg",
  },
  {
    city: "Karjat",
    quote: "Where nature's beauty meets ancient architectural wonders.",
    imageLink: "./cities/karjat.jpg",
  },
  {
    city: "Udaipur",
    quote: "The City of Lakes, resonating with royal romance.",
    imageLink: "./cities/udaipur.jpg",
  },
  {
    city: "Manali",
    quote: "An enchanting blend of nature, tranquillity, and adventure.",
    imageLink: "./cities/manali.jpg",
  },
  {
    city: "Jaipur",
    quote: "The Pink City resonating with rich heritage.",
    imageLink: "./cities/jaipur.jpg",
  },
  {
    city: "Pune",
    quote: "The pulsating heart of Maratha culture and modernity.",
    imageLink: "./cities/pune.jpg",
  },
  {
    city: "Dehradun",
    quote: "Scenic Valley of Dreams, surrounded by mountains.",
    imageLink: "./cities/dehradun.jpg",
  },
  {
    city: "Khopoli",
    quote: "A delightful fusion of history and nature's wonders.",
    imageLink: "./cities/khopoli.jpg",
  },
  {
    city: "Mount Abu",
    quote: "A refreshing oasis of coolness in the desert.",
    imageLink: "./cities/mount abu.jpg",
  },
  {
    city: "Shimla",
    quote: "A timeless charm nestled in the summer capital of yesteryears.",
    imageLink: "./cities/shimla.jpg",
  },
  {
    city: "Ooty",
    quote: "The Queen of Hill Stations amid beautiful blue mountains.",
    imageLink: "./cities/ooty.jpg",
  },
  {
    city: "Kanatal",
    quote: "A hidden gem of tranquillity and picturesque landscapes.",
    imageLink: "./cities/kanatal.jpg",
  },
  {
    city: "Mussoorie",
    quote: "The Queen of Hills offering mesmerizing views.",
    imageLink: "./cities/mussoorie.jpg",
  },
];

const PopularDestination = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 456 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 456, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="w-[100%] lg:w-[90vw] mx-auto font-jt ">
      <h1 className="lg:my-12 my-8 text-[28px] sm:text-[32px] md:text-[36px] ls:text-[40px] lg:text-[44px]  ll:text-[48px] justify-center flex mx-auto items-center font-bold">
        Popular Destinations
      </h1>
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={4}
        swipeable
      >
        {citydata.map(({ city, quote, imageLink }) => (
          <div className="w-full flex justify-center">
            <NewCard quote={quote} city={city} image={imageLink} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default PopularDestination;
