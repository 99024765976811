import React from "react";
// import './Card.css';
// import Image from './image.png';
import { useNavigate } from 'react-router-dom';

const NewCard = ({quote,city,image}) => {
  const navigate = useNavigate();

  const handleClick = (city) => {
    const formattedCity = city.replace(/\s+/g, "-");
    navigate(`/search/${formattedCity}`);
  };
  return (
    <div className="relative flex flex-col justify-between items-center  font-hk bg-[#F4F4F4] border shadow-sm border-gray-300 rounded-2xl w-[180px] lg:w-[252px] h-fit lg:h-[330px] p-1">
      <img
        className="relative bg-cover bg-center rounded-xl min-h-[150px] min-w-[150px] lg:min-h-[180px] lg:min-w-[180px]"
        objectFit="cover"
        src={image}
        alt=""
      />

      <div className="flex flex-col justify-center items-center">
        <h2 className="text-black text-xl lg:text-2xl mt-1 lg:mt-2 text-center font-semibold">
          {city}
        </h2>
        <p className="text-black text-[12px] lg:text-[14px] text-center font-light mt-1 lg:mt-2  max-h-[100px]">
          {quote}{" "}
        </p>
        <button onClick={() => handleClick(city)}  className="bg-black text-[16px] shadow-lg text-white rounded-full w-[120px] h-[32px] lg:w-[138px]  lg:h-[39px] mt-1 lg:mt-2 mb-1 flex items-center justify-center gap-4">
          Explore
        </button>
      </div>
    </div>
  );
};

export default NewCard;
