import { useState, useEffect } from "react";
import BedroomPopup from "./Bedroom Popup";
import "./style.css"
import { useNavigate } from "react-router-dom";
const Layout = ({
  villaData,
  adults,
  setAdults,
  children,
  setChildren,
  endDate,
  startDate,
}) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(villaData.price_guest_pay);
  const [availability, setAvailability] = useState(true);
  const [extraPrice, setExtraPrice] = useState(0);
  const [pax, setPax] = useState(adults + Math.ceil(children / 2));
  const [DateNull, SetDateNull] = useState(false)
  const maxPax = villaData.number_of_adults + Math.ceil(villaData.number_of_children / 2);
  const includedPax =
    villaData.standard_occupany ? villaData.standard_occupany : maxPax;

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const data = [
    { bedroom: "Bedroom 1", beds: "1 double bed" },
    { bedroom: "Bedroom 2", beds: "2 double bed" },
    { bedroom: "Bedroom 3", beds: "1 double bed" },
    { bedroom: "Bedroom 4", beds: "1 double bed" },
    { bedroom: "Bedroom 5", beds: "1 double bed" },
    { bedroom: "Bedroom 6", beds: "1 twin bed" },
  ];
  const newArray = villaData.villarooms.map((room) => ({
    room_type: room.room_type,
    room_name: room.room_name,
    bed: room.villaroom_bed
      .filter((bed) => bed.quantity > 0)
      .map((bed) => ({
        quantity: bed.quantity,
        name: bed.name,
        size: bed.size,
      })),
  }));
  let roomOrder = ["BEDROOM", "LIVING ROOM", "DORMITORY", "OTHER SPACES"];

  newArray.sort(
    (a, b) => roomOrder.indexOf(a.room_type) - roomOrder.indexOf(b.room_type)
  );

  useEffect(() => {
    if (villaData && startDate && endDate) {
      let priceSum = 0;
      let flag = true;

      if (Object.prototype.toString.call(startDate) === "[object Date]" &&
        Object.prototype.toString.call(endDate) === "[object Date]") {

        for (
          let d = new Date(startDate);
          d < endDate;
          d.setDate(d.getDate() + 1)
        ) {
          let tempDate = d.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' }).slice(0, 10);
          let dateStr = tempDate;
          let [month, day, year] = dateStr.split("/");
          let formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
          //console.log(formattedDate, "tempdate")
          console.log(tempDate,"date")
          if (villaData.availability_per_date[formattedDate] == 0) flag = false;
          priceSum += parseInt(villaData.price_per_date[formattedDate]);
        }
      }
      console.log(flag,"flag");
      if (!priceSum || priceSum === 0) flag = false;
      setAvailability(flag);
      if (flag) setTotalPrice(priceSum);
      else setTotalPrice(villaData.price_guest_pay);
      //console.log(priceSum, "Total wala");
    }
  }, [villaData, startDate, endDate]);

  useEffect(() => {
    let days = 0; // default value in case of invalid dates

    if (Object.prototype.toString.call(startDate) === "[object Date]" &&
      Object.prototype.toString.call(endDate) === "[object Date]") { // check if dates are valid
      let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      days = Math.ceil(timeDiff / (1000 * 3600 * 24));
      SetDateNull(false)
    }
    else {
      SetDateNull(true)
    }
    // //console.log(Object.prototype.toString.call(startDate),"startdate")
    // const days = (endDate.getTime() - startDate.getTime()) / 86400000;
    setPax(adults + Math.ceil(children / 2));
    if (includedPax >= adults + Math.ceil(children / 2)) setExtraPrice(0);
    else {
      setExtraPrice(
        (adults + Math.ceil(children / 2) - includedPax) *
        villaData.price_per_extra_person *
        days
      );
    }

    //console.log(extraPrice, "EXTRAA", days);
  }, [adults, children, startDate, endDate]);

  //console.log(newArray, "data2");
  //console.log(villaData);

  const renderBedrooms = () => {
    const firstColumn = newArray.slice(0, Math.ceil(newArray.length / 2));
    const secondColumn = newArray.slice(Math.ceil(newArray.length / 2));

    return (
      <>
        <div className="flex">
          <div className="w-1/2 mx-auto ">
            <ul className="space-y-1">
              {firstColumn.map((item) => (
                <li
                  style={{ textTransform: "capitalize" }}
                  key={item.room_name}
                >
                  <strong>•{item.room_name}:</strong> {item.bed[0]?.quantity}{" "}
                  {item.bed[0]?.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="w-1/2 ">
            <ul className="space-y-1">
              {secondColumn.map((item) => (
                <li
                  style={{ textTransform: "capitalize" }}
                  key={item.room_name}
                >
                  <strong>•{item.room_name}:</strong> {item.bed[0]?.quantity}{" "}
                  {item.bed[0]?.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  };

  //BOOKING PAGE 4
  let thumbnailId = -1;
  if (villaData.main_media_id) thumbnailId = villaData.main_media_id;
  else if (villaData.media_order.length > 0)
    thumbnailId = villaData.media_order[0];
  else if (villaData.media.length > 0)
    thumbnailId = villaData.media[0].mediafiles_id;

  const thumbnail = villaData.media.filter((item) => {
    return thumbnailId === item.mediafiles_id;
  });


  const handleBookNow = () => {
    const bookingData = {
      adults: adults,
      children: children,
      price: totalPrice + extraPrice,
      checkIn: startDate,
      checkOut: endDate,
      villaName: villaData.listing_name,
      villa_lat: villaData.geo_lat,
      host_id: villaData.host_id,
      villa_long: villaData.geo_long,
      villaAddress: villaData.area,
      villaCity: villaData.name,
      villaLandmark: villaData.building,
      cancellationPolicy: villaData.cancellation_policy,
      checkInTime: villaData.check_in_start_time,
      checkOutTime: villaData.check_out_time,
      damageDeposit: villaData.damage_deposit,
      thumbnail: thumbnail[0],
      prepayment: villaData.advance_percent,
      taxFlag: villaData.is_tax_included,
      property_ptr_id: villaData.property_ptr_id,
      city_id:villaData.city_id
    };
    navigate("/payment", { state: bookingData });
  };




  // //console.log(newArray, "adsdsads");
  return (
    <>
      {isPopupOpen && (
        <BedroomPopup data={newArray} onClose={handleClosePopup} />
      )}
      <div>
        <div className="font-jt  my-auto flex flex-col lg:flex-row w-[97%] lg:w-[90vw] justify-between mx-auto">
          <div className="w-full lg:w-[80%]">
            <div className="bg-[#EAEAEA] md:w-full mx-auto font-jt rounded-3xl shadow-md mt-5 justify-between  flex flex-col md:flex-row">
              <div className="md:w-[40%] p-2 lg:p-6">
                <div className="text-[25px] lg:text-[30px]">Villa Type</div>

                <div className="grid grid-cols-3 gap-2 my-2 lg:my-5 space-x-2  ">
                  <div className="bg-white border-[#CACACA] p-1 lg:p-2 rounded-md shadow-md text-center">
                    <p className=" font-bold text-base lg:text-lg">
                      {villaData.size_of_property
                        ? villaData.size_of_property
                        : "N/A"}{" "}
                      Sq.ft{" "}
                    </p>
                    <p>Area</p>
                  </div>
                  <div className="bg-white border-[#CACACA] p-1 lg:p-2 rounded-md shadow-md text-center">
                    <p className=" font-bold text-base lg:text-lg">
                      {villaData.number_of_bedrooms
                        ? villaData.number_of_bedrooms
                        : "N/A"}
                    </p>
                    <p>BHK</p>
                  </div>
                  <div className="bg-white border-[#CACACA] p-1 lg:p-2 rounded-md shadow-md text-center">
                    <p className=" font-bold text-base lg:text-lg">{maxPax}</p>
                    <p>Max PAX</p>
                  </div>
                </div>
                <div>
                  <div className="bg-white border-[#CACACA] p-2 rounded-md shadow-md ">
                    <h2 className="text-xl lg:text-2xl mb-1  font-bold text-center">
                      {villaData.number_of_bedrooms} Bedroom
                    </h2>
                    {renderBedrooms()}
                    <div className="text-sm border-black text-purple-800 mt-1 text-center">
                      <button onClick={handleOpenPopup}>
                        Click to know more in detail
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:w-[35%] p-1 lg:p-6 flex flex-col justify-between">
                <div className="text-[24px] lg:text-[30px]">
                  Total Adults and Children{" "}
                </div>

                <div className="flex flex-row items-center justify-between   py-1 lg:py-3">
                  <div className="flex flex-row">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-2"
                    >
                      <path
                        d="M16.5 14C15 14 13.8 14.4 12.9 14.9C14.3 16.1 14.9 17.5 15 17.6L15.1 17.8V20H23.1V18C23 18 21.4 14 16.5 14Z"
                        fill="#020202"
                      />
                      <path
                        d="M16.5 12C18.433 12 20 10.433 20 8.5C20 6.567 18.433 5 16.5 5C14.567 5 13 6.567 13 8.5C13 10.433 14.567 12 16.5 12Z"
                        fill="#020202"
                      />
                      <path
                        d="M4 8.5C4 6.6 5.6 5 7.5 5C9.4 5 11 6.6 11 8.5C11 10.4 9.4 12 7.5 12C5.6 12 4 10.4 4 8.5ZM7.5 14C2.6 14 1 18 1 18V20H14V18C14 18 12.4 14 7.5 14Z"
                        fill="#020202"
                      />
                    </svg>
                    <div className="text-[20px] font-semibold"> Adults</div>
                  </div>

                  <div className="flex items-center mr-[8px]">
                    <button
                      onClick={() => {
                        if (adults > 0) setAdults(adults - 1);
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0C13.9711 0 18 4.02891 18 9C18 13.9711 13.9711 18 9 18C4.02891 18 0 13.9711 0 9C0 4.02891 4.02891 0 9 0ZM5.625 9.84375H12.375C12.8391 9.84375 13.2188 9.46406 13.2188 9C13.2188 8.53594 12.8391 8.15625 12.375 8.15625H5.625C5.16094 8.15625 4.78125 8.53594 4.78125 9C4.78125 9.46406 5.16094 9.84375 5.625 9.84375Z"
                          fill="#020202"
                        />
                      </svg>
                    </button>
                    <input
                      id="adults"
                      value={adults}
                      className="w-8 text-center text-lg appearance-none block  bg-transparent  border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                    />
                    <div
                      className={`${pax >= maxPax
                          ? "hover:cursor-not-allowed opacity-70"
                          : "hover:cursor-pointer"
                        }`}
                      onClick={() => {
                        if (pax < maxPax) setAdults(adults + 1);
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0.0625C7.23233 0.0625 5.50436 0.586675 4.0346 1.56874C2.56483 2.55081 1.41929 3.94665 0.742831 5.57977C0.0663725 7.21288 -0.11062 9.00992 0.234236 10.7436C0.579091 12.4773 1.43031 14.0698 2.68024 15.3198C3.93017 16.5697 5.52268 17.4209 7.25638 17.7658C8.99009 18.1106 10.7871 17.9336 12.4202 17.2572C14.0534 16.5807 15.4492 15.4352 16.4313 13.9654C17.4133 12.4956 17.9375 10.7677 17.9375 9C17.933 6.63102 16.9899 4.36037 15.3148 2.68525C13.6396 1.01013 11.369 0.0670426 9 0.0625ZM12.4375 9.6875H9.6875V12.4375C9.6875 12.6198 9.61507 12.7947 9.48614 12.9236C9.35721 13.0526 9.18234 13.125 9 13.125C8.81767 13.125 8.6428 13.0526 8.51387 12.9236C8.38494 12.7947 8.3125 12.6198 8.3125 12.4375V9.6875H5.5625C5.38017 9.6875 5.2053 9.61507 5.07637 9.48614C4.94744 9.35721 4.875 9.18234 4.875 9C4.875 8.81766 4.94744 8.6428 5.07637 8.51386C5.2053 8.38493 5.38017 8.3125 5.5625 8.3125H8.3125V5.5625C8.3125 5.38016 8.38494 5.2053 8.51387 5.07636C8.6428 4.94743 8.81767 4.875 9 4.875C9.18234 4.875 9.35721 4.94743 9.48614 5.07636C9.61507 5.2053 9.6875 5.38016 9.6875 5.5625V8.3125H12.4375C12.6198 8.3125 12.7947 8.38493 12.9236 8.51386C13.0526 8.6428 13.125 8.81766 13.125 9C13.125 9.18234 13.0526 9.35721 12.9236 9.48614C12.7947 9.61507 12.6198 9.6875 12.4375 9.6875Z"
                          fill="#020202"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between  py-3">
                  <div className="flex flex-row">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-2"
                    >
                      <path
                        d="M16.5 14C15 14 13.8 14.4 12.9 14.9C14.3 16.1 14.9 17.5 15 17.6L15.1 17.8V20H23.1V18C23 18 21.4 14 16.5 14Z"
                        fill="#020202"
                      />
                      <path
                        d="M16.5 12C18.433 12 20 10.433 20 8.5C20 6.567 18.433 5 16.5 5C14.567 5 13 6.567 13 8.5C13 10.433 14.567 12 16.5 12Z"
                        fill="#020202"
                      />
                      <path
                        d="M4 8.5C4 6.6 5.6 5 7.5 5C9.4 5 11 6.6 11 8.5C11 10.4 9.4 12 7.5 12C5.6 12 4 10.4 4 8.5ZM7.5 14C2.6 14 1 18 1 18V20H14V18C14 18 12.4 14 7.5 14Z"
                        fill="#020202"
                      />
                    </svg>
                    <div className="text-[20px] font-semibold">Children</div>
                  </div>

                  <div className="flex items-center mr-[8px]">
                    <button
                      onClick={() => {
                        if (children > 0) setChildren(children - 1);
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0C13.9711 0 18 4.02891 18 9C18 13.9711 13.9711 18 9 18C4.02891 18 0 13.9711 0 9C0 4.02891 4.02891 0 9 0ZM5.625 9.84375H12.375C12.8391 9.84375 13.2188 9.46406 13.2188 9C13.2188 8.53594 12.8391 8.15625 12.375 8.15625H5.625C5.16094 8.15625 4.78125 8.53594 4.78125 9C4.78125 9.46406 5.16094 9.84375 5.625 9.84375Z"
                          fill="#020202"
                        />
                      </svg>
                    </button>
                    <input
                      id="children"
                      value={children}
                      className="w-8 text-center text-lg appearance-none block  bg-transparent  border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                    />
                    <button
                      className={`${pax >= maxPax
                          ? "hover:cursor-not-allowed opacity-70"
                          : "hover:cursor-pointer"
                        }`}
                      onClick={() => {
                        if (pax < maxPax) setChildren(children + 1);
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0.0625C7.23233 0.0625 5.50436 0.586675 4.0346 1.56874C2.56483 2.55081 1.41929 3.94665 0.742831 5.57977C0.0663725 7.21288 -0.11062 9.00992 0.234236 10.7436C0.579091 12.4773 1.43031 14.0698 2.68024 15.3198C3.93017 16.5697 5.52268 17.4209 7.25638 17.7658C8.99009 18.1106 10.7871 17.9336 12.4202 17.2572C14.0534 16.5807 15.4492 15.4352 16.4313 13.9654C17.4133 12.4956 17.9375 10.7677 17.9375 9C17.933 6.63102 16.9899 4.36037 15.3148 2.68525C13.6396 1.01013 11.369 0.0670426 9 0.0625ZM12.4375 9.6875H9.6875V12.4375C9.6875 12.6198 9.61507 12.7947 9.48614 12.9236C9.35721 13.0526 9.18234 13.125 9 13.125C8.81767 13.125 8.6428 13.0526 8.51387 12.9236C8.38494 12.7947 8.3125 12.6198 8.3125 12.4375V9.6875H5.5625C5.38017 9.6875 5.2053 9.61507 5.07637 9.48614C4.94744 9.35721 4.875 9.18234 4.875 9C4.875 8.81766 4.94744 8.6428 5.07637 8.51386C5.2053 8.38493 5.38017 8.3125 5.5625 8.3125H8.3125V5.5625C8.3125 5.38016 8.38494 5.2053 8.51387 5.07636C8.6428 4.94743 8.81767 4.875 9 4.875C9.18234 4.875 9.35721 4.94743 9.48614 5.07636C9.61507 5.2053 9.6875 5.38016 9.6875 5.5625V8.3125H12.4375C12.6198 8.3125 12.7947 8.38493 12.9236 8.51386C13.0526 8.6428 13.125 8.81766 13.125 9C13.125 9.18234 13.0526 9.35721 12.9236 9.48614C12.7947 9.61507 12.6198 9.6875 12.4375 9.6875Z"
                          fill="#020202"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="text-sm text-center text-gray-500 mb-2 ">
                  Price per extra Person: ₹{villaData.price_per_extra_person}
                  /-(For PAX above{" "}
                  {villaData.standard_occupany
                    ? villaData.standard_occupany
                    : villaData.number_of_adults +
                    Math.ceil(villaData.number_of_children / 2)}
                  )
                </div>
                {!availability && (
                  <div className="text-red-600 lg:hidden shake-shake text-[15px] font-semibold  flex justify-center text-center  mt-1 ">
                    Villa Not Available in the given date range!
                  </div>
                )}
                {pax > maxPax && (
                  <div className="text-red-600 lg:hidden shake-shake text-[15px] font-semibold flex justify-center text-center  mt-1 ">
                    Max capacity of villa exceeded! Please reduce the PAX.
                  </div>
                )}
                {DateNull && (
                  <div className="text-red-600 lg:hidden shake-shake text-[15px] font-semibold flex justify-center text-center  mt-1 ">
                    Please Select Dates to Adjust Prices
                  </div>
                )}
                <div className="items-center text-center">
                  <svg
                    className="mb-2 hidden lg:block"
                    width="500"
                    height="1"
                    viewBox="0 0 500 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line y1="0.5" x2="448.001" y2="0.5" stroke="#C6C6C6" />
                  </svg>
                  <div className="bg-white text-[20px] lg:text-[25px] border-[#CACACA] shadow-md font-semibold px-4 py-1 lg:py-2 rounded flex flex-row justify-between">
                    <div>Total Cummulative Pax</div>
                    <div>{pax}</div>
                  </div>
                </div>
              </div>

              <div className="md:w-[25%] rounded-b-3xl lg:rounded-bl-none lg:rounded-r-3xl    bg-[#2A2A2B] text-white flex flex-col justify-between p-1 lg:p-2">
                <div className=" text-[22px] lg:text-[30px] px-1 lg:p-6">
                  Price
                </div>
                <div className="h-full -mt-1 lg:mt-2 flex flex-col ">
                  <div className="flex justify-between space-x-1 px-2">
                    <div className=" hidden lg:flex flex-row">
                      <svg
                        width="65.449"
                        height="90"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.70096 0.700317C0.87253 0.700317 0.200958 1.37189 0.200958 2.20032V16.051C0.200958 16.5779 0.477406 17.0662 0.929214 17.3372L6.92921 20.9372C7.40423 21.2222 7.99767 21.2222 8.47269 20.9372L14.4727 17.3372C14.9245 17.0662 15.2009 16.5779 15.2009 16.051V2.20032C15.2009 1.37189 14.5294 0.700317 13.7009 0.700317H1.70096ZM1.70059 2.80039C2.03196 2.80039 2.30058 2.53176 2.30058 2.20039C2.30058 1.86902 2.03196 1.60039 1.70059 1.60039C1.36921 1.60039 1.10059 1.86902 1.10059 2.20039C1.10059 2.53176 1.36921 2.80039 1.70059 2.80039Z"
                          fill="white"
                        />
                      </svg>
                      <div className="absolute   mt-5 text-[#5C18CC]">
                        <p className="ml-3 text-2xl -mt-1 font-bold">12%</p>
                        <p className="text-center text-lg ml-1 -mt-1 font-bold">
                          off
                        </p>
                      </div>
                    </div>
                    <div className=" lg:hidden flex flex-row">
                      <svg
                        width="43.632"
                        height="60"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.70096 0.700317C0.87253 0.700317 0.200958 1.37189 0.200958 2.20032V16.051C0.200958 16.5779 0.477406 17.0662 0.929214 17.3372L6.92921 20.9372C7.40423 21.2222 7.99767 21.2222 8.47269 20.9372L14.4727 17.3372C14.9245 17.0662 15.2009 16.5779 15.2009 16.051V2.20032C15.2009 1.37189 14.5294 0.700317 13.7009 0.700317H1.70096ZM1.70059 2.80039C2.03196 2.80039 2.30058 2.53176 2.30058 2.20039C2.30058 1.86902 2.03196 1.60039 1.70059 1.60039C1.36921 1.60039 1.10059 1.86902 1.10059 2.20039C1.10059 2.53176 1.36921 2.80039 1.70059 2.80039Z"
                          fill="white"
                        />
                      </svg>
                      <div className="absolute   mt-5 text-[#5C18CC]">
                        <p className="ml-2 text-base lg:text-2xl -mt-3 font-bold">
                          12%
                        </p>
                        <p className="text-center text-base lg:text-lg ml-1 -mt-1 font-bold">
                          off
                        </p>
                      </div>
                    </div>
                    <div>
                      {availability && (
                        <div className="text-end  ">
                          {" "}
                          <div className="text-sm lg:text-2xl" >
                            <s>
                              ₹{Math.floor(1.12 * (totalPrice + extraPrice))}
                            </s>
                          </div>
                          <div className="text-3xl -mt-1 lg:-mt-0 lg:text-5xl">
                            ₹{totalPrice + extraPrice}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex -mt-3 lg:-mt-0 text-[12px] lg:text-base text-end pr-2 space-y-1 flex-col">
                    {/* <div className="mb-1">Total</div> */}
                    <div>{true ? "+₹0" : "+₹250"} TAX </div>
                  </div>
                </div>

                <div className="hidden lg:flex flex-col h-5/12">
                  <div className="text-[13px] underline text-end mr-2 lg:mr-0 lg:text-center">
                    Explore Prepayment Policy
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[20%]  flex flex-row lg:flex-col justify-center items-end">
            <div className="lg:flex-col flex-row flex w-full mt-4 lg:mt-0 lg:w-[200px]">
              <div className="w-full">
                <button
                  onClick={() => {
                    if (availability && startDate && villaData.status =='OPEN/BOOKABLE' && endDate) handleBookNow();
                  }}
                  className={`w-full text-[25px] text-center bg-black border-black border-2 mb-2 text-white px-4 lg:px-10 p-2 rounded-full ${availability && villaData.status =='OPEN/BOOKABLE' && !DateNull ? "" : "opacity-[70%] hover:cursor-not-allowed"
                    }`}
                >
                  Book Now
                </button>
              </div>
              <div className="w-full">
                <button
                  className={`w-full text-center text-[25px] bg-white border-2 border-black text-black px-5 p-2 rounded-full ${availability ? "" : "opacity-[70%] hover:cursor-not-allowed"
                    }`}
                >
                  Add to Cart
                </button>
              </div>
            </div>
            {villaData.status !='OPEN/BOOKABLE' && (
              <div className="text-red-600 text-[16px]  font-semibold hidden lg:flex justify-center text-right  mt-5 ">
                Villa Not Available for Booking
              </div>
            )}
            {!availability && (
              <div className="text-red-600 text-[16px]  font-semibold hidden lg:flex justify-center text-right  mt-5 ">
                Villa Not Available in the given date range!
              </div>
            )}
            {pax > maxPax && (
              <div className="text-red-600 text-[16px] font-semibold hidden lg:flex justify-center text-right  mt-5 ">
                Max capacity of villa exceeded! Please reduce the PAX.
              </div>
            )}
            {DateNull && (
              <div className="text-red-600 text-[16px] font-semibold hidden lg:flex justify-center text-right  mt-5 ">
                Please Select Dates to Adjust Prices
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
