import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

const StarRating = () => {
  const [rating, setRating] = useState(0);

  return (
    <div>
      {[...Array(10)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <label key={i}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => setRating(ratingValue)}
              style={{ display: 'none' }}
            />
            <FontAwesomeIcon
              icon={ratingValue <= rating ? faStarSolid : faStarRegular}
              color={ratingValue <= rating ? '#FCCC24' : 'white'}
              size="2x"
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
