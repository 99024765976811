function CheckInOutTimes({ checkInTime, checkOutTime }) {
    return (
      <div className="flex justify-between w-full h-1/3 bg-[#f5f1f1] rounded-md p-4">
        <div className="mr-4">
          <h2 className="text-xl font-medium mb-2">Check in</h2>
          <p className="text-gray-500">{checkInTime}</p>
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-medium mb-2">Check out</h2>
          <p className="text-gray-500">{checkOutTime}</p>
        </div>
      </div>
    );
  }
  

export default CheckInOutTimes;