import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button1 from "../Buttons/Button1";

const BasicHeader = () => {
  const navigate = useNavigate();

  const handleCartClick = () => {
    navigate("/coming-soon");
  };

  return (
    <>
      <div className="bg-white flex justify-between shadow-shadowColor shadow-md z-50 top-0 sticky ">
        <div className=" ml-10">
          <img
            src="/logo.png"
            className="h-[5vw] hover:cursor-pointer "
            onClick={() => {
              navigate("/");
            }}
          ></img>
        </div>
        <div className="flex flex-row  justify-center mt-4  mr-10">
          <div
            className="mr-5 hover:cursor-pointer scale-[0.9] "
            onClick={() => {
              handleCartClick();
            }}
          >
            <img src="/cart.svg"></img>
          </div>
          <div className="ml-4">
            <Link to="/signup" className="mr-5 scale-[0.8] md:scale-90">
              <Button1 value="Login/Signup" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicHeader;
