import axios from "axios";
import React, { useState } from "react";
// import { server } from "./server";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
function RazorPayTesting() {
  const [name, setName] = useState("rishabh");
  const [amount, setAmount] = useState("100");
  
  
// this function will handel payment when user submit his/her money
// and it will confim if payment is successfull or not
  const handlePaymentSuccess = async (response) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      // bodyData.append("response", JSON.stringify(response));
      //console.log(response,"response")
      await axios.post(
        `${process.env.REACT_APP_API_AWS}/payment-success/`,{response})
        .then((res) => {
          //console.log("Everything is OK!");
          setName("");
          setAmount("");
        })
        .catch((err) => {
          //console.log(err);
        });
    } catch (error) {
      //console.log(console.error());
    }
  };

  // this will load a script tag which will open up Razorpay payment card to make //transactions
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const showRazorpay = async () => {
    const res = await loadScript();

    // let bodyData = new FormData();

    // // we will pass the amount and product name to the backend using form data
    // bodyData.append("amount", amount.toString());
    // bodyData.append("name", name);
    // let bodyData = {
    //     amount: amount.toString(),
    //     name: name
    //   }
    // const csrftoken = getCookie('csrftoken');
    // //console.log(name,amount,bodyData,csrftoken)
    // 
    const data = await axios.post(`${process.env.REACT_APP_API_AWS}/pay/`,{name,amount})
    //console.log(data,"first response data")
    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user
    
    var options = {
      key_id: process.env.REACT_APP_PUBLIC_KEY, // in react your environment variable must start with REACT_APP_
      key_secret: process.env.REACT_APP_SECRET_KEY,
      amount: data.data.payment.amount,
      currency: "INR",
      name: "A2Z Villaz",
      description: "Test teansaction",
      image: "./Logopay.png", // add image url
      order_id: data.data.payment.id,
      handler: function (response) {
        // we will handle success by calling handlePaymentSuccess method and
        // will pass the response that we've got from razorpay
        handlePaymentSuccess(response);
      },
      prefill: {
        name: "User's name",
        email: "User's email",
        contact: "User's phone",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  //console.log(process.env)

  return (
    <div className="container mx-auto mt-20">
    <form>
      <h1 className="text-xl font-bold mb-8">Payment page</h1>

      <div className="mb-4">
        <label htmlFor="name" className="block font-medium mb-2">
          Product name
        </label>
        <input
          type="text"
          className="px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 w-full"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="amount" className="block font-medium mb-2">
          Amount
        </label>
        <input
          type="text"
          className="px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 w-full"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
    </form>
    <button
      onClick={showRazorpay}
      className="bg-blue-500 text-white px-4 py-3 rounded-lg shadow-sm hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-600"
    >
      Pay with razorpay
    </button>
  </div>
  );
}

export default RazorPayTesting;
