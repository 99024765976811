import React from "react";
import { Link } from "react-scroll";

const Navigation = () => {
    const sectionNames = ["Amenities", "Layout", "Food", "Nearby", "Rules & Policies", "Reviews", "FAQs", "Similar Villas"];
    // "Rules & Policies", "Reviews", "FAQs", "Similar Villas"
    const sectionNamesMobile = ["Amenities", "Layout", "Food", "Nearby", "More"];

    return (
        <div className="font-jt text-[20px]  sticky top-0 z-[3000]  my-5 bg-white items-center  lg:mx-2">
            <div className="hidden lg:flex  flex-row justify-center ">
                {sectionNames.map((sectionName, index) => (
                    <Link
                        className="px-1 lg:mx-0 w-1/3 text-center py-2  border-b-4 hover:cursor-pointer font-medium text-black border-[#F1F1F1]"
                        activeClass="bg-[#F1F1F1] font-bold text-black border-b-black"
                        to={sectionName}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        key={index}
                    >
                        {sectionName}
                    </Link>
                ))}
            </div>
            <div className="lg:hidden flex flex-row justify-center ">
                {sectionNamesMobile.map((sectionName, index) => (
                    <Link
                        className="px-2  lg:mx-0 w-1/3 text-center py-2 text-sm  border-b-4 hover:cursor-pointer font-medium text-black border-[#F1F1F1]"
                        activeClass="bg-[#F1F1F1] font-semibold text-base text-black border-b-black"
                        to={sectionName}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        key={index}
                    >
                        {sectionName}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Navigation;
