import React from "react";
import "./notfound.css"
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <section className="page_404 font-jt">
  <div className="container">
    <div className="row">	
      <div className="col-sm-12 ">
        <div className="col-sm-10 col-sm-offset-1  text-center">
          <div className="four_zero_four_bg">
          </div>
          <div className="contant_box_404">
            <h3 className="h2">
              Look like you're lost
            </h3>
            <p>the page you are looking for not avaible!</p>
            
            <Link to="/"><a href className="link_404">Go to Home</a></Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  
    )
};

export default NotFound;
