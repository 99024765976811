import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Header4 from "../../Page-4/Header4";

const UserProfile = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const userObject = JSON.parse(localStorage.getItem("user"));
  const token = userObject.access;
  const [update,setUpdate]=useState(false)
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_AWS}/user_profile_update/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate, token]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone_number: Yup.string()
      .required("Required")
      .matches(/^\+?\d+$/, "Phone number is not valid"),
  });

  return (
    <><Header4/>
    <div className="font-jt w-[95%]  lg:w-[80vw]  mx-auto mt-8">
        <Link to="/">
        <div className="flex flex-row items-center border-2 rounded-2xl border-black w-fit px-2"><img src="./back.png" alt="" className="w-7 h-10 " />
    <div className="text-start text-base "> Go Back</div></div>
        </Link>
    
      <div className="text-center text-5xl">User Profile Page</div>
      <Formik
        initialValues={{
          first_name: userObject?.first_name || "",
          last_name: userObject?.last_name || "",
          email: userObject?.email || "",
          phone_number: userObject?.phone_number || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .patch(
              `${process.env.REACT_APP_API_AWS}/user_profile_update/`,
              values,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              //console.log("Profile updated");
              setUserData(response.data);
              const updatedUserObject = { ...userObject, ...response.data };
            localStorage.setItem('user', JSON.stringify(updatedUserObject));
            setUpdate(true)
              setSubmitting(false);
            })
            .catch((error) => {
              console.error("Error updating profile: ", error);
              if (error.response && error.response.status === 401) {
                navigate("/login");
              }
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-4">
            <div>
              <label htmlFor="first_name">First Name</label>
              <Field
                name="first_name"
                type="text"
                className="w-full border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <ErrorMessage name="first_name" component="div" />
            </div>
            <div>
              <label htmlFor="last_name">Last Name</label>
              <Field
                name="last_name"
                type="text"
                className="w-full border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <ErrorMessage name="last_name" component="div" />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <Field
                name="email"
                type="email"
                className="w-full border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <ErrorMessage name="email" component="div" />
            </div>
            <div>
              <label htmlFor="phone_number">Phone Number</label>
              <Field
                name="phone_number"
                type="text"
                className="w-full border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <ErrorMessage name="phone_number" component="div" />
            </div>
            {update && (<div className="text-red-500 text-lg font-semibold" >
                Profile Updated
            </div>)}
            <div>
              <button
                type="submit"
                className="inline-flex justify-center text-xl py-2 px-4 bg-black text-white rounded-full "
                disabled={isSubmitting}
              >
                {isSubmitting?"Updating":"Update"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div></>
  );
};

export default UserProfile;
