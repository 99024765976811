import Carousel from "react-multi-carousel";
import SimilarVillas from "./Card";

const citydata=[
  { city: 'Lonavala', quote: 'Embrace the monsoons in this misty paradise.', imageLink: './cities/lonavala.jpg' },
  { city: 'Goa', quote: 'Dive into vibrant nightlife and sun-kissed sandy beaches.', imageLink: './cities/goa.jpg' },
  { city: 'Alibag', quote: 'Your ultimate coastal retreat for the perfect beach escape.', imageLink: './cities/alibag.jpg' },
  { city: 'Igatpuri', quote: 'Escape to rejuvenation amidst rolling hills and cascading waterfalls.', imageLink: './cities/igatpuri.jpg' },
  { city: 'Mahabaleshwar', quote: 'Indulge in panoramic vistas and fresh strawberries.', imageLink: './cities/mahabaleshwar.jpg' },
  { city: 'Panchgani', quote: 'Experience colonial charm amid breathtaking tablelands.', imageLink: './cities/panchgani.jpg' },
  { city: 'Nashik', quote: 'Savour the flavours of the wine capital of India.', imageLink: './cities/nashik.jpg' },
  { city: 'Karjat', quote: "Where nature's beauty meets ancient architectural wonders.", imageLink: './cities/karjat.jpg' },
  { city: 'Udaipur', quote: 'The City of Lakes, resonating with royal romance.', imageLink: './cities/udaipur.jpg' },
  { city: 'Manali', quote: 'An enchanting blend of nature, tranquillity, and adventure.', imageLink: './cities/manali.jpg' },
  { city: 'Jaipur', quote: 'The Pink City resonating with rich heritage.', imageLink: './cities/jaipur.jpg' },
  { city: 'Pune', quote: 'The pulsating heart of Maratha culture and modernity.', imageLink: './cities/pune.jpg' },
  { city: 'Dehradun', quote: 'The picturesque Valley of Dreams embraced by mountains.', imageLink: './cities/dehradun.jpg' },
  { city: 'Khopoli', quote: "A delightful fusion of history and nature's wonders.", imageLink: './cities/khopoli.jpg' },
  { city: 'Mount Abu', quote: 'A refreshing oasis of coolness in the desert.', imageLink: './cities/mount abu.jpg' },
  { city: 'Shimla', quote: 'A timeless charm nestled in the summer capital of yesteryears.', imageLink: './cities/shimla.jpg' },
  { city: 'Ooty', quote: 'The Queen of Hill Stations amid beautiful blue mountains.', imageLink: './cities/ooty.jpg' },
  { city: 'Kanatal', quote: 'A hidden gem of tranquillity and picturesque landscapes.', imageLink: './cities/kanatal.jpg' },
  { city: 'Mussoorie', quote: 'The Queen of Hills offering mesmerizing views.', imageLink: './cities/mussoorie.jpg' }
]



const SimilarVillasCrousel = ({villaData,adults,children,formData,startDate,endDate,location}) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
   villaData.landmark.length>0?( <div className="w-[95vw] mx-auto font-jt mb-8 lg:mb-0">
   <h1 className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8my-4 lg:m-8 font-jt">
   Similar Villas near this Landmark
   </h1>
   <Carousel
     responsive={responsive}
     removeArrowOnDeviceType={["tablet", "mobile"]}
     additionalTransfrom={0}
     arrows
     autoPlay
     autoPlaySpeed={3000}
     centerMode={false}
     className=""
     containerClass="container-with-dots"
     dotListClass=""
     draggable
     focusOnSelect={false}
     infinite
     itemClass=""
     keyBoardControl
     minimumTouchDrag={80}
     pauseOnHover
     renderArrowsWhenDisabled={false}
     renderButtonGroupOutside={false}
     renderDotsOutside={false}
     rewind={false}
     rewindWithAnimation={false}
     rtl={false}
     shouldResetAutoplay
     showDots={false}
     sliderClass=""
     slidesToSlide={4}
     swipeable
   >
     {villaData?.landmark[0]?.villas_with_same_landmark?.map((a,i)=>(
           <div className="w-full flex justify-center">
           <SimilarVillas adults={adults}
           children={children}
           startDate={startDate}
           endDate={endDate} formData={formData} location={location} i={i} data={a} landmark={villaData?.landmark[0]?.landmark?.title}/>
         </div>
     ))}
       
     
       
   </Carousel>
 </div>):(<></>)
  );
};
export default SimilarVillasCrousel;
