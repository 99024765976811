import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';

const ThankYouPage = () => {
    


   
    return (
        <div className="font-jt min-h-screen bg-gray-100 flex flex-col justify-center items-center">
            <div className="bg-white shadow-lg rounded-lg p-2 py-10 md:p-20 mx-5 md:mx-0">
                <h1 className="text-3xl md:text-5xl font-bold text-center mb-5">Thank You for Your Booking at A2Z Villaz!</h1>
                <p className="text-center text-xl text-gray-700 mb-10">
                A confirmation email, which includes all the details of your booking, has been sent to your email address.
                </p>
                <img className='h-60 mx-auto' src="./thankyou.gif" />
                <div className="flex justify-center">
                <Link to="/"> 
                    <button className="bg-black text-white font-bold py-2 px-4  rounded-full">
                        Go to Homepage
                    </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ThankYouPage;
