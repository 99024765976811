import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
});

const LoginSignupForm = () => {
  const [isOpen, setIsOpen] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="font-hk min-h-screen bg-gray-100 flex items-center justify-center">
      <button
        className="bg-white px-6 py-2 rounded font-bold"
        onClick={openModal}
      >
        Login / Signup
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="font-hk  bg-white rounded p-8 shadow-xl"
      >
        <h1 className="text-2xl font-bold mb-6">Login / Signup</h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              //console.log(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="email"
                  className="text-red-600 text-sm"
                  component="div"
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
                <ErrorMessage
                  name="password"
                  className="text-red-600 text-sm"
                  component="div"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <button className="text-gray-400 mt-4" onClick={closeModal}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default LoginSignupForm;
