import React from 'react';

const PrivacyPolicy = () => (<div className="w-full h-full bg-white p-8 font-jt">
 <h2 className="text-2xl font-bold mb-6">What is the A2Z Villaz Privacy policy?</h2>
 <div>Last Updated:26th October 2017</div>

We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.


By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our platform.

By use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.

<h1 className='text-2xl font-sem-bold my-5'>1.	Collection of Personally Identifiable Information and other Information</h1>

When you use our Website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, eﬃcient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our platform to make your experience safer and easier.

By nature weblogs collect data from your device while you are visiting our website or our platform. The weblogs gather information that such as your location, your machine/device, your browsing pattern among other things. A2Z Villaz collects and analyzes this information for enhanced user experience.

Our platform and website uses "cookies" on certain pages of the Website to help analyse our web page flow, measure promotional eﬀectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. We oﬀer certain features that are only available through the use of a "cookie".

A2Z Villaz also uses third party analytics engine that can gather information about your online behavior. We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are
 
always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session.

Additionally, you may encounter "cookies" or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.

If you complete a transaction on our site, we collect all the required information to complete the transaction, this may include demographic and transactional information. We may use this information to provide you a better user experience in the future.

If you transact with us, we collect some additional information, such as a billing address, a credit / debit card number and a credit / debit card expiration date and/ or other payment instrument details and tracking information from cheques or money orders. This information is stored in a secure manner with our payment processing company.

If you choose to post messages on our properties, chat rooms or other message areas or leave feedback, that information is available to anyone on the internet.

If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we may collect such information into a file specific to you.

We collect personally identifiable information (email address, name, phone number, credit card / debit card / other payment instrument details, etc.) from you when you set up a free account with us. While you can browse some sections of our Website without being a registered member, certain activities (such as placing an order) do require registration. We do use your contact information to send you oﬀers based on your previous orders and your interests.

<h1 className='text-2xl font-sem-bold my-5'>2. Security Precautions</h1>

Our Website has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we oﬀer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.
 
<h1 className='text-2xl font-sem-bold my-5'>3. Choice/Opt-Out</h1>

We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us via email or SMS on behalf of our partners, and from us in general, after setting up an account.

If you want to remove your contact information from all A2Z Villaz lists and newsletters, please visit unsubscribe

<h1 className='text-2xl font-sem-bold my-5'>4. Advertisements on A2Z Villaz</h1>

We use third-party advertising companies to serve ads when you visit our Website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.

<h1 className='text-2xl font-sem-bold my-5'>5. Your Consent</h1>

By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.

Any changes in the privacy policy would be communicated in a timely manner to you, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and 
</div>)

export default PrivacyPolicy;