import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import "./Slider.css";
import "./Sidebar.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
const amenities = [
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
  {
    name: "Game zone",
    icon: {
      file: "/Game Zone.svg",
    },
  },
];

const cancellationPolicies = [
  "Free Upto 14 Days",
  "Free Upto 7 Days",
  "Free Upto 48 Hours",
];
const prePaymentPolicies = [
  "30%",
  "40%",
  "50%",
  "60%",
  "70%",
  "80%",
  "90%",
  "100%",
];

const Sidebar = ({amenities, query, setQuery}) => {

  //console.log(amenities)
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(500000);
  const [minRating, setMinRating] = useState(0);
  const [maxRating, setMaxRating] = useState(10);
  const [rating, setRating] = useState(0);
  
  //open and close
  const [amenitiesOpen, setAmenitiesOpen] = useState(true);
  const [rangeOpen, setRangeOpen] = useState(true);
  const [ratingOpen, setRatingOpen] = useState(true);
  const [prepaymentOpen, setPrepaymentOpen] = useState(true);
  const [cancellationOpen, setCancellationOpen] = useState(true);
  const [mealsOpen, setMealsOpen] = useState(true);
  ////
  const [prePaymentPolicy, setPrePaymentPolicy] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState("");
  const [breakfast, setBreakfast] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [dinner, setDinner] = useState(false);

  const [amenitiesFlag, setAmenitiesFlag] = useState(
    Array(amenities.length).fill(false)
  );

  const [cancellationFlag, setCancellationFlag] = useState([
    false,
    false,
    false,
  ]);

  const [paymentFlag, setPaymentFlag] = useState(
    Array(prePaymentPolicies.length).fill(false)
  );

  const handleAmenities = (i) => {
    setAmenitiesFlag((prevArray) => {
      const newArray = [...prevArray];
      newArray[i] = !newArray[i];
      return newArray;
    });
  };

  // Handles the checking and unchecking of prepayment policy checkboxes
  const handlePayment = (ind) => {
    setPaymentFlag((prevArray) => {
      const newArray = Array(prePaymentPolicies).fill(false);
      newArray[ind] = !prevArray[ind];
      return newArray;
    });
  };

  // to update the state variables of prepayment policy
  useEffect(() => {
    if (paymentFlag.every((val) => !val)) {
      setPrePaymentPolicy("");
    } else {
      const ind = paymentFlag.findIndex((val) => val === true);
      setPrePaymentPolicy(prePaymentPolicies[ind]);
    }
  }, [paymentFlag]);

  // Handles the checking and unchecking of cancellation policy checkboxes
  const handleCancellation = (ind) => {
    setCancellationFlag((prevArray) => {
      const newArray = Array(3).fill(false);
      newArray[ind] = !prevArray[ind];
      return newArray;
    });
  };

  // to update the state variables of cancellation policy
  useEffect(() => {
    if (cancellationFlag.every((val) => !val)) {
      setCancellationPolicy("");
    } else {
      const ind = cancellationFlag.findIndex((val) => val === true);
      setCancellationPolicy([cancellationPolicies[ind]]);
    }
  }, [cancellationFlag]);

  //Slider
  const steps = ["0 Rs","5000 Rs", "10,000 Rs", "25,000 Rs", "50,000 Rs", "100,000 Rs","500,000 Rs"]; // Define your custom steps here

  const log = (value) => {
    //console.log(steps[value[0]], steps[value[1]]);
    
    const minPrice = parseInt(steps[value[0]].replace(/\D/g,''));
    const maxPrice = parseInt(steps[value[1]].replace(/\D/g,''));
    
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
  };

  //////////QUERY GENERATION///////////////////
  // Generates the query string based on state variables
  const formQuery = () => {
    let queryString = "";
    if (cancellationPolicy) {
      if (cancellationPolicies === "Free Upto 14 Days") {
        queryString += `&cancellation_policy=FREE_UPTO_14_DAYS`;
      } else if (cancellationPolicies === "Free Upto 7 Days") {
        queryString += `&cancellation_policy=FREE_UPTO_7_DAYS`;
      } else {
        queryString += `&cancellation_policy=FREE_UPTO_48_HOURS`;
      }
    }
    queryString += `&min_a2z_score=${minRating * 10}&max_a2z_score=${
      maxRating * 10
    }`;
    if (breakfast) queryString += `&is_breakfast=${breakfast}`;
    if (lunch) queryString += `&is_lunch=${lunch}`;
    if (dinner) queryString += `&is_dinner=${dinner}`;
    queryString += `&min_price_guest_pay=${minPrice}&max_price_guest_pay=${maxPrice}`;
    queryString += `&page_size=15`;
    let amenitiesQuery = "&amenities=";
    let amenitiesQuery2 = amenitiesQuery;
    amenities.forEach((item, i) => {
      if (amenitiesFlag[i]) {
        amenitiesQuery += `${item.id}+`;
      }
    });
    if (amenitiesQuery !== amenitiesQuery2)
      amenitiesQuery = amenitiesQuery.slice(0, -1);
    queryString += amenitiesQuery;

    if (prePaymentPolicy !== "") {
      let preTemp = `&prepayment=`;
      preTemp += prePaymentPolicy.slice(0, -1);
      queryString += preTemp;
    }

    return queryString;
  };

  useEffect(() => {
    let temp = formQuery();
    //console.log("Inside here!");
    //console.log(temp);
    setQuery(temp);
  }, [amenities]);

  // Handles the click of apply filters button and updates the query string
  const handleApply = () => {
    let temp = formQuery();
    // //console.log(temp);
    setQuery(temp);
    //console.log("Final query .......", query);
  };

   // To handle the click of clear filters button
   const handleClear = () => {
    setMinPrice(0);
    setMaxPrice(50000);
    setMinRating(0);
    setMaxRating(10);
    setPrePaymentPolicy("");
    setCancellationPolicy("");
    setBreakfast(false);
    setLunch(false);
    setDinner(false);
    setAmenitiesFlag(Array(amenities.length).fill(false));
    setPaymentFlag(Array(prePaymentPolicies.length).fill(false));
    setCancellationFlag(Array(3).fill(false));
    setRating(0)
  };
  ////////////////////////////////////////
  return (
    <>
      <aside className="w-full lg:w-[290px] bg-[#141626]  h-fit ml-[30px] font-jt rounded-2xl py-4 px-7 my-4">
        <div className="grid grid-cols-2  py-4">
          <h1 className="text-[#FFFFFF] font-bold text-[26px]">Filters</h1>
          <div className="grid grid-cols-2 text-center items-center">
            <div
              className="ml-4 underline cursor-pointer text-[#FFFFFF] text-[16px] font-semibold"
                onClick={() => {
                  handleApply();
                }}
            >
              Apply
            </div>
            <div
              className="ml-2 underline cursor-pointer text-[#FFFFFF] text-[16px] font-semibold"
                onClick={() => {
                  handleClear();
                }}
            >
              Clear
            </div>
          </div>
        </div>
        <svg
          className=""
          width="240"
          height="1"
          viewBox="0 0 310 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="310"
            height="1"
            rx="0.5"
            fill="url(#paint0_linear_76_1453)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_76_1453"
              x1="-1.29925e-06"
              y1="0.333501"
              x2="309.974"
              y2="-2.66679"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" stop-opacity="0" />
              <stop offset="0.518433" stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <div className="mb-8 mt-6 flex flex-col">
          <div className="flex flex-row items-center justify-between ">
            <div className="text-[#FFFFFF] text-[18px] font-semibold">
              Popular Amenities
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                setAmenitiesOpen(!amenitiesOpen);
              }}
            >
              {amenitiesOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                    fill="white"
                  />
                </svg>
              )}
              {!amenitiesOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 23 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          <div>
            {amenitiesOpen && (
              <ul className="grid grid-cols-2 gap-[15px] mt-4">
                {amenities?.map((item, i) => (
                  <li>
                    <div
                      className= {`flex flex-col hover:cursor-pointer items-center justify-center relative  font-medium text-black  rounded-lg text-[15px] py-1 ${amenitiesFlag[i]?"bg-purple-200":"bg-[#F8F8F8] "}`}
                      onClick={() => {
                        handleAmenities(i);
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={amenitiesFlag[i]}
                        className=" accent-black absolute left-1 top-1 "
                      />
                      {item.name === "Please Wait..." ? (
                        <i className="fa-solid fa-spinner"></i>
                      ) : (
                        <img
                          src={item.icon.file}
                          alt={item.name}
                         
                          width={"40px"}
                        />
                      )}

                      <span className={`font-semibold ${item.name.length > 10 ? 'text-[12px]' : 'text-base'}`}>{item.name}</span>


                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="mb-8 flex flex-col">
          <div className="flex flex-row items-center justify-between ">
            <div className="text-[#FFFFFF] text-[18px] font-semibold">
              Price Range
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                setRangeOpen(!rangeOpen);
              }}
            >
              {rangeOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                    fill="white"
                  />
                </svg>
              )}
              {!rangeOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 23 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          {rangeOpen && (
            <div className="mt-[38px] w-[95%]">
              <Slider
                range
                defaultValue={[0, 5]}
                onAfterChange={log}
                tooltipVisible
                max={steps.length - 1}
                tipFormatter={(value) => steps[value]}
              />
            </div>
          )}
        </div>

        <div className="mb-8 flex flex-col">
          <div className="flex flex-row items-center justify-between ">
            <div className="text-[#FFFFFF] text-[18px] font-semibold">
              Ratings
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                setRatingOpen(!ratingOpen);
              }}
            >
              {ratingOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                    fill="white"
                  />
                </svg>
              )}
              {!ratingOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 23 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          {ratingOpen && (
            <div className="mt-2">
              <div>
                {[...Array(5)].map((star, i) => {
                  const ratingValue = i + 1;
                  return (
                    <label key={i} className="p-1 cursor-pointer">
                      <input
                        type="radio"
                        name="rating"
                        value={ratingValue}
                        onClick={() => {
                          setRating(ratingValue);
                          setMinRating((ratingValue * 2) - 1);
                          setMaxRating(ratingValue * 2);
                        }}
                        style={{ display: "none" }}
                      />
                      <FontAwesomeIcon
                        icon={
                          ratingValue <= rating ? faStarSolid : faStarRegular
                        }
                        color={ratingValue <= rating ? "#FCCC24" : "white"}
                        size="xl"
                      />
                    </label>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="mb-8 flex flex-col">
          <div className="flex flex-row items-center justify-between ">
            <div className="text-[#FFFFFF] text-[18px] font-semibold">
              Prepayment Policy
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                setPrepaymentOpen(!prepaymentOpen);
              }}
            >
              {prepaymentOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                    fill="white"
                  />
                </svg>
              )}
              {!prepaymentOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 23 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          {prepaymentOpen && (
            <ul className="grid grid-cols-3 gap-3 mt-4">
              {prePaymentPolicies.map((item, i) => {
                return (
                  <li>
                    <button
                      onClick={() => {
                        handlePayment(i);
                      }}
                      className="flex flex-row items-center font-medium text-[#FFFFFF] text-[15px]"
                    >
                      <input
                        type="checkbox"
                        checked={paymentFlag[i]}
                        className="hidden"
                      />
                      <label
                        htmlFor="custom-checkbox"
                        className={`w-4 h-4 mr-3 flex justify-center items-center cursor-pointer rounded-full border-2 ${
                          paymentFlag[i]
                            ? "border-black bg-black"
                            : "border-gray-300 bg-white"
                        }`}
                      >
                        {paymentFlag[i] && (
                          <svg
                            className="w-4 h-4 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        )}
                      </label>
                      <h1 className="ml-2 text-center ">{item}</h1>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="mb-8 flex flex-col">
          <div className="flex flex-row items-center justify-between ">
            <div className="text-[#FFFFFF] text-[18px] font-semibold">
              Cancellation Policy
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                setCancellationOpen(!cancellationOpen);
              }}
            >
              {cancellationOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                    fill="white"
                  />
                </svg>
              )}
              {!cancellationOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 23 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          {cancellationOpen && (
            <ul className="mt-3">
              {cancellationPolicies.map((item, i) => {
                return (
                  <li>
                    <button
                      onClick={() => {
                        handleCancellation(i);
                      }}
                      className="py-1 flex items-center font-medium text-[#FFFFFF] text-[15px]"
                    >
                      <input
                        type="checkbox"
                        checked={cancellationFlag[i]}
                        className=" hidden"
                      />
                      <label
                        htmlFor="custom-checkbox"
                        className={`w-4 h-4 mr-3 flex justify-center items-center cursor-pointer rounded-full border-2 ${
                          cancellationFlag[i]
                            ? "border-black bg-black"
                            : "border-gray-300 bg-white"
                        }`}
                      >
                        {cancellationFlag[i] && (
                          <svg
                            className="w-4 h-4 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        )}
                      </label>
                      {item}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="mb-8 flex flex-col">
          <div className="flex flex-row items-center justify-between ">
            <div className="text-[#FFFFFF] text-[18px] font-semibold">
              Meals included
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                setMealsOpen(!mealsOpen);
              }}
            >
              {mealsOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                    fill="white"
                  />
                </svg>
              )}
              {!mealsOpen && (
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 23 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.760742 2.62598L0.883789 2.76953L10.1807 13.4678C10.4951 13.8301 10.9668 14.0557 11.4932 14.0557C12.0195 14.0557 12.4912 13.8232 12.8057 13.4678L22.0957 2.79004L22.2529 2.6123C22.3691 2.44141 22.4375 2.23633 22.4375 2.01758C22.4375 1.42285 21.9316 0.9375 21.3027 0.9375H1.69727C1.06836 0.9375 0.5625 1.42285 0.5625 2.01758C0.5625 2.24316 0.637695 2.45508 0.760742 2.62598Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
          {mealsOpen && (
            <ul className="flex flex-col justify-center mt-3">
              <li>
                <button
                  onClick={() => {
                    let temp = breakfast;
                    setBreakfast(!temp);
                  }}
                  className="py-1 flex items-center font-medium text-[#FFFFFF] text-[15px]"
                >
                  <input
                    type="checkbox"
                    checked={breakfast}
                    onChange={() => {
                      let temp = breakfast;
                      setBreakfast(!temp);
                    }}
                    className="mr-3 h-[15px] w-[15px] accent-black"
                  />
                  Breakfast
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    let temp = lunch;
                    setLunch(!temp);
                  }}
                  className="py-1 flex items-center font-medium text-[#FFFFFF] text-[15px]"
                >
                  <input
                    type="checkbox"
                    checked={lunch}
                    onChange={() => {
                      let temp = lunch;
                      setLunch(!temp);
                    }}
                    className="mr-3 h-[15px] w-[15px] accent-black"
                  />
                  Lunch
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    let temp = dinner;
                    setDinner(!temp);
                  }}
                  className="py-1 flex items-center font-medium text-[#FFFFFF] text-[15px]"
                >
                  <input
                    type="checkbox"
                    checked={dinner}
                    onChange={() => {
                      let temp = dinner;
                      setDinner(!temp);
                    }}
                    className="mr-3 h-[15px] w-[15px] accent-black"
                  />
                  Dinner
                </button>
              </li>
            </ul>
          )}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
